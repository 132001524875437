
import React,{useState,useEffect} from 'react'
import useReportErr from '../hooks/useReportErr'
import {useGetData,usePostData,usePutData,useDeleteData} from '../hooks/useGetData'
import './CreateManRecepyNew2.css'
import {RiDeleteBackLine} from 'react-icons/ri'
import{AiOutlineInfoCircle} from 'react-icons/ai'
import { RotatingLines } from 'react-loader-spinner'
import Alert from '../functions/appAlert'
import useNutritionSummary from '../hooks/useNutritionSummary'
import {TbMeat} from 'react-icons/tb'
import {TbCheese} from 'react-icons/tb'
import{LuWheat} from 'react-icons/lu'
import {BsFire} from 'react-icons/bs'
import { IoResizeOutline } from "react-icons/io5";
import {useNavigate} from 'react-router-dom';
import useFetchImageUrl from '../hooks/useFetchImageUrl';
import usePageSettings from '../hooks/usePageSettings';
import languages from '../AppLanguage/AppLanguage';
import AppError from '../AppLanguage/AppError';
import displayGadgetToSmall from '../functions/displayGadgetToSmall'
import YesNoAlert from '../functions/YesNoAlert'
import { RiUploadCloud2Line } from "react-icons/ri"
import { RiDownloadCloud2Line } from "react-icons/ri"
import{AiOutlinePlusCircle} from 'react-icons/ai'
import{AiOutlineMinusCircle} from 'react-icons/ai'
import { IoBookSharp } from "react-icons/io5";
import { FaBook } from "react-icons/fa";
import { CiImageOn } from "react-icons/ci";
import { TbDeviceDesktopAnalytics } from "react-icons/tb";
import noimagefound1 from '../images/noimagefound1.jpg'


export default function CreateManRecepyNew() {

    const [manNew,setManNew]=useState([{}])
    const[getRecepyCategories,setRecepyCategories]=useState([{}])
    const[chosenCategory,setChosenCategory]=useState(0)
    const[getRecepyBycategory,setRecepyBycategory]=useState([{}])
    const[RecepyData,setRecepy]=useState([])
    const[chosenRecepyId,setChosenRecepyId]=useState(0)
    const[getSpinnerLoading,setSpinnerLoading]=useState(false)
    const [getShowAlert,setShowAlert] = useState(false);
    const [getAlertMessage,setAlertMessage] = useState({});
    const[getAlertCode,setAlertCode]=useState(0)
    const[psize,setPsize]=useState(1)
    const [IngredData,setIngredData]=useState([{}])
    const [getIngredCategories,setIngredCategories]=useState([{}])
    const[getIngredSupplier,setIngredSupplier]=useState([{}])
    const[getIngredNutrients,setIngredNutrients]=useState([{}])
    const[expandedRow,setExpandedRow]=useState(null)
    const[chosenIngredid,setchosenIngredid]=useState(0)
    const[showIngredComment,setshowIngredComment]=useState(false)
    const[getIngredComments,setIngredComments]=useState([{}])
    const[getnutritionData,setnutritionData]=useState([{}])
    const[getReceivedDataStatus,setReceivedDataStatus]=useState(false)
    const[chosenRecepyName,setChosenRecepyName]=useState('blåbärskaka')
    const[showSaveRecepyButton,setshowSaveRecepyButton]=useState(false)
    const [getRecepyHeaderData,setRecepyHeaderData]=useState([{}])
    const [getNutrientSummary,setNutrientSummary]=useState([{}])
    const[getButtonSaveActive,setButtonSaveActive]=useState(false) // we toggle the button to highlight it to remind user to save
    const[sortBenaemn,setSortBenaemn]=useState(false)
    const[sortOrder,setSortOrder]=useState(false)
    const[sortKalories,setSortKalories]=useState(false)
    const[recepyComments,setRecepyComments]=useState([{}])
    const [getImages,setImages]=useState([{}])
    const[getIngredDataByCategory,setIngredDataByCategory]=useState([{}])
    const[getRecepies,setRecepies]=useState([{}])
    const[chosenSaveRecepyCategory,setchosenSaveRecepyCategory]=useState(0)
    const[getResizeTextArea,setResizeTextArea]=useState(false)
    const[getBackGroundShade,setBackGroundShade]=useState(0.55)
    const[getMenuShade,setMenuShade]=useState(0.85)
    const[getMenuShade2,setMenuShade2]=useState(0.65)
    const[getRenderKey,setRenderKey]=useState(0)
    const[getColorVal,setColorVal]=useState(0)
    const[getImageVal,setImageVal]=useState(0)
    const[getImageUrl,setImageUrl]=useState('')
    const pageid=7
    const[logtime]=useState(5000)
    const[getFuriganaName,setFuriganaName]=useState('') // this is the furigana name
    const[getShowFurigana,setShowFurigana]=useState(false) // this is the view furigana field
    const[getPseudoVisible,setPseudoVisible]=useState(true) // this is the pseudo visible field
    const [getImageText,setImageText]=useState('') // this is the image text
    const[getTabId,setTabId]=useState(0)
    const[getRecipeImage,setRecipeImage]=useState(0)
    const [recipeImages, setRecipeImages] = useState([]);
    const[getChosenImageIndex,setChosenImageIndex]=useState(0)
    const[getShowYesNoAlert,setShowYesNoAlert]=useState(false)
    const[getYesNoAlertMessage,setYesNoAlertMessage]=useState('')
    // file handling
    const maxSize = 3 * 1024 * 1024; // 3 Megabytes
    const acceptedFileTypes = ['image/jpeg', 'image/png'];
    const [file, setFile] = useState(null);
    const [error, setError] = useState(null);
    // end file handling
    
    const [windowWidth,setWindowWith]=useState(0) // this is the window width
    const [windowHeight,setWindowHeight]=useState(0)  // this is the window height
    const [getMobileIsUsed,setMobileIsUsed]=useState(false) // this is if the mobile is used
    const[getIpadIsUsed,setIpadIsUsed]=useState(false) // this is if the ipad is used
    const[getCountry,setCountry]=useState('en') // this is the country
    const [trigger, setTrigger] = useState(false); 
    const[getTabNr,setTabNr]=useState(0)

  

  
    useEffect(()=>{
      let country = localStorage.getItem('country')||'se' // this is the country code
      setCountry(country)
    },[])

        // if language is japanese we use the font Zen Old Mincho
        let newFontFamily=''
        if(getCountry==='ja'){
        newFontFamily= "'Zen Old Mincho, Arial, Helvetica, sans-serif'"
        }else{
        newFontFamily='Arial, Helvetica, sans-serif'
        }
        document.documentElement.style.setProperty('--font-family', newFontFamily);
    
    


        const { nutrientSummary, calculateTotalNutrients } = useNutritionSummary(RecepyData,getIngredNutrients);
        const { GetSettings, backGroundShade, menuShade, menuShade2, colorVal,imageVal } = usePageSettings(pageid);
        const { imageUrl, alertMessage, alertCode, showAlert} = useFetchImageUrl(getImageVal); // runs at mount, gives ut the image url and fetch image from server

        const navigate = useNavigate();
  //alert(windowWidth)
        useEffect(() => {
          function setSize() {
            setWindowWith(window.innerWidth);
            setWindowHeight(window.innerHeight);
          
            if(window.innerWidth < 600){
        
              setMobileIsUsed(true)
            } 
        
          }
      
        
          // Call setSize initially to set the correct state
          setSize();
        
          // Add the event listener
          window.addEventListener('resize', setSize);
        
          // Return a cleanup function to remove the event listener when the component unmounts
          return () => {
            window.removeEventListener('resize', setSize);
          };
        }, [backGroundShade, menuShade, menuShade2, colorVal,imageVal]); // Dependencies
      
        function rgbToRgba(rgb, alpha) {
          return rgb.replace(')', `, ${alpha})`).replace('rgb', 'rgba');
        }
        
      //--bakcground shade settings
      let rgbaVal
      let rgbaVal2
      let val
      
      if(getColorVal && getColorVal.length > 0){
        let rgb = getColorVal;
        let alpha = getMenuShade; // replace with your alpha value
        let alpha2 = getMenuShade2; // replace with your alpha value
        rgbaVal = rgbToRgba(rgb, alpha);
        rgbaVal2 = rgbToRgba(rgb, alpha2);
        val=`rgba(255, 255, 255, ${getBackGroundShade})`
      
        }else{
          rgbaVal =`rgba(255, 255, 255, ${getBackGroundShade})`;
          rgbaVal2 =`rgba(255, 255, 255, ${getBackGroundShade})`;
          val=`rgba(255, 255, 255, ${getBackGroundShade})`
        }
      //---
      
      useEffect(() => {
          let root = document.documentElement;
          root.style.setProperty('--rgbaVal', rgbaVal);
          root.style.setProperty('--rgbaVal2', rgbaVal2);
          root.style.setProperty('--val',val);
          root.style.setProperty('--windowHeight',windowHeight+'px');
      
          
        }, [getBackGroundShade,getMenuShade,getMenuShade2]);
      

    // language settings
    const selectedCountry = languages[getCountry];
 
     let title=''
    if(getMobileIsUsed===false){
      title=selectedCountry.mancreaterecipe?.title
    }else{
      title=selectedCountry.mancreaterecipe?.titleMobile
    }


    const {
      leftFirstSelectLegend,
      leftSecondSelectLegend,
      recepyTitlePlaceHolder,
      menuHeaderId,
      menuHeaderIngrediens,
      menuHeaderMangd,
      menuHeaderKostnad,
      menuHeaderKalorier,
      menuHeaderOrder,
      titleMenuHeaderId,
      titleMenuHeaderIngrediens,
      titleMenuHeaderMangd,
      titleMenuHeaderKostnad,
      titleMenuHeaderKalorier,
      titleMenuHeaderOrder,
      titleDeleteIngredient,
      ingredientBoxCommentTitle,
      ingredientBoxPlusButtonText,
      titleIngredientBoxPlusButton,
      ingredientBoxMinusButtonText,
      titleIngredientBoxMinusButton,
      ingredientBoxGetNutrientButtonText,
      ingredientBoxCloseListButtonText,
      ingredientBoxNumberOfLetters,
      footerMenuTotalt,
      footerCalorieSummary,
      saveRecepySelectCategoryFirstOption,
      saveRecepyButtonText,
      titleSaveRecepyButton,
      nutrientEnergyLegend,
      nutrientKolhydraterLegend,
      nutrientProteinLegend,
      nutrientFettLegend,
      titleSummaEnergiIRecept,
      titleTotalMangdKolhydraterIRecept,
      titleTotalMangdProteinIRecept,
      titleTotalMangdFettIRecept,
      titleAdjustBackGround,
      recepyDescriptionHeader,
      recepyDescriptionAntalTecken,
      nutrientListHeaderSubject,
      nutrientListHeaderAmount,
      nutrientListHeaderValue,
      choseRecepyFirstOption,
      leftSelectFirstOption,
      checkboxShowPriceLabel,
      recipeIconText,
      imageIconText,
      createImageButtonText,
      createImageTextPlaceholder,
      deleteImageTitle,
      generateAIimageDisclaimer,
      uploadIconText,
      choseImageButtontext,
      choseImageUploadText,
      recepyMustBeChosenBeforeUploadMessage,
      downloadIconText,
  } = selectedCountry.mancreaterecipe || {};




// error messages
const selectedError=AppError[localStorage.getItem('country')||'en']
  

const{
  recepyCreated,
  imageSearchTextToLong,
  selectedImageDeleted,
  aiimageIsDone,
  btnYes,
  btnNo,
  imageDeletQuestion,
  imageUploadedMessage,
  aiimageCantBeDone,
  aiImageCantBeUploaded,
  recipeCanNotBeSaved,
  aiSubscriptionVoid,
  imageuploadVoid,
}=selectedCountry.confirmationMessages||{};


          useEffect(() => {
            GetSettings();
          }, [GetSettings]);

          // we set the image url here
          useEffect(() => {
            setImageUrl(imageUrl);
            if (alertCode > 0) {
              setAlertMessage(alertMessage);
              setAlertCode(alertCode);
              setShowAlert(showAlert);
            }
          }, [imageUrl])
        
          // here we set the color values and image values
          useEffect(() => {
            setBackGroundShade(backGroundShade);
            setMenuShade(menuShade);
            setMenuShade2(menuShade2);
            setColorVal(colorVal);
            setImageVal(imageVal);
          }, [backGroundShade, menuShade, menuShade2, colorVal,imageVal]);






          useEffect(()=>{
            setSpinnerLoading(true)
            async function GetAllIngredData(){
           
              try{
                let response= await useGetData('getallingredientsAndLatestPrice')
                 setIngredData(response.data[0]) // we set the data here
                 setIngredCategories(response.data[1]) 
                 setIngredSupplier(response.data[2])
                 setRecepyCategories(response.data[3]) // denna kan tas bort
                 setIngredNutrients(response.data[4])
                 setImages(response.data[5])
                 setRecepies(response.data[6])
                 setSpinnerLoading(false)
              
              }catch(error){
                setAlertCode(28001)
                setAlertMessage(selectedError[28001])
                setSpinnerLoading(false)
                setShowAlert(true)
          
              }
              }
            GetAllIngredData()
       
              showTab('image'); // Set 'info' tab as default
  
          },[])
  
          useEffect(() => {
              calculateTotalNutrients();
            }, [RecepyData, getnutritionData]);
          
          
          useEffect(()=>{
              setSpinnerLoading(true)
            async function GetDataByCategory(){
              setSpinnerLoading(true)
              try{
                let response=await useGetData(`getIngredByCategory/${chosenCategory}`)
                setIngredDataByCategory(response.data[0])
                setSpinnerLoading(false)
              }catch(error){
                setShowAlert(true);
                setAlertMessage(selectedError[28002])
                setAlertCode(28002);
             
  
              }
  
            }
          
          GetDataByCategory()
          },[chosenCategory])
          
          
  
  function getData(id){
    setFuriganaName('') // clearing the furigana name
    setShowFurigana(false)  // closing the furigana field
      setSpinnerLoading(true)
      setExpandedRow(null)// here we reset the expanded row
      async function GetDataByRecepyId(id){
       
      try{
      const response=await useGetData(`recepybyid/${id}`)
      // here we will make a check and update the object
      let myNewObjArr=[...response.data[0]]
    
      myNewObjArr.map(data=>{
          if(data.kaloriekoeff===0||data.kaloriekoeff===null){
              // we will try to find the kaloriekoeff in the object getIngredNutrients
              let kaloriekoeff=getIngredNutrients.find(data1=>data1.ingredid===data.ingredid&&data1.nutrient==='Energy')
              // if the kaloriekoeff is undefined we will set it to 0
              if(kaloriekoeff===undefined){
                  data.kaloriekoeff=0
              }else{
                  // if the kaloriekoeff is not undefined we will update the kaloriekoeff in the object RecepyData
                  data.kaloriekoeff=kaloriekoeff.quantity/100
              }
          }
      })
  
      setRecepy(response.data[0])
      setRecepyHeaderData(response.data[1]) // here we set the recepy header data
      setFuriganaName(response.data[1][0].benaemnfuri ||'') // here we set the furigana name
      setChosenRecepyName(response.data[1][0].benaemn ||'',) // here we set the recepy name
      setImageText(response.data[1][0].benaemn ||'') // here we set the image text
      setSpinnerLoading(false)
      setReceivedDataStatus(false)
  
     AIComments(response.data[1][0].benaemn ||'')
     }catch(error){
      setSpinnerLoading(false)
      setShowAlert(true);
      setAlertMessage(selectedError[28003])
      setAlertCode(28003);
     
      }
      }
  
      GetDataByRecepyId(id)
    }
  
  
  
  
  //console.log(recipeImages)
  
  
  
  
  async function AIComments(benaemn){
    
    try{
      let response=await useGetData(`getrecepycomments/${benaemn}`)
      setRecepyComments(response.data)
  
      setSpinnerLoading(false)
  }catch(error){
      setAlertCode(28004)
      setAlertMessage(selectedError[28004])
      setShowAlert(false)
      setSpinnerLoading(false)
    
  
  }
  }
         
          
          
          const RunReportErrHandler = async (errCode) => {
              setSpinnerLoading(true);
              await useReportErr('ErrReport',{errCode});
              setSpinnerLoading(false);
            };
          
          useEffect(() => {
              if (getAlertCode > 0) {
                RunReportErrHandler(getAlertCode);
              }
            }, [getAlertCode]);
            
            const handleCloseAlert = () => {
              setShowAlert(false);
            };

            const handleCloseAlertYes = () => {
              setShowYesNoAlert(false);
              deleteImageHandler()
            };
            const handleCloseAlertNo = () => {
              setShowYesNoAlert(false);
            };
  
            
  const categoryHandler=(value)=>{
      setChosenCategory(value)
    
  }
  
  const showRecepyHandler=(value)=>{
      setChosenRecepyId(value)
      //here we wnt to set the recepy name
      let recepyIndex=getRecepyBycategory.findIndex(data=>data.receptid===value)
      let recepyName=getRecepyBycategory[recepyIndex].benaemn
      setChosenRecepyName(recepyName)
   
  }
  
  const handleRowClickIndex = (index,ingredid) => {// we get the index of the row and the ingredid
    setchosenIngredid(ingredid) // here we set the ingredid to our state
      setReceivedDataStatus(false) // here we reset the received data status
      setnutritionData([]) // here we reset the nutrition data
      setshowIngredComment(true) // here we open the component
      if (expandedRow === index) { // here we check if the index is the same as the expanded row
        setExpandedRow(null);
      } else {
        setExpandedRow(index);
      }
    };
  
  
    const ingredCommentsHandler=(comment)=>{
    
      //here we will copy the array RecepyData and add the comment to the ingredient
      let myNewObjArr=[...RecepyData]
      let newIndex=myNewObjArr.findIndex(data=>data.ingredid===chosenIngredid)
  
      // we will add the comment to the ingredient
      myNewObjArr[newIndex].btext=comment
      // //we will set the new array to our state
      setRecepy(myNewObjArr)
    
     
    }
    const deleteIngredientHandler=(ingredid)=>{ // delete ingredient from recepy
      //here we will copy the array RecepyData and delete the ingredient
      let myNewObjArr=[...RecepyData]
      let newIndex=myNewObjArr.findIndex(data=>data.ingredid===ingredid)
      // we will delete the ingredient from the array
      myNewObjArr.splice(newIndex,1)
      //we will set the new array to our state
      setRecepy(myNewObjArr)
  
      }
  
  
  
  
  
  
  const changeAmountHandler=(amount)=>{
  
   
    if(amount===1 || amount===3){
      let changeVal=0
      if(amount===1){
        changeVal=1
      }else{
        changeVal=10
      }
    
    
    // here i want to change the amount of the ingredient i want to decrease by 1
    //copy recepydata
    let myNewObjArr=[...RecepyData]
   
    //we will find the index of the ingredient we want to change
    let newIndex=myNewObjArr.findIndex(data=>data.ingredid===chosenIngredid)
    // fist find the amount of the ingredient
    let amountInGram=myNewObjArr[newIndex].mangd
    // we will decrease the amount by 1
    
    myNewObjArr[newIndex].mangd=amountInGram-changeVal
    // we will change the totalcost
    myNewObjArr[newIndex].ADsum=myNewObjArr[newIndex].latest_pris*(amountInGram-changeVal)/1000
    // here we also need to change the amount of calories
    // first we find the total amount of calories
    let totalCalories=myNewObjArr[newIndex].kalories
    // to find the number of calories per amount of grams we divide the total calories with the amount of grams
    let caloriesPerGram=totalCalories/amountInGram
    // we will multiply the calories per gram with the new amount of grams
    myNewObjArr[newIndex].kalories=caloriesPerGram*(amountInGram-changeVal)
  
    //here we need to calculate the total amount of kalories in the newObjArr only for the chosenIngredid
  let totalamountOfKalories=myNewObjArr.find(data=>data.ingredid===chosenIngredid)
  //if the totalamount of caloriesis undefined or 0 we will try to find it in the object getIngredNutrients
  if(totalamountOfKalories.kalories===undefined||totalamountOfKalories.kalories===0){
     
      // here we need to find the kalories in the object getIngredNutrients
      totalamountOfKalories=getIngredNutrients.find(data=>data.ingredid===chosenIngredid&&data.nutrient==='Energy')
      // if the kaltories is undefined we will set the kalories to 0
      if(totalamountOfKalories===undefined){
          totalamountOfKalories=null
      }else{
      // if the kalories is not undefined we will divide by 100 to get the kalories per gram
      totalamountOfKalories=totalamountOfKalories.quantity/100
    
      }
  // we will now multiply the total amount of kalories with the amount of grams
  totalamountOfKalories=totalamountOfKalories*amountInGram
  myNewObjArr[newIndex].kalories=totalamountOfKalories
  }
    //we will set the new array to our state
    setRecepy(myNewObjArr)
  
  
    }else{
      if(amount===2 || amount===4){
        let changeVal=0
        if(amount===2){
          changeVal=1
        }else{
          changeVal=10
        }
      
  
      // here i want to change the amount of the ingredient i want to increase by 1
    //copy recepydata
    let myNewObjArr=[...RecepyData]
    //we will find the index of the ingredient we want to change
    let newIndex=myNewObjArr.findIndex(data=>data.ingredid===chosenIngredid)
    // fist find the amount of the ingredient
    let amountInGram=myNewObjArr[newIndex].mangd
    // we will increase the amount by 1
    myNewObjArr[newIndex].mangd=amountInGram+changeVal
    // we will change the totalcost
    myNewObjArr[newIndex].ADsum=myNewObjArr[newIndex].latest_pris*(amountInGram+changeVal)/1000
    // here we also need to change the amount of calories
    // first we find the total amount of calories
    let totalCalories=myNewObjArr[newIndex].kalories
    // to find the number of calories per amount of grams we divide the total calories with the amount of grams
    let caloriesPerGram=totalCalories/amountInGram
    // we will multiply the calories per gram with the new amount of grams
    myNewObjArr[newIndex].kalories=caloriesPerGram*(amountInGram+changeVal)
  
    // here we need to calculate the total amount of kalories in the newObjArr only for the chosenIngredid
  let totalamountOfKalories=myNewObjArr.find(data=>data.ingredid===chosenIngredid)
  //if the totalamount of caloriesis undefined or 0 we will try to find it in the object getIngredNutrients
  if(totalamountOfKalories.kalories===undefined||totalamountOfKalories.kalories===0){
      // here we need to find the kalories in the object getIngredNutrients
      totalamountOfKalories=getIngredNutrients.find(data=>data.ingredid===chosenIngredid&&data.nutrient==='Energy')
      // if the kaltories is undefined we will set the kalories to 0
      if(totalamountOfKalories===undefined){
          totalamountOfKalories=null
      }else{
      // if the kalories is not undefined we will divide by 100 to get the kalories per gram
      totalamountOfKalories=totalamountOfKalories.quantity/100

      }
  // we will now multiply the total amount of kalories with the amount of grams
  totalamountOfKalories=totalamountOfKalories*amountInGram
  //here we will make the  amount to integer by using not parseint we use math.round
  myNewObjArr[newIndex].kalories=totalamountOfKalories
  }
  
    //we will set the new array to our state
    setRecepy(myNewObjArr)
    
    }
    }
  }
  
    
  
  const GetFoodInfoContainer=()=>{
  
    // here we check if we have data in the object getIngredNutrients
    // if we have data in this object we will set the state getnutritionData to the data in this object
    let checkIfWeHaveData=getIngredNutrients.filter(data=>data.ingredid===chosenIngredid)
  
    //if(checkIfWeHaveData.length>0){
    if  (Array.isArray(checkIfWeHaveData) && checkIfWeHaveData.length > 0){
      
      //here we have to toggle the received data status to true
      if(getReceivedDataStatus===true){
        setnutritionData([])
        setReceivedDataStatus(false)
      }else{
      setnutritionData(checkIfWeHaveData)
      //we have data so we update the object RecepyData
      //here we need to update the amount of kalorikoefficient
      // find the index of the ingredid
      // copy object RecepyData
      let myNewObjArr=[...RecepyData]
      //we will find the index of the ingredient we want to change
      let newIndex=myNewObjArr.findIndex(data=>data.ingredid===chosenIngredid)
      // we will change the amount kaloriekoeff
      myNewObjArr[newIndex].kaloriekoeff=checkIfWeHaveData.find(data=>data.nutrient==='Energy').quantity/100
      //we will set the new array to our state
   
      setRecepy(myNewObjArr)
      
      setReceivedDataStatus(true)
     
    
      }
    
    
    }else{
      // here we need to make a request to the api
      GetFoodInfoHandler()
    }
    }
    
    async function GetFoodInfoHandler(){
  
    //copy data
    let myNewObjArr=[...RecepyData]
    //we will find the index of the ingredient we want to change
    let newIndex=myNewObjArr.findIndex(data=>data.ingredid===chosenIngredid)
    //find the name of the ingredient
    let benaemn=myNewObjArr[newIndex].benaemn
    let textToTranslate=benaemn
    let language='en'
    
    // here i need to make a request for translation
    setSpinnerLoading(true)
    try{
      let response=await useGetData(`translate/${language}/${textToTranslate}/${chosenIngredid}`)
      let amountofCalories=response.data.calories
      let totalDailyData=response.data.totalNutrients
     
      const totalDailyArray = Object.keys(totalDailyData).map((key) => ({
        nutrient: totalDailyData[key].label,
        quantity: totalDailyData[key].quantity,
        unit: totalDailyData[key].unit,
      }));
     setnutritionData(totalDailyArray)
      
      // here we need to check if the amount of calories is undefined
      // this function translates the ingredientname to english but also it check the amount of calories
      //and the calories are the most important so we will check if the amount of calories is a number
      if(isNaN(amountofCalories)){
        setAlertCode(28005)
        setAlertMessage(selectedError[28005])
        setShowAlert(true)
        setSpinnerLoading(false)
        return
      }else{
          //here we want to use the data we already got from backend in the totalDailyArray
          // we will find the kalories in the array
          let kalories=totalDailyArray.find(data=>data.nutrient==='Energy')
          //we will divide with 100 to get the kalories per gram
          kalories=kalories.quantity/100
          // we will update the kaloriekoeff value in the object RecepyData
          myNewObjArr[newIndex].kaloriekoeff=kalories
          // we will update the kalories value in the object RecepyData
          //update state
          setRecepy(myNewObjArr)
      setReceivedDataStatus(true)
      setSpinnerLoading(false)
      }
    
    
    }catch(error){
      setAlertCode(28006)
      setAlertMessage(selectedError[28006])
      setShowAlert(true)
      setSpinnerLoading(false)
     
    }
    }
  
  
    const addDbOwnerIngredient=(ingredid)=>{
      showTab('image')
      setchosenIngredid(ingredid)
    
      // here i want to add a new ingredient to the object RecepyData
      //copy recepydata
      let myNewObjArr=[...RecepyData]
      //we will add the new ingredient to the array
      // we have to find the benaemn from the IngredData object
      let benaemn=IngredData.find(data=>data.ingredid===ingredid)
      // we ned to find receptid
      // let receptid=myNewObjArr[0].receptid
      
    
      // here we must find the pris from the IngredData object
      let pris=IngredData.find(data=>data.ingredid==ingredid)
      //here we have to find te calories for the ingredient in the object getIngredNutrients
      // and to find the kalories we need to look on the nutrient column and look for 'Energy'
      let kalories=getIngredNutrients.find(data=>data.ingredid==ingredid&&data.nutrient==='Energy')
      // when we have the kalories we need to divide by 100 to get the kalories per gram
      //first we need to check so the kalories is not undefined
      let kaloriekoeff
      if(kalories===undefined){
          kaloriekoeff=0
      }else{
      kaloriekoeff=kalories.quantity/100
      kaloriekoeff=parseFloat(kaloriekoeff.toFixed(2))
      }
  
      // before we push we must check if this ingredient already are in the array
      let checkIfIngredidExist=myNewObjArr.find(data=>data.ingredid==ingredid)
      if(checkIfIngredidExist){
          return
      }else{
      myNewObjArr.push({ingredid:parseInt(ingredid),benaemn:benaemn.benaemn,latest_pris:pris.pris,kalories:0,mangd:1,ADsum:0,btext:'',quantity:0,arborder:0,kaloriekoeff:kaloriekoeff})
      //we will set the new array to our state
  
        // AddnewIngredientToRecepy(kaloriekoeff,ingredid)
      setRecepy(myNewObjArr)
      }

  
    }
  
    // async function AddnewIngredientToRecepy(kaloriekoeff,ingredid){
   
    //   setSpinnerLoading(true)
    //   try{
    //       let response =await useGetData(`addnewingredientToRecepy/${chosenRecepyId}/${ingredid}/${kaloriekoeff}`)
    //       if(response.status===200){
    //           setSpinnerLoading(false)
    //       }
    //   }catch(error){
    //       setAlertCode(19006)
    //       setAlertMessage('Kan inte lägga till ingrediens')
    //       setShowAlert(true)
    //       setSpinnerLoading(false)
    //   }
    // }
    
  // here we wneed to sort the categories ascending by name
  let sortedCategories=getRecepyCategories.sort((a,b)=>{
      if(a.benaemn<b.benaemn){
          return -1
      }else if(a.benaemn>b.benaemn){
          return 1
      }
      return 0
  })
  
  
  
  const descriptionHandler=(description)=>{
   
    let myNewObjArr=[...getRecepyHeaderData]
    myNewObjArr[0].btext=description
  setRecepyHeaderData(myNewObjArr)
    setshowSaveRecepyButton(true)
    setButtonSaveActive(true)
  
  }
  
  
  
  const SaveRecepyHandler=()=>{
    setShowFurigana(false)
      setButtonSaveActive(false)
         setSpinnerLoading(true)
         // first we save the data to the object recepyHeaderData
          let myNewObjArr2=[...getRecepyHeaderData]
          myNewObjArr2[0].benaemnfuri=getFuriganaName
          setRecepyHeaderData(myNewObjArr2)

      async function SaveRecepy(){
          let myNewObjArr=[...RecepyData]
          let myNewObjArr2=[...getRecepyHeaderData]

          let recepyData = JSON.stringify(myNewObjArr);
          let recepyHeaderData = JSON.stringify(myNewObjArr2);
  
  
          try{
              let response= await usePostData(`saveNewRecepy`,{recepyData,recepyHeaderData,chosenSaveRecepyCategory,getFuriganaName})
            //  let response= await useGetData(`saveNewRecepy/${decodedRecepyData}/${decodedRecepyHeaderData}/${chosenSaveRecepyCategory}`)
              
              if(response.status===200){
                  setAlertCode(0)
                  setAlertMessage(recepyCreated)
                  setShowAlert(true)
                  setSpinnerLoading(false)
              }else if(response.status===201){
                  setAlertCode(0)
                  setAlertMessage(recipeCanNotBeSaved)
                  setShowAlert(true)
                  setSpinnerLoading(false)
              }
          
          }catch(error){
              setAlertCode(28008)
              setAlertMessage(selectedError[28008])
              setShowAlert(true)
              setSpinnerLoading(false)
            
          }
      
      }
      SaveRecepy()
  }
  
  const  HandleArbOrderClick=async(index)=> {
  
      setSpinnerLoading(true)
        // Make a shallow copy of the mydataObj array
        const updatedData = [...RecepyData];
        // Toggle the clicked state of the arbOrder button for the clicked row
        updatedData[index].arbOrderclicked = !updatedData[index].arbOrderclicked;
      // find the highest number in the array
        let maxArbOrder = Math.max.apply(Math, updatedData.map((data) => data.arborder));
          
         if(maxArbOrder>0){
                if(updatedData.length>maxArbOrder)
                {
                  updatedData[index].arborder=maxArbOrder+1
                }
                else{   
                  updatedData.map((data) =>{return data.arborder=0})
                    }
      
         }else{
          updatedData[index].arborder=maxArbOrder+1
        
        }
          // Update the state with the new array of dataObj
          setRecepy(updatedData);
       
          setSpinnerLoading(false)
      }
  
  
      const SortBenaemnHandler=()=>{
          //here we will sort the recepy by benaemn
          // we will copy the array RecepyData
          let myNewObjArr=[...RecepyData]
          // we will sort the array by benaemn ascending then toggle ascending and descending
          if(sortBenaemn===false){
              myNewObjArr.sort((a,b)=>{
                  if(a.benaemn<b.benaemn){
                      return -1
                  }else if(a.benaemn>b.benaemn){
                      return 1
                  }
                  return 0
              })
              setRecepy(myNewObjArr)
              setSortBenaemn(true)
      }
      else{
          myNewObjArr.sort((a,b)=>{
              if(a.benaemn>b.benaemn){
                  return -1
              }else if(a.benaemn<b.benaemn){
                  return 1
              }
              return 0
          })
          setRecepy(myNewObjArr)
          setSortBenaemn(false)
      }
      }
  
  const SortOrderHandler=()=>{
      //here we will toggle the sort order
      // we will copy the array RecepyData
      let myNewObjArr=[...RecepyData]
      // we will sort the array by arborder ascending then toggle ascending and descending
      if(sortOrder===false){
          myNewObjArr.sort((a,b)=>{
              if(a.arborder<b.arborder){
                  return -1
              }else if(a.arborder>b.arborder){
                  return 1
              }
              return 0
          })
          setRecepy(myNewObjArr)
          setSortOrder(true)
  
  }else{
      myNewObjArr.sort((a,b)=>{
          if(a.arborder>b.arborder){
              return -1
          }else if(a.arborder<b.arborder){
              return 1
          }
          return 0
      })
      setRecepy(myNewObjArr)
      setSortOrder(false)
  
  }
  }
  
  const SortKalories=()=>{
      //here we will sort ascending descending by kalories
      // we will copy the array RecepyData
      let myNewObjArr=[...RecepyData]
      // we will sort the array by kalories ascending then toggle ascending and descending
      if(sortKalories===false){
          myNewObjArr.sort((a,b)=>{
              if(a.kaloriekoeff*a.mangd<b.kaloriekoeff*b.mangd){
                  return -1
              }else if(a.kaloriekoeff*a.mangd>b.kalorikoeff*b.mangd){
                  return 1
              }
              return 0
          })
          setRecepy(myNewObjArr)
          setSortKalories(true)
  
  }
  else{
      myNewObjArr.sort((a,b)=>{
          if(a.kaloriekoeff*a.mangd>b.kaloriekoeff*b.mangd){
              return -1
          }else if(a.kaloriekoeff*a.mangd<b.kaloriekoeff*b.mangd){
              return 1
          }
          return 0
      })
      setRecepy(myNewObjArr)
      setSortKalories(false)
  
  }
  }
  
  const ChangeRecepyNameHandler=(name)=>{
    setShowFurigana(true)
      //we copy the object getRecepyHeaderData
      let myNewObjArr=[...getRecepyHeaderData]
      // we change the name
      myNewObjArr[0].benaemn=name
      // we set the new object to our state
      setRecepyHeaderData(myNewObjArr)
      if(chosenSaveRecepyCategory>0)
      setButtonSaveActive(true)
    setImageText(name)
  }
  
  
  
  const AddRecepieHandler=(id)=>{
  setRecipeImages([])
  setChosenRecepyId(id)
  getData(id)
  setButtonSaveActive(false)
  setRecipeImage('')
  setChosenImageIndex(0) // we set the chosen image index to 0
  showTab('image')
  }
  
  const addCategoryHandler=(category)=>{
    setchosenSaveRecepyCategory(category) 
    if(chosenRecepyName.length>0)
    setButtonSaveActive(true)
  }
  

// if the user changges the value on the range control we save the value in the database
async function SetBackGroundShadeHandler(val){
  setBackGroundShade(val)
  setRenderKey(getRenderKey+1)
  setSpinnerLoading(true)
  try{
    const response = await usePutData(`updateBackGroundShade`,{pageid,val})
    if(response.status===200){
    setSpinnerLoading(false)
    }
  }catch(error){
      setAlertCode(28009)
      setAlertMessage(selectedError[28009])
      setSpinnerLoading(false)
      setShowAlert(true)
    
    }

}

  async function SetmenuColorHandler(val){
    setMenuShade(val)
    setMenuShade2(val-0.20)
    setRenderKey(getRenderKey+1)
    setSpinnerLoading(true)
    try{
      const response = await usePutData(`updateMenuShade`,{pageid,val})
      if(response.status===200){
      setSpinnerLoading(false)
      }
  
  }catch(error){
    setAlertCode(28010)
    setAlertMessage(selectedError[28010])
    setSpinnerLoading(false)
    setShowAlert(true)
    return;
  }
  }
  
const setResizeTextAreaHandler=()=>{
  if(getResizeTextArea===false){
    setResizeTextArea(true)
  }else{
    setResizeTextArea(false)
  }
}

function AreaChoice(){
  if(getResizeTextArea===true){
    return "xktotalArea2"
}else if(getResizeTextArea===false&&getBackGroundShade==1){
  return "xktotalArea1"
}else if(getResizeTextArea===false&&getBackGroundShade<1){
  return "xktotalArea"
}
}

const setFuriganaNameHandler=(name)=>{
  setFuriganaName(name)
  setButtonSaveActive(true)
}

function showTab(tabId,tabNr) {
  setTabId(tabId)
  setTabNr(tabNr)
  //here we want to make a request to the api to get the image
  const tabContents = document.querySelectorAll('.tab-content');
  tabContents.forEach(tabContent => {
    tabContent.style.display = 'none';
  });
  document.getElementById(tabId).style.display = 'block';
}


useEffect(()=>{
  GetImageData()
  },[chosenRecepyId,trigger])


//   async function GetImageData(){
//     setSpinnerLoading(true)
//     try{
//   const token = localStorage.getItem('auth_token');
//   const headers = token ? { authorization: `Bearer ${token}` } : {};  
//   const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/streamBucketImages_Temp`, { headers });

// if(response.status === 200){
//     const images = await response.json(); // assuming the images are Base64 encoded strings
//     const imageBlobs = images.map(image => {
//         const byteCharacters = atob(image);
//         const byteNumbers = new Array(byteCharacters.length);
//         for (let i = 0; i < byteCharacters.length; i++) {
//             byteNumbers[i] = byteCharacters.charCodeAt(i);
//         }
//         const byteArray = new Uint8Array(byteNumbers);
//         return new Blob([byteArray], {type: 'image/jpeg'}); // adjust the MIME type as needed
//     });
//    // const imageUrls = imageBlobs.map(blob => URL.createObjectURL(blob));
//     const imageUrls = imageBlobs.map(blob => URL.createObjectURL(blob));
//     let loadedImagesCount = 0;

// imageUrls.forEach((url, index) => {
//     const img = new Image();
//     img.onload = () => {
//         loadedImagesCount++;
//         if (loadedImagesCount === imageUrls.length) {
//             setSpinnerLoading(false);
//         }
//     };
//     img.src = url;
// });

// setRecipeImages(imageUrls);
//     // setRecipeImages(imageUrls);
//     // setSpinnerLoading(false);
// } else if (response.status === 404){
//     setAlertCode(0);
//     setAlertMessage(selectedError[28013]);
//     setSpinnerLoading(false);
//     setShowAlert(false);
// }
//     } catch(error){
//       setAlertCode(28011)
//       setAlertMessage(selectedError[28013])
//       setSpinnerLoading(false)
//       setShowAlert(true)
//     }
//   }


async function GetImageData() {
  setSpinnerLoading(true);

  try {
      const token = localStorage.getItem('auth_token');
      const headers = token ? { authorization: `Bearer ${token}` } : {};

      // Fetch image URLs from the backend

        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/streamBucketImages_Temp`, { headers });

      if (response.status === 200) {
          const imageUrls = await response.json(); // Backend returns direct CloudFront URLs

          if (imageUrls.length === 0) {
              handleNoImages(); // No images available
          } else {
              setRecipeImages(imageUrls); // Update state with image URLs
              showTab('image'); // Show the image tab
              setTabNr(1);
          }
      } else if (response.status === 404) {
          handleNotFoundError();
      }
  } catch (error) {
      handleFetchError(error);
  } finally {
      setSpinnerLoading(false); // Stop the spinner
  }
}

// Helper function: Handle no images
function handleNoImages() {
  showTab('image'); // Switch to the "info" tab
  setTabNr(2);
}

// Helper function: Handle 404 error
function handleNotFoundError() {
  setAlertCode(0);
  setAlertMessage(selectedError[28013]);
  setShowAlert(false);
}

// Helper function: Handle generic fetch errors
function handleFetchError(error) {
  console.error('Error fetching image data:', error);
  setAlertCode(19015);
  setAlertMessage(selectedError[28013]);
  setShowAlert(true);
}






async function GenerateaiImage(){
  setSpinnerLoading(true)
  try{
    let response=await useGetData(`generateaiimage_Temp/${chosenRecepyId}/${getImageText}`)
    if(response.status===200){
    setSpinnerLoading(false)
    setAlertCode(0)
    setAlertMessage(aiimageIsDone)
    setShowAlert(false)
    setSpinnerLoading(false)
    setTrigger(!trigger); 
    }else if(response.status===201){
    
      setAlertCode(0)
      setAlertMessage(aiSubscriptionVoid)
      setShowAlert(true)
      setSpinnerLoading(false)

    }
    else if(response.status===202){
    
      setAlertCode(0)
      setAlertMessage(aiImageCantBeUploaded)
      setShowAlert(true)
      setSpinnerLoading(false)

    }
    else if(response.status===203){
    
      setAlertCode(0)
      setAlertMessage(aiSubscriptionVoid)
      setShowAlert(true)
      setSpinnerLoading(false)

    }
  }catch (error){
    setAlertCode(28012)
    setAlertMessage(selectedError[28012])
    setSpinnerLoading(false)
    setShowAlert(true)
  }


}
const setImageTextHandler=(text)=>{
  if(text.length>700){
    setAlertCode(0)
    setAlertMessage(imageSearchTextToLong)
    setShowAlert(true)
  }else{
    setImageText(text)
  }

}

const choseImageHandler=(image)=>{
  setChosenImageIndex(image)
}

const deleteImageHandlerContainer=()=>{
  console.log('delete image')
  setYesNoAlertMessage(`${imageDeletQuestion}`)
  setShowYesNoAlert(true)
}


const deleteImageHandler=()=>{


  // here we delete the image from the object first
  let images=[...recipeImages]
  images.splice(getChosenImageIndex,1)

  setRecipeImages(images)
  // we call up the delete image function
 DeleteImage()

}

async function DeleteImage(){
  setSpinnerLoading(true)
  try{
    let response=await useDeleteData(`deleteimages_Temp/${getChosenImageIndex}`)
    if(response.status===200){
    setSpinnerLoading(false)
    setAlertCode(0)
    setAlertMessage(selectedImageDeleted)
    setShowAlert(true)
    }
  }
   catch(error){
      setAlertCode(28014)
      setAlertMessage(selectedError[28014])
      setSpinnerLoading(false)
      setShowAlert(true)
    }
}

const handleFileChange = (e) => {
  
    let selected = e.target.files[0];

    if (selected) {
      if (selected.size > maxSize) {
        setFile(null);
        setError('File too large. Maximum size is 3MB.');
      } else if (!acceptedFileTypes.includes(selected.type)) {
        setFile(null);
        setError('Invalid file type. Only JPEG and PNG files are accepted.');
      } else {
        setFile(selected);
        setError('');
      }
    }

}

const uploadImage = async () => {
  setSpinnerLoading(true);
  const formData = new FormData();
  formData.append('file', file);
  formData.append('chosenRecepyId', chosenRecepyId);
  
  const token = localStorage.getItem('auth_token');
  const headers = token ? { 
  'Authorization': `Bearer ${token}`
} : {};  

  try {
  //  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/uploadBsimage`, { 
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/uploadBsimage_Temp`, { 
      
      method: 'POST',
      headers: headers,
      body: formData
    });
    if (response.status === 200) {
      setSpinnerLoading(false);
      setAlertCode(0)
      setAlertMessage(imageUploadedMessage)
      setShowAlert(true)
      setTrigger(!trigger); 
    }else if(response.status===201){
      setSpinnerLoading(false);
      setAlertCode(0)
      setAlertMessage(imageuploadVoid)
      setShowAlert(true)
    }

  } catch (error) {
     setAlertCode(28015)
      setAlertMessage(selectedError[28015])
      setSpinnerLoading(false)
      setShowAlert(true)
  }
};

// useEffect(()=>{
// let imagetext=getRecepies.find(data=>data.receptid===chosenRecepyId)
// setImageText(imagetext)
// },[chosenRecepyId])

  return (
    <>
    <div className="xktotWrapper">
    {windowWidth<360?displayGadgetToSmall():''}
    <div className={AreaChoice()}>
            {getBackGroundShade<1?<img src={imageUrl} alt="Bakesoft" className="xkbackgroundImageRecepyNew" />:''}
        <div className="xkheaderArea">
            <div className={getBackGroundShade<1?"xkcompName":"xkcompName1"}>{title}</div>
        </div>

      <div className="xkrangeArea">
      <input type="range" min="0.0" max="1" value={getMenuShade||0.2} step="0.10" id={2}  
             title="Justera bakgrund" className="xkrange2" onChange={(e)=>SetmenuColorHandler(e.target.value)}/>
    
            <input type="range" min="0.0" max="1" value={getBackGroundShade||0.2} step="0.10" id={2}  
            title="Justera bakgrund" className="xkrange1" onChange={(e)=>SetBackGroundShadeHandler(e.target.value)}/>
            
       
     </div>


    


        <div className="xkareaA">
            <div className="xkareaATop">
  
                 <fieldset className="xkSelectIngredFieldset">
             
                     <legend className="xkSelectIngredLegend">{leftFirstSelectLegend}</legend>
                 
                     <div className="xkSelect">
                         <select className="xkSelectRecepySelect"
                          name="recepy" id="recepy" onChange={(e)=>categoryHandler(e.target.value)}>
                           <option value="0" hidden>&nbsp;&nbsp;{leftSelectFirstOption}&nbsp;!&nbsp;</option>
                         {getIngredCategories.map((data,index)=>{
                             
                             return(
                                <option key={index} value={data.kategoriid}>&nbsp;&nbsp;{data.benaemn}</option>
                             )
                         })}
                         </select>


                     </div>
     
                 </fieldset>
      
             

  
     <fieldset className="xkSelectRecepyFieldset">
                     <legend className="xkSelectRecepyLegend">{leftSecondSelectLegend}</legend>
                      <div className="xkSelect1">
                        <select className="xkSelectRecepySelect"
                          name="ingredients" id="ingred" onChange={(e)=>AddRecepieHandler(e.target.value)}>
                           <option value="0" hidden>&nbsp;&nbsp;{choseRecepyFirstOption}&nbsp;!&nbsp;</option>
                         {getRecepies.map((data,index)=>{
                             
                             return(
                                <option key={index} value={data.receptid}>&nbsp;&nbsp;{data.benaemn}</option>
                             )
                         })}
                         </select>
                      </div>
                         </fieldset>
            
    
        </div>
        <div className="xkareaABottom">
        <IngredientsByCategory manNew={manNew}  addDbOwnerIngredient={addDbOwnerIngredient}
        getIngredDataByCategory={getIngredDataByCategory} chosenCategory={chosenCategory}
        getPseudoVisible={getPseudoVisible}
        />
</div>
{getMobileIsUsed===false?
<div className="xkareaABottom1">

  <div className="xkcheckboxWrapper">
  <label htmlFor="pseudoVisible" className="xkcheckpseudoLabel">{checkboxShowPriceLabel}</label>
 <input 
  type="checkbox" 
  id="pseudoVisible" 
  className="xkcheckpseudo" 
  name="pseudoVisible" 
  checked={getPseudoVisible} 
  onChange={(e) => setPseudoVisible(e.target.checked)}
/>





</div>
  </div>
  :''}

        </div>






        
        <div className="xkareaB">

<div className="area-f">
    <div className="xktabs">
      <button className={getTabNr!==1?"xktab-button":"xktab-button1"} onClick={()=>showTab('info',1)}><CiImageOn  className="xkImageIcon"/><div className="xkIconText">{imageIconText}</div></button>
      <button className={getTabNr!==2?"xktab-button":"xktab-button1"}  onClick={()=>showTab('image',2)}><TbDeviceDesktopAnalytics className="xkRecipeIcon" /><div className="xkIconText">{recipeIconText}</div></button>
      <button className={getTabNr!==3?"xktab-button":"xktab-button1"}  onClick={()=>showTab('upload',3)}><RiUploadCloud2Line className="xkimageIconUpload" /><div className="xkIconText">{uploadIconText}</div></button>
      <button className={getTabNr!==4?"xktab-button":"xktab-button1"}  onClick={()=>showTab('download',4)}><RiDownloadCloud2Line className="xkimageIconUpload" /><div className="xkIconText">{downloadIconText}</div></button>
  
    </div>

    <div id="info" className="tab-content">



<div className="xkImageContainer">
{recipeImages.length > 0 ?
    Object.entries(recipeImages).map(([index, url]) => (
        <img key={index} src={url} alt={`Recipe ${index}`} className={getChosenImageIndex===index?"s3imagedata1":"s3imagedata"}
         onClick={()=>choseImageHandler(index)}
         onDoubleClick={()=>deleteImageHandlerContainer()}
         title={deleteImageTitle}
        />
    ))
    :
    <img src={noimagefound1} alt="My Image" className="s3imagedataNotFound" />
}
</div>
</div>

<div id="upload" className="tab-content">
 <UploadImage handleFileChange={handleFileChange} uploadImage={uploadImage}
 error={error} file={file}  chosenRecepyId={chosenRecepyId}
   choseImageButtontext={choseImageButtontext} choseImageUploadText={choseImageUploadText}
   recepyMustBeChosenBeforeUploadMessage={recepyMustBeChosenBeforeUploadMessage}
 />
 
    </div>
    
  <div id="download" className="tab-content">
        <div className="xkgenerateImageTop">
      <div className="xkgenerateImageWrapper">
      <textarea className="xkimageinput" placeholder={createImageTextPlaceholder} 
      value={getImageText} onChange={(e)=>setImageTextHandler(e.target.value)} />
     
      <button className={!getImageText?"xkgenerateImageButton":"xkgenerateImageButton1"} onClick={GenerateaiImage}
          disabled={!getImageText}
         >{createImageButtonText}</button>
   </div>
  
      </div>
    <div className="xkimageInformationTop">
      <div className="xkimageInformationText">
    {generateAIimageDisclaimer}
      </div>
    </div>
  </div>
  
    
    <div id="image" className="tab-content">
    

  
          <div className="xkareaBTop">
           
          <RecepyHeader manNew={manNew} chosenRecepyName={chosenRecepyName}
          recepyTitlePlaceHolder={recepyTitlePlaceHolder}
          ChangeRecepyNameHandler={ChangeRecepyNameHandler} 
          getRecepyHeaderData={getRecepyHeaderData}
          setFuriganaNameHandler={setFuriganaNameHandler}
          getFuriganaName={getFuriganaName}
          getShowFurigana={getShowFurigana}
          getMobileIsUsed={getMobileIsUsed}
          getIpadIsUsed={getIpadIsUsed}
          getCountry={getCountry}
          setShowFurigana={setShowFurigana}

          
        
          />
    

             <GetAiMessage manNew={manNew} RecepyData={RecepyData} expandedRow={expandedRow}
             IngredData={IngredData} handleRowClickIndex={handleRowClickIndex} chosenIngredid={chosenIngredid}
             getReceivedDataStatus={getReceivedDataStatus} deleteIngredientHandler={deleteIngredientHandler}
              addDbOwnerIngredient={addDbOwnerIngredient} getRecepyHeaderData={getRecepyHeaderData}
              changeAmountHandler={changeAmountHandler} getFoodInfoContainer={GetFoodInfoContainer}
               ingredCommentsHandler={ingredCommentsHandler} getnutritionData={getnutritionData}
               chosenRecepyId={chosenRecepyId} saveRecepyHandler={SaveRecepyHandler}
               HandleArbOrderClick={HandleArbOrderClick} getButtonSaveActive={getButtonSaveActive}
               SortBenaemnHandler={SortBenaemnHandler} SortOrderHandler={SortOrderHandler}
               SortKalories={SortKalories} getRecepyCategories={getRecepyCategories}
               addCategoryHandler={addCategoryHandler}
               titleMenuHeaderId={titleMenuHeaderId}  
               titleMenuHeaderIngrediens={titleMenuHeaderIngrediens}
               titleMenuHeaderMangd={titleMenuHeaderMangd}
               titleMenuHeaderKostnad={titleMenuHeaderKostnad}
               titleMenuHeaderKalorier={titleMenuHeaderKalorier}
               titleMenuHeaderOrder={titleMenuHeaderOrder}
               menuHeaderId={menuHeaderId}
               menuHeaderIngrediens={menuHeaderIngrediens}
               menuHeaderMangd={menuHeaderMangd}
               menuHeaderKostnad={menuHeaderKostnad}
               menuHeaderKalorier={menuHeaderKalorier}
               menuHeaderOrder={menuHeaderOrder} titleDeleteIngredient={titleDeleteIngredient}
               ingredientBoxCommentTitle={ingredientBoxCommentTitle}
               titleIngredientBoxMinusButton={titleIngredientBoxMinusButton}
               ingredientBoxMinusButtonText={ingredientBoxMinusButtonText}
               ingredientBoxPlusButtonText={ingredientBoxPlusButtonText}
               titleIngredientBoxPlusButton={titleIngredientBoxPlusButton}
               ingredientBoxCloseListButtonText={ingredientBoxCloseListButtonText}
               ingredientBoxGetNutrientButtonText={ingredientBoxGetNutrientButtonText}
               ingredientBoxNumberOfLetters={ingredientBoxNumberOfLetters}
               nutrientListHeaderSubject={nutrientListHeaderSubject}
               nutrientListHeaderAmount={nutrientListHeaderAmount}
               nutrientListHeaderValue={nutrientListHeaderValue}
               footerMenuTotalt={footerMenuTotalt}
               footerCalorieSummary={footerCalorieSummary}
               saveRecepySelectCategoryFirstOption={saveRecepySelectCategoryFirstOption}
               saveRecepyButtonText={saveRecepyButtonText}
               titleSaveRecepyButton={titleSaveRecepyButton}
             
               
    
              
             />
           
     
        <ViewNutritionSummary  nutrientSummary={nutrientSummary} manNew={manNew}
          titleSummaEnergiIRecept={titleSummaEnergiIRecept}
          titleTotalMangdKolhydraterIRecept={titleTotalMangdKolhydraterIRecept}
          titleTotalMangdProteinIRecept={titleTotalMangdProteinIRecept}
          titleTotalMangdFettIRecept={titleTotalMangdFettIRecept}
          nutrientEnergyLegend={nutrientEnergyLegend}
          nutrientKolhydraterLegend={nutrientKolhydraterLegend}
          nutrientProteinLegend={nutrientProteinLegend}
          nutrientFettLegend={nutrientFettLegend}
        /> </div>
        </div></div>

        </div>

        <div className={getResizeTextArea===false?"xkareaC":"xkareaC1"}>
          <RecepyDescriptionHeader manNew={manNew} getResizeTextArea={getResizeTextArea}
          recepyDescriptionHeader={recepyDescriptionHeader}
          ingredientBoxNumberOfLetters={ingredientBoxNumberOfLetters}
          setResizeTextAreaHandler={setResizeTextAreaHandler} getMobileIsUsed={getMobileIsUsed}
          
          />
          <ViewDescription manNew={manNew} descriptionHandler={descriptionHandler}
          ingredientBoxNumberOfLetters={ingredientBoxNumberOfLetters}
           getRecepyHeaderData={getRecepyHeaderData} getResizeTextArea={getResizeTextArea}
           />
   

        </div>

        <div className="xksearchSpinnerContainer">
        <div className="xksearchSpinner">
          <RotatingLines strokeColor="white" strokeWidth="2" animationDuration="0.75" width="70" visible={getSpinnerLoading}/>
        </div>
        </div>
        {getShowAlert===true?<Alert message={getAlertMessage} code={getAlertCode} onClose={handleCloseAlert}/>:''}
        {getShowYesNoAlert===true?<YesNoAlert message={getYesNoAlertMessage}  onCloseYes={handleCloseAlertYes} onCloseNo={handleCloseAlertNo}
  btnYes={btnYes} btnNo={btnNo}
  />:''}

    </div>
    </div>
    </>
  )
}

function GetAiMessage(props){
    let{manNew,
      RecepyData,getRecepyCategories,
      ingredCommentsHandler,expandedRow,handleRowClickIndex,chosenIngredid,
      deleteIngredientHandler,getFoodInfoContainer,changeAmountHandler,saveRecepyHandler,
     getReceivedDataStatus,getnutritionData,HandleArbOrderClick,
     getButtonSaveActive,SortBenaemnHandler,SortOrderHandler,SortKalories,addCategoryHandler,
     titleMenuHeaderId,titleMenuHeaderIngrediens, titleMenuHeaderMangd,
     titleMenuHeaderKostnad, titleMenuHeaderKalorier,titleMenuHeaderOrder,
     menuHeaderId,menuHeaderIngrediens,menuHeaderMangd,menuHeaderKostnad,menuHeaderKalorier,menuHeaderOrder
    
     ,titleDeleteIngredient,

     ingredientBoxCommentTitle,
     titleIngredientBoxMinusButton,
     ingredientBoxMinusButtonText,
     ingredientBoxPlusButtonText,
     titleIngredientBoxPlusButton,
     ingredientBoxCloseListButtonText,
     ingredientBoxGetNutrientButtonText,
     ingredientBoxNumberOfLetters,
     nutrientListHeaderSubject,
     nutrientListHeaderAmount,
     nutrientListHeaderValue,

     footerMenuTotalt,
     footerCalorieSummary,
     saveRecepySelectCategoryFirstOption,
     saveRecepyButtonText,titleSaveRecepyButton,


      }=props

    //so we need to check if the value is a number
    let totalCost=RecepyData.reduce((a,b)=>a+b.ADsum,0)
    //if the totalcost is undefined we will set it to 0
    if(isNaN(totalCost)){
      totalCost=0
    }else{
      totalCost = parseInt(totalCost, 10) || 0; 
    }

// Summarize the total kaloriekoeff * mangd
let totalsum = RecepyData.reduce((total, item) => total + (item.kaloriekoeff * item.mangd), 0);

//Check if the total is NaN and set it to 0 if it is
if (isNaN(totalsum)) {
  totalsum = 0;
} else {
  // totalsum = totalsum.toFixed(2);
  totalCost = parseInt(totalCost, 10) || 0; 
}


    // let totalsum = RecepyData.reduce((sum, data) => {
    //     if (data.kalories > 0) {
    //         // here we need to check if the kalories is a number
    //         // we parse we need decimals
    //         data.kalories=parseFloat(data.kaloriekoeff*data.mangd).toFixed(2)

    //       return sum + data.kalories;
    //     }
    //     return sum;
    //   }, 0);
   


    let totalAmount=RecepyData.reduce((a,b)=>a+b.mangd,0)
  
   // if the total amount is undefined we will set it to 0
    if(isNaN(totalAmount)){
      totalAmount=0
    }
    
    // if(chosenIngredid===0){
    //   totalAmount=0
    //   totalCost=0
    //   totalsum=0
    // }
  
   
    return(
        <div className="xkHeaderTop">
        <div className="xkheader">
          <button className="xkheaderButtonBenaemn" title={titleMenuHeaderIngrediens} onClick={()=>SortBenaemnHandler()}>{menuHeaderIngrediens}</button>
          <button className="xkheaderButtonAmount" title={titleMenuHeaderMangd}>{menuHeaderMangd}</button>
          <button className="xkheaderButton" title={titleMenuHeaderKostnad}>{menuHeaderKostnad}</button>
          <button className="xkheaderKcals" title={titleMenuHeaderKalorier} onClick={()=>SortKalories()}>{menuHeaderKalorier}</button>
          <button className="xkheaderOrder" title={titleMenuHeaderOrder} onClick={()=>SortOrderHandler()}>{menuHeaderOrder}</button>
          <button className="xkheaderDelete" title={titleMenuHeaderOrder} onClick={()=>SortOrderHandler()}></button>
       
    
          </div>

          <div className="xkreceivedContainer">
   {RecepyData.map((data,index)=>
  <div key={index+100} className="xkdatarows">
    <button className={index%2===0?"xkinfobuttonBenaemn":"xkinfobuttonBenaemn2"} 
  
  onClick={()=>handleRowClickIndex(index,data.ingredid)}>{String(data.benaemn).substring(0,30)}{data.btext?<AiOutlineInfoCircle className={{fontSize: '13px',color:'blue' ,cursor:'pointer',marginLeft:'22px'}} title="Klicka för mer information"/>:''}</button>
  <button className={index%2===0?"xkinfobuttonAmount":"xkinfobuttonAmount2"}>{data.mangd ||0}</button>
  <button className={index%2===0?"xkinfobutton":"xkinfobutton2"}>{String(data.ADsum).substring(0,4)||0}</button>
  <button className={index%2===0?"xkkcalsButton":"xkkcalsButton2"}>{Math.round(Number(data.kaloriekoeff)*Number(data.mangd)) || 0}</button>
  <button className={index%2===0?"xkarborderButton":"xkarborderButton2"} onClick={()=>HandleArbOrderClick(index)}>{String(data.arborder||0)}</button>
  <button className={index%2===0?"xkdelete":"xkdelete1"} 
  onClick={()=>deleteIngredientHandler(data.ingredid)}>
  <RiDeleteBackLine className="xkdeletebackline"
                   title={titleDeleteIngredient}/></button>

<div className="ingredcommentWrapper">
{/* //om vi har ett ingredid och vi ska expandera raden för att visa kommentar */}
{data.ingredid>0&&expandedRow === index && (<div className={getReceivedDataStatus===false?"xkanimateddiv20":"animateddiv21"}>

<IngredComments manNew={manNew} RecepyData={RecepyData}
 ingredCommentsHandler={ingredCommentsHandler} chosenIngredid={chosenIngredid} 
 changeAmountHandler={changeAmountHandler} getFoodInfoContainer={getFoodInfoContainer}
  getReceivedDataStatus={getReceivedDataStatus} getnutritionData={getnutritionData}

  ingredientBoxCommentTitle={ingredientBoxCommentTitle}
  titleIngredientBoxMinusButton={titleIngredientBoxMinusButton}
  ingredientBoxMinusButtonText={ingredientBoxMinusButtonText}
  ingredientBoxPlusButtonText={ingredientBoxPlusButtonText}
  titleIngredientBoxPlusButton={titleIngredientBoxPlusButton}
  ingredientBoxCloseListButtonText={ingredientBoxCloseListButtonText}
  ingredientBoxGetNutrientButtonText={ingredientBoxGetNutrientButtonText}
  ingredientBoxNumberOfLetters={ingredientBoxNumberOfLetters}
  nutrientListHeaderSubject={nutrientListHeaderSubject}
  nutrientListHeaderAmount={nutrientListHeaderAmount}
  nutrientListHeaderValue={nutrientListHeaderValue}
  /></div>)}
 </div>
</div>)}


<div className="xktotalCostContainer">
<button className="xktotalcostBenaemn">{footerMenuTotalt}:</button>
  <button className="xktotalamountButton">{totalAmount}</button>
  <button className="xktotalcostButton">{totalCost}</button>
  <button className="xktotalcaloriesButton">{Math.round(totalsum)}</button>
  <button className="xkavgcaloriesButton" title={footerCalorieSummary}>{String(totalsum/totalAmount).substring(0,4)}</button>
  <button className="xkavgcaloriesdelete"></button>
 
  </div>
  <div className="xkselectnewingredientContainer">
  <select className="xkselectdbOwnerIngredient" onChange={(e)=>addCategoryHandler(e.target.value)} id="2">
  <option value="0" hidden>&nbsp;&nbsp;{saveRecepySelectCategoryFirstOption}&nbsp;!&nbsp;</option>
           {getRecepyCategories.map((data,key)=><option key={key}value={data.kategoriId}>&nbsp;&nbsp;{data.Benaemn}</option>)}
    </select> 
        
        
    {/* {getRecepyHeaderData[0].benaemn.length>0?
    <SaveButton manNew={manNew} saveRecepyHandler={saveRecepyHandler}
    getButtonSaveActive={getButtonSaveActive}
    />:''} */}

  <SaveButton
    manNew={manNew}
    saveRecepyHandler={saveRecepyHandler}
    getButtonSaveActive={getButtonSaveActive}
    saveRecepyButtonText={saveRecepyButtonText}
    titleSaveRecepyButton={titleSaveRecepyButton}
 
 

  />


  </div>
  
    </div>
    
    </div>

)
}

function RecepyHeader(props){
    let {manNew,chosenRecepyName,ChangeRecepyNameHandler,getRecepyHeaderData,
      recepyTitlePlaceHolder,setFuriganaNameHandler,getFuriganaName,getShowFurigana,
      getMobileIsUsed,getIpadIsUsed,getCountry,setShowFurigana
    }=props

    // here we need to check if GetRecepyHeaderData.length is greater than 0
    // if it is we will show the name of the recepy from getRecepyHeaderData
    // if it is not we will show the name of the recepy from the state chosenRecepyName
    if(getRecepyHeaderData.length>0){
        chosenRecepyName=getRecepyHeaderData[0].benaemn
    }

    let setClassName=''

// here we make a selection of the class name depending on the device
  // if(getMobileIsUsed===true){
  //   setClassName='xkrecepyManHeaderJAPMobile'
  // }else if(getIpadIsUsed===true){
  //   setClassName='xkrecepyManHeaderJAPIPAD'
  // }
  // else{
  //   setClassName='xkrecepyManHeaderJAP'
  // }


    return(
        <>
        <div className="xkrecepyHeaderWrapper">
<input type="text" className={getCountry!=='ja'?"xkrecepyHeader":"xkrecepyHeaderMANJAP"}
value={chosenRecepyName||''} placeholder={recepyTitlePlaceHolder}
onChange={(e)=>ChangeRecepyNameHandler(e.target.value)}
onKeyPress={(e) => {
  if (e.key === 'Enter') {
    setShowFurigana(false);
  }
}}

/>
</div>

<div className="xkrecepyHeaderWrapper">
{getShowFurigana===true&&getCountry==='ja'?
<input type="text" className="xkrecepyHeaderJAP"
value={getFuriganaName}
onChange={(e)=>setFuriganaNameHandler(e.target.value)}
onKeyPress={(e) => {
  if (e.key === 'Enter') {
    setShowFurigana(false);
  }
}}

placeholder='ふりがな、作成したデータを管理するために使います'/>
:''}

</div>
  </>
  )
  }




  function IngredComments(props){
    let {manNew,ingredCommentsHandler,RecepyData,chosenIngredid,
      changeAmountHandler,getFoodInfoContainer,getReceivedDataStatus,getnutritionData,
      ingredientBoxCommentTitle,titleIngredientBoxMinusButton,ingredientBoxMinusButtonText,
      ingredientBoxPlusButtonText,titleIngredientBoxPlusButton,ingredientBoxCloseListButtonText,
      ingredientBoxGetNutrientButtonText,ingredientBoxNumberOfLetters,
      nutrientListHeaderSubject,nutrientListHeaderAmount,nutrientListHeaderValue

    
    
    }=props
    const editor = React.useRef(null)
  
  //here i need to get the comment for the ingredient¨
  let myNewObjArr=[...RecepyData]
  let commentIndex=myNewObjArr.findIndex(data=>data.ingredid==chosenIngredid)
  let comment = typeof myNewObjArr[commentIndex].btext === 'string' ? myNewObjArr[commentIndex].btext : '';

  // let comment=myNewObjArr[commentIndex].btext || ''
  // i want to know the length of the text here
  let textLength=comment.length||0
  let textmax=200
  
  
  
    return(
      <>
      <div className="xktextareaContainer">
        <textarea className="xktextareaComments" rows="5" cols="50" placeholder={ingredientBoxCommentTitle}
         onChange={(e)=>ingredCommentsHandler(e.target.value)} value={comment}/> 

<AiOutlinePlusCircle 
        className="xkAiOutlinePlusCircle" 
        onClick={()=>changeAmountHandler(2)}
        onDoubleClick={()=>changeAmountHandler(4)}
        title={titleIngredientBoxPlusButton} 
        /><br></br>

        <AiOutlineMinusCircle 
        className="xkAiOutlinePlusCircle"
        onClick={()=>changeAmountHandler(1)}
        onDoubleClick={()=>changeAmountHandler(3)}
        title={titleIngredientBoxMinusButton} 
        />

<div className="xkbuttonFoodInfoWrapper">
          {getReceivedDataStatus===false?
          <button className="xkbuttonFoodInfo"
          onClick={getFoodInfoContainer} title={ingredientBoxGetNutrientButtonText}>{ingredientBoxGetNutrientButtonText}</button>:
          <button className="xkbuttonFoodInfo" onClick={getFoodInfoContainer} title={ingredientBoxCloseListButtonText}>{ingredientBoxCloseListButtonText}</button>}
          <div className="xknumTecken">&nbsp;&nbsp;{ingredientBoxNumberOfLetters}&nbsp;{textLength}/{textmax}
      </div></div>
      
     
       </div>
       <div className="xkfoodareaWrapper">
       {getReceivedDataStatus===true?<div className="xkfoodareaContainer">
        <div className="xkfoodAreaHeader">
      
        <button className="xkheaderButtonNutrient">{nutrientListHeaderSubject}</button>
        <button className="xkheaderButtonQuantity">{nutrientListHeaderAmount}</button>
        <button className="xkheaderButtonUnit">{nutrientListHeaderValue}</button>
        </div>
  
         <div className="xkfoodArea">
          {getnutritionData.map((data,index)=>(
            <div key={index+100}>
            <button className={index%2===0?"xknutrientValue":"xknutrientValue2"}>{data.nutrient}</button>
            <button className={index%2===0?"xknutrientQuantity":"xknutrientQuantity2"}>{data.quantity}</button>
            <button className={index%2===0?"xknutrientUnit":"xknutrientUnit2"}>{data.unit}</button>
            </div>
            
          ))}
          
          
       </div>
        </div>:''}
   </div>
        </>
    )
  }

  function ViewDescription(props){
    let {manNew,getRecepyHeaderData,descriptionHandler,getResizeTextArea,ingredientBoxNumberOfLetters}=props
    let textmax=2500
    let textLength=0
    //here we need to check if GetRecepyHeaderData is undefined
    if (getRecepyHeaderData.length > 0) {
      if (getRecepyHeaderData[0].btext) {
          textLength = getRecepyHeaderData[0].btext.length || 0;
      } else {
          // Handle the case where btext is undefined or falsy
          textLength = 0; // or provide a default value
      }
  } else {
      // Handle the case where getRecepyHeaderData is an empty array
      textLength = 0; // or provide a default value
  }
  

    return(
      <div>
       <textarea className={getResizeTextArea===false?"xkkviewdescription":"xkkviewdescription1"}
    value={getRecepyHeaderData.length>0?getRecepyHeaderData[0].btext:''} onChange={(e)=>descriptionHandler(e.target.value)}/>
          <div className={getResizeTextArea===false?"xknumTecken1":"xknumTecken2"}>{ingredientBoxNumberOfLetters}&nbsp;{textLength}/{textmax} </div>
      </div>

    )
  }

  function SaveButton(props){
    let {manNew,saveRecepyHandler,getButtonSaveActive,saveRecepyButtonText,titleSaveRecepyButton,
      getRecepyHeaderData,chosenSaveRecepyCategory
    }=props
 
    return(
      <div>
        <button className={getButtonSaveActive===false?"xksaveRecepyButton":"xksaveRecepyButtonActive"} 
        onClick={saveRecepyHandler} title={titleSaveRecepyButton}
      disabled={getButtonSaveActive===false?true:false}
        >{saveRecepyButtonText}</button>
      </div>
    )
  }



  function ImageViewer(props){
    let {imageToview,manNew}=props
    return(
      <div>
    
    <img src={imageToview} alt="User Profile" className="xkimageContainer"/>
 
      </div>
    )
  }

  function ViewRecepyCommentFromAi(props){
    let {manNew,recepyComments,chosenRecepyId}=props

    return(
<div className="xkviewRecepyCommentFromAiContainer">
  <div className="xkviewRecepyCommentFromAiHeader">{chosenRecepyId?'Kommentar från AI':''}</div>
    <button className="xkviewRecepyCommentFromAiButton">{recepyComments?recepyComments.content:''}</button>
</div>
    )
  }


  function ImageSlider(props){
    let {manNew,getImages,showImage,currentImageIndex}=props




    return (
      <div>
        {showImage && (
          <img
            src={`${getImages[currentImageIndex]}`}
            alt={`Image ${currentImageIndex + 1}`}
            className="xkimageContainer"
          />
        )}
      </div>
    );
  };

  function RecepyDescriptionHeader(props){
    let {manNew,getResizeTextArea,setResizeTextAreaHandler,getMobileIsUsed,
       recepyDescriptionHeader
    }=props

    return(
      <div className={getResizeTextArea===false?"xkrecepyDescriptionHeader":"xkrecepyDescriptionHeader1"}>{recepyDescriptionHeader}
   
   {getMobileIsUsed===false?
    getResizeTextArea===false?
   <FaBook className="xkresizeBookIcon" onClick={()=>setResizeTextAreaHandler()}/>:
   <IoBookSharp className="xkresizeBookIcon1" onClick={()=>setResizeTextAreaHandler()}/>
    :''}

      </div>
    )
  }

  function IngredientsByCategory(props){
    let {addDbOwnerIngredient,getIngredDataByCategory,getPseudoVisible
    }=props

    // here when i receive the getingredDataByCategory i will map through the array
    // Find the lowest and highest prices
let lowestPrice = Math.min(...getIngredDataByCategory.map(item => item.pris));
let highestPrice = Math.max(...getIngredDataByCategory.map(item => item.pris));
let midRange = (lowestPrice + highestPrice) / 2;
 

let closest = getIngredDataByCategory.length>0 && getIngredDataByCategory.reduce((prev, curr) => {
  return (Math.abs(curr.pris - midRange) < Math.abs(prev.pris - midRange) ? curr : prev);
});

let newData = getIngredDataByCategory.map(item => {
  let pris = isNaN(item.pris) ? 0 : Number(item.pris);
  let isMid = Math.abs(pris - midRange) <= midRange * 0.15; // Check if the price is within 10% of the mid range
  return {
    ...item,
    lowest: pris === lowestPrice ? 1 : 0,
    highest: pris === highestPrice ? 1 : 0,
    mid: item === closest ? 1 : 0,
    mid1: isMid ? 1 : 0
  };
});

  



return(
    <div className="xkingredButtonContainer">               
{newData.map((data,index)=>
  <div key={index}>
    <button 
        className={`${index%2===0?"xkshowIngredButton1":"xkshowIngredButton"} ${!getPseudoVisible ? 'hide-pseudo' : ''}`}
      value={data.ingredid} 
      onClick={() => addDbOwnerIngredient(data.ingredid)}
      data-price={data.pris && data.pris>0&&Math.floor(data.pris)} // Convert the price to an integer before setting it as the data-price attribute
      data-lowest={data.lowest ==1 ? "1" : "0"}
      data-highest={data.highest == 1 ? "1" : "0"}
      data-mid={data.mid == 1 ? "1" : "0"}
      data-mid1={data.mid1 == 1 ? "1" : "0"}
    >
      {data.benaemn}
    </button>
  </div>
)}

</div>
)

  }

  function ViewNutritionSummary(props){
    let {manNew,nutrientSummary,

      titleSummaEnergiIRecept,titleTotalMangdKolhydraterIRecept,titleTotalMangdProteinIRecept,titleTotalMangdFettIRecept,
      nutrientEnergyLegend,nutrientKolhydraterLegend,nutrientProteinLegend,nutrientFettLegend
    
    }=props
  
    return(
        <div className="xknutrientSummaryContainer">
            <div className="xkviewNutrientData">
            <BsFire className="xkfireIcon" />
            <button className="xknutrientSummaryButtonKcals" title={titleSummaEnergiIRecept}>{nutrientEnergyLegend}:<br></br>{nutrientSummary.totalKcals||0}</button>
            <LuWheat className="xkwheatIcon" />
            <button className="xknutrientSummaryButtonCarb" title={titleTotalMangdKolhydraterIRecept}>{nutrientKolhydraterLegend}:<br></br>{nutrientSummary.totalCarbohydrates||0}g&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</button>
            <TbMeat className="xkmeatIcon" />
            <button className="xknutrientSummaryButtonProtein" title={titleTotalMangdProteinIRecept}>{nutrientProteinLegend}:<br></br>{nutrientSummary.totalProtein||0}g</button>
            <TbCheese className="xkcheeseIcon" />
            <button className="xknutrientSummaryBFats" title={titleTotalMangdFettIRecept}>{nutrientFettLegend}:<br></br>{nutrientSummary.totalFat||0}g</button>
        </div></div>
    )
  }

  
function UploadImage(props) {
  let {handleFileChange,uploadImage,error,file,chosenRecepyId,choseImageButtontext,
  choseImageUploadText,recepyMustBeChosenBeforeUploadMessage
  } = props;


  return (
  <>
  <div className="xkimageUploadContainer">
  <div className="xkimageUploadWrapper">
      <input type="file" onChange={handleFileChange} id="file"
      accept=".png, .jpg, .jpeg" 
      className="xkinputimageUpload"
      />
        <label htmlFor="file" className="xkfileLabel">{choseImageButtontext}</label>
      {error && <div className="xkerror">{error}</div>}
      {file && <div className="xkfileName">{file.name}</div>}

      </div>
    
      <div className="xkbuttonUploadImageWrapper">
      <button onClick={uploadImage} className={error||!file||!chosenRecepyId?"xkbuttonUploadImage":"xkbuttonUploadImage1"}
      disabled={error||!file}>{choseImageUploadText}</button>
    </div></div>
    {/* <div className="xkimageUploadMessage">{!chosenRecepyId?recepyMustBeChosenBeforeUploadMessage:''}</div> */}
    </>
  );
}






export {CreateManRecepyNew,GetAiMessage,RecepyHeader,IngredComments,ViewDescription,
  SaveButton,ViewNutritionSummary,ImageViewer,ViewRecepyCommentFromAi,ImageSlider,
  RecepyDescriptionHeader,IngredientsByCategory,UploadImage}