const languages = {
    se: {
      common: {
        greeting: "Hej",
        farewell: "Hej då"
      },
      ingredienslista:{
        title: "Ingrediens lista - mina ingredienser",
        titleMobile: "Ingrediens lista",
        kategoriLegend: "Välj kategori",
        selectFirstOption: "Välj kategori",
        headerId: "Id",
        headerNamn: "Namn",
        headerAktiv: "Aktiv",
        headerDela: "Dela",
        headerSpara: "Spara",
        ListSaveButton: "Spara",
        SavedOnButton:"Sparad",
        titleAdjustMenu:"Justera menyfärg",
        titleAdjustMenu1:"Justera bakgrund",

      },
      receptlista: {
        title: "Recept lista - mina recept",
        titleMobile: "Recept lista",
        kategoriLegend: "Välj kategori",
        selectFirstOption: "Välj kategori",
        headerId: "Id",
        headerNamn: "Namn",
        headerAktiv: "Aktiv",
        headerDela: "Dela",
        headerSpara: "Spara",
        ListSaveButton: "Spara",
        SavedOnButton:"Sparad",
        titleAdjustMenu:"Justera menyfärg",
        titleAdjustMenu1:"Justera bakgrund",
      },
      produktlista: {
        title: "Produkt lista - mina produkter",
        titleMobile: "Produkt lista",
        kategoriLegend: "Välj kategori",
        selectFirstOption: "Välj kategori",
        headerId: "Id",
        headerNamn: "Namn",
        headerAktiv: "Aktiv",
        headerDela: "Dela",
        headerSpara: "Spara",
        ListSaveButton: "Spara",
        SavedOnButton:"Sparad",
        titleAdjustMenu:"Justera menyfärg",
        titleAdjustMenu1:"Justera bakgrund",
      },
      ingredienser:{

        title:"Ingredienslista - mina ingredienser",
        titleMobile: "Ingredienser",
        ingredbutton:"Ingrediens-lista",
        ingredcreatebutton:"Skapa ingrediens",
        receptkoppbutton:"Recept-kopplingar",
        produktkoppbutton:"Produkt-kopplingar",
        inkopbutton:"Inköpspris",
        prisdiabutton:"Pris-diagram",
        nutrientbutton:"Näringsämnen",
        konvertbutton:"Konvertering",
        ingredinfobutton:"Ingrediens-information",
        chooseingredcategoryFieldset:"Välj ingrediens kategori",
        ingredcategoryFirstOption1:"Välj kategori",
        changeingredNameFieldset:"Ändra ingrediens namn",
        changeingredNameSaveButton:"Spara",
        nutrientHeaderHas:"har",
        nutrientHeaderReceptKopp:"recept kopplingar",
        nutrientHeaderAnd:"och",
        nutrientHeaderProdkopp:"produkt kopplingar",
        nutrientHeaderSmall:"Ingrediens har kopplingar, kan ej tas bort",
        nutrientDeleteInfoButton:"Ta bort ingrediens",
        nutrientlistTopSubject:"Näringsämnen per 100gr",
        nutrientlistTopAmount:"Mängd",
        nutrientListTopWeight:"Vikt",
        createingredientNameLegend:"Ingrediens namn",
        createingredientSaveButton:"Spara Ingrediens",
        produktkoppHeaderLegendText:"Inga produkter kopplade till",
        produktkoppInputHeaderTextLegend:"Ersätt",
        produktkoppInputHeaderTextLegend2:"i valda produkter med",
        produktkoppMessageTextLegend:"st valda produkter att ändra",
        produktkoppSaveButtonText:"Ersätt & spara",
        inkopsprisChangePriceLegend:"Uppdatera pris på",
        inkopsprisLatestPriceLegend:"Senaste pris per kg:",
        inkopsprisListHeaderLegend:"Inköps data för",
        prisdiagramChoseChartLegend:"Välj chart",
        nutrientHeaderLegend:"Näringsämnen",
        nutrientFetchDataButtonText:"Hämta näringsämnen",
        nutrientSaveDataButtonText:"Spara",
        nutrientListHeaderTextSubject:"Näringsämnen per 100gr",
        nutrientListHeaderTextAmount:"Mängd",
        nutrientListHeaderTextWeight:"Vikt",
        konvertHeaderTextLegend:"Konvertera",
        konvertHeaderTextLegend2:"till gram",
        konvertSaveButtonText:"Konvertera",
        ingredInfoHeaderTextlegend:"Information om",
        ingredInfoGetInfoButtonText:"Hämta information",
        ingredInfoGetNewInfoText:"Hämta ny information",
        ingredcanberemovedText:"Ingrediens kan tas bort!",
        recepyConnectionHeader:"Recept kopplade till",
        recepySelectHeaderLegend:"ersätt",
        recepySelectHeaderLegend2:"i valda recept med",
        recepySelectedStatementLegend:"st valda recept att ändra",
        recepyConnectionSaveButtonText:"Ersätt & spara",
        produktkoppHeaderTextLegend2:"Produkter kopplade till",
        inkopPriceHeaderBenaemn:"Inköps datum",
        inkopPriceHeaderPrice:"Pris",
        inkopChangePriceSaveButton:"Spara",
        prisdiagramHeaderLegend:"Prisutveckling för",
        konvertDisclaimerText:"OBS! Konvertering är endast ett ungefärligt värde som vi hämtar efter sökning på internet, eller hos leverantör. Vi bedömmer att data som oftast är användbar, men vid extra noggrant behov, ber vi er att kontrollera fler källor.",
        konvertGramText:"gram",
        measureTableSpoon:"Matsked",
        measureTeaSpoon:"Tesked",
        measureDeciliter:"Deciliter",
        measureMilliliter:"Milliliter",
        measurePinch:"Kryddmått",
        ingredSelect:"Välj ingrediens",
        measureSelect:"Välj mått",

      },
      ingredcategories:{
        title:"Ingrediens kategorier",
        titleMobile:"Ingrediens kategorier",
        categoryHeaderKategori:"Kategori",
        categoryHeaderAntal:"Antal",
        categoryMoveIngredLegend:"Flytta ingrediens",
        categorySelectFirstOption:"Välj kategori",
        categoryButtonMoveSubmit:"Flytta",
        categoryCreateCategorySubmit:"Skapa kategori",
        furiganaLabel:"",
      },
      recepycategories:{
        title:"Recept kategorier",
        titleMobile:"Recept kategorier",
        categoryHeaderKategori:"Kategori",
        categoryHeaderAntal:"Antal",
        categoryMoveIngredLegend:"Flytta ingrediens",
        categorySelectFirstOption:"Välj kategori",
        categoryButtonMoveSubmit:"Flytta",
        categoryCreateCategorySubmit:"Skapa kategori"
      },
      productcategories:{
        title:"Produkt kategorier",
        titleMobile:"Produkt kategorier",
        categoryHeaderKategori:"Kategori",
        categoryHeaderAntal:"Antal",
        categoryMoveIngredLegend:"Flytta ingrediens",
        categorySelectFirstOption:"Välj kategori",
        categoryButtonMoveSubmit:"Flytta",
        categoryCreateCategorySubmit:"Skapa kategori",
      },
      myrecipes:{
        title:"Mina recept",
        titleMobile:"Mina recept",
        leftSelectLegendMinaRecept:"Recept kategorier",
        leftSelectFirstOption:"Välj kategori",
        aiHeader:"Kommentar från AI",
        listHeaderId:"Id",
        listHeaderIngrediens:"Ingrediens",
        listHeaderMangd:"Mängd",
        listHeaderKostnad:"Kostnad",
        listHeaderKal:"Kal",
        listHeaderOrder:"Order",
        ingredCommentBoxInfo:"Kommentar till ingrediens",
        ingredInfoBoxPlus:"Plus",
        ingredInfoBoxMinus:"Minus",
        getNutrientInfoButton:"Hämta näringsvärden",
        hideNutrientValues:"Dölj näringsvärden",
        antalTeckenInfoText:"Antal tecken",
        addIngredientSelectLegend:"Ingredienser",
        addIngredientSelectFirstOption:"Välj ingrediens",
        numPortionSelectLegend:"Antal portioner",
        numPortionsSelectlegendMobile:"Portioner",
        portionSizeInputLegend:"Storlek/portion i gram",
        portionSizeInputLegendMobile:"Storlek/portion",
        portionInputText:"Storlek i gram",
        saveRecepyButtonText:"Spara recept",
        saveRecepyButtonTextMobile:"Spara",
        sizeRecepyDoublerLegend:"Storlek på recept",
        nutrientEnergyLegend:"Energi",
        nutrientKolhydraterLegend:"Kolhydrater",
        nutrientProteinLegend:"Protein",
        nutrientFettLegend:"Fett",
        rightHeaderBeskrivning:"Beskrivning",
        rightBottomAntalTecken:"Antal tecken",
        titleJusteraBakgrund:"Justera bakgrund",
        titleTaBortDettaRecept:"Ta bort detta recept",
        titleDatabasensIngrediensId:"Databasens ingrediens id",
        titleKlickaforattSortera:"Klicka för att sortera",
        titleMangdIgramPerIngrediens:"Mängd i gram per ingrediens",
        titleKostnadPerIngrediens:"Kostnad per ingrediens",
        titleKalorier:"Kalorier",
        titleKlickaForMerInformation:"Klicka för mer information",
        titleTaBortRecept:"Ta bort recept",
        titleAntalKalorierPerGramKalorierPerPortion:"Antal kalorier per gram/kalorier per portion",
        titleAndraStorlekPaRecept:"Ändra storlek på recept",
        titleSummaEnergiIRecept:"Summa energi i recept",
        titleTotalMangdKolhydraterIRecept:"Total mängd kolhydrater i recept",
        titleTotalMangdProteinIRecept:"Total mängd protein i recept",
        titleTotalMangdFettIRecept:"Total mängd fett i recept",
        titleHamtaNaringsvarde:"Hämta näringsvärde",
        titleMinskaMangd:"Minska mängd",
        titleOkaMangd:"Öka Mängd",
        titleHamtaNaringsvarde:"Hämta näringsvärde",
        titleDeleteFromRecipe:"Ta bort ingrediens från recept",
        textAreaKomment:"Kommentar till ingrediens",
        nutrientListHeaderSubject:"Näringsämne per 100gr",
        nutrientListHeaderAmount:"Mängd",
        nutrientListHeaderValue:"Värde",
        footerTotalt:"Totalt",
        titleTotalSummary:"Antal kalorier per gram/kalorier per portion",
        titleDoublerLegend:"Ändra storlek på recept",
        searchLegend:"Sök recept",
        searchPlaceholdertext:"Recept namn",
        searchButton:"Sök",
        imageIconText:"Bilder",
        recipeIconText:"Recept",
        uploadIconText:"Ladda upp bild",
        downloadIconText:"AI bild",
        descriptionIconText:"Beskrivning",
        deleteImageTitle:"Dubbelklicka för att ta bort bild",
        choseImageButtontext:".Välj bild",
        choseImageUploadText:"Ladda upp bild",
        recepyMustBeChosenBeforeUploadMessage:"Ett recept måste väljas innan man kan ladda upp en bild!",
        createImageTextPlaceholder:"Skriv in text för bild",
        createImageButtonText:"Skapa AI bild",
        generateAIimageDisclaimer:" Generering av bilder med AI ingår inte i ditt abonnemang. Det tillkommer alltid extra kostnad för denna tjänst. Priser finns på ditt konto - prislista.",
        publishRecipeHeader:"Publicera detta recept på Live",
        publishSalesPriceText:"Pris för att låsa upp recept",
        publishDifficultyText:" Välj Svårighetsgrad",
        publishTimeText:"Tid",
        publishDifficultyOption1:"Lätt",
        publishDifficultyOption2:"Medel",
        publishDifficultyOption3:"Svår",
        publishButtonText:"Publicera",
        inputPriceText:"Pris i krediter",
        inputMinuteText:"Tid i minuter",



      },
      mancreaterecipe:{
title:"Skapa recept - manuellt",
titleMobile:"Skapa recept manuellt",
leftFirstSelectLegend:"Starta med ingrediens",
leftSecondSelectLegend:"Starta med ett recept",
leftSelectFirstOption:"Välj ingrediens",
recepyTitlePlaceHolder:"Namn på recept",
menuHeaderId:"Id",
menuHeaderIngrediens:"Ingrediens",
menuHeaderMangd:"Mängd",
menuHeaderKostnad:"Kostnad",
menuHeaderKalorier:"Kal",
menuHeaderOrder:"Order",
titleMenuHeaderId:"Databasens ingrediens id",
titleMenuHeaderIngrediens:"Ingrediens, klicka för att sortera",
titleMenuHeaderMangd:"Mängd i gram per ingrediens",
titleMenuHeaderKostnad:"Kostnad per ingrediens",
titleMenuHeaderKalorier:"Kalorier, klicka för att sortera",
titleMenuHeaderOrder:"Klicka för att sortera",
titleDeleteIngredient:"Klicka för att ta bort ingrediens från recept",
ingredientBoxCommentTitle:"Kommentar till ingrediens",
ingredientBoxPlusButtonText:"Plus",
titleIngredientBoxPlusButton:"Öka mängd med 1 eller 10(dubbelklick)",
ingredientBoxMinusButtonText:"Minus",
titleIngredientBoxMinusButton:"Minska mängd med 1 eller 10(dubbelklick)",
ingredientBoxGetNutrientButtonText:"Hämta näringsvärden",
ingredientBoxCloseListButtonText:"Dölj näringsvärden",
ingredientBoxNumberOfLetters:"Antal tecken",
footerMenuTotalt:"Totalt",
footerCalorieSummary:"Antal kalorier per gram",
saveRecepySelectCategoryFirstOption:"Spara i kategori",
saveRecepyButtonText:"Spara recept",
titleSaveRecepyButton:"Spara recept i mina recept",
nutrientEnergyLegend:"Energi",
nutrientKolhydraterLegend:"Kolhydrater",
nutrientProteinLegend:"Protein",
nutrientFettLegend:"Fett",
titleSummaEnergiIRecept:"Summa energi i recept",
titleTotalMangdKolhydraterIRecept:"Total mängd kolhydrater i recept",
titleTotalMangdProteinIRecept:"Total mängd protein i recept",
titleTotalMangdFettIRecept:"Total mängd fett i recept",
titleAdjustBackGround:"Justera backgrund",
recepyDescriptionHeader:"Beskrivning",
recepyDescriptionAntalTecken:"Antal tecken",
nutrientListHeaderSubject:"Näringsämne per 100gr",
nutrientListHeaderAmount:"Mängd",
nutrientListHeaderValue:"Värde",
choseRecepyFirstOption:"Välj recept",
checkboxShowPriceLabel:"Visa pris",
recipeIconText:"Recept",
imageIconText:"Bilder",
createImageButtonText:"Skapa AI bild",
createImageTextPlaceholder:"Skriv in text för bild",
deleteImageTitle:"Dubbelklicka för att ta bort bild",
generateAIimageDisclaimer:" Generering av bilder med AI ingår inte i ditt abonnemang. Det tillkommer alltid extra kostnad för denna tjänst. Priser finns på ditt konto - prislista.",
uploadIconText:"Ladda upp bild",
downloadIconText:"AI bild",
choseImageButtontext:".Välj bild",
choseImageUploadText:"Ladda upp bild",
recepyMustBeChosenBeforeUploadMessage:"Ett recept måste väljas innan man kan ladda upp en bild!",
    
},
  createAirecepy:{
title:"Skapa recept med AI",
titleMobile:"Skapa recept med AI",
searchInputLegend:"Sök AI för ett recept",
sendButtonText:"Fråga AI om ett recept",
sendButtonTextClicked:"Väntar...",
recpyArrivedButtonHeaderText:"AI har hittat följande recept",
listHeaderId:"Id",
listHeaderIngrediens:"Ingrediens",
listHeaderMangd:"Mangd",
listHeaderSum:"Sum",
listHeaderKcals:"Kcal",
listHeaderBakesoftMatch:"Bakesoft match",
listHeaderBakesoftMatchMobile:"Bs match",
titleAddIngredient:"Lägg till ingrediens i databas",
titleDeleteFromRecipe:"Ta bort från recept",
footerTotalt:"Totalt",
saveNewIngredientHeader:"Spara ny Ingrediens",
saveIngredientBoxName:"Namn",
saveIngredientBoxCategory:"Kategori",
saveIngredientBoxpPrice:"Pris",
saveIngredientBoxSaveButtonText:"Lägg till",
saveIngredientPricePlaceHolder:"Pris per kg",
titleAdjustBackground:"Justera bakgrund",
selectAddIngredientFirstOption:"Lägg till ingrediens",
saveRecepyBoxRecepyNameTitle:"Namn på recept",
saveRecepyBoxRecepyCategoryFirstOption:"Välj kategori",
saveRecepyBoxSaveRecepyButtonText:"Spara",
openSaveRecepyBoxButtonText:"Spara",
descriptionHeader:"Beskrivning",
descriptionCharacterLength:"Antal tecken",
ingredientBoxCommentTitle:"Kommentar till ingrediens",
ingredientBoxPlusButtonText:"Plus",
titleIngredientBoxPlusButton:"Öka mängd med 1 eller 10(dubbelklick)",
ingredientBoxMinusButtonText:"Minus",
titleIngredientBoxMinusButton:"Minska mängd med 1 eller 10(dubbelklick)",
selectIngredientFirstOption:"Välj ingrediens",  
ailastSearchMessage:"Senaste AI sökningen",
deleteingredientfromAiRecepy:"Ta bort ingrediens från recept",
saveRecepyTitle:"Spara recept",
},
createProduct:{
title:"Skapa produkt",
titleMobile:"Skapa produkt",
leftSelectLegend:"Välj produkt kategori",
leftSelectFirstOption:"Välj kategori",
titleCreateNewProduct:"Skapa ny produkt",
middleFirstSelectLegend:"Välj ingrediens",
middleFirstSelectLegendMobile:"Ingrediens",
middleSecondSelectLegend:"Välj recept",
middleSecondSelectLegendMobile:"Recept",
middleThirdSelectLegend:"Välj förpackning",
middleThirdSelectLegendMobile:"Förpackning",
middleFirstSelectFirstOption:"Ingredienser",
middleSecondSelectFirstOption:"Recept",
middleThirdSelectFirstOption:"Förpackning",
headerId:"Id",
headerName:"Namn",
headerAmount:"Mängd",
headerTotal:"Totalt",
headerCalories:"Kalorier",
titleSaveMenuOpenButton:"Spara meny",
rightHeader:"Produkt kalkyl",
totalCostLegend:"Totalkostnad",
prisHeaderLegend:"Pris",
marginalHeaderLegend:"Marginal",
nutrientHeaderLegend:"Näringsvärden",
caloriesHeaderLegend:"Kalorier",
proteinHeaderLegend:"Protein",
fatHeaderLegend:"Fett",
carbonHeaderLegend:"Kolhydrater",
titleAdjustMenu:"Justera menyfärg",
titleAdjustMenu1:"Justera bakgrund",
titleDeleteFromProduct:"Ta bort ifrån produkt",
titleTrash:"Ta bort produkt",
titleIncreaseAmount:"Tryck för att öka mängd",
titleDecreaseAmount:"Tryck för att minska mängd",
saveProduktButtonText:"Spara produkt",
titleKlickToSave:"Klicka för att spara",
saveProduktBoxMenuHeader:"Spara produkt",
namePlaceHolderText:"Namn",
recipeIconText:"Recept",
imageIconText:"Bilder",
uploadIconText:"Ladda upp bild",
downloadIconText:"AI bild",
generateAIimageDisclaimer:" Generering av bilder med AI ingår inte i ditt abonnemang. Det tillkommer alltid extra kostnad för denna tjänst. Priser finns på ditt konto - prislista.",
choseImageButtontext:".Välj bild",
choseImageUploadText:"Ladda upp bild",
recepyMustBeChosenBeforeUploadMessage:"En produkt måste väljas innan man kan ladda upp en bild!",
createImageButtonText:"Skapa AI bild",
createImageTextPlaceholder:"Skriv in text för bild",
deleteImageTitle:"Dubbelklicka för att ta bort bild",   
produkttitle:"Produkt",
noChosenProdukt:"Ingen produkt vald",
descriptionIconText:"Beskrivning",
saveDescriptionButtonText:"Spara",
placeholderDescriptionText:"Produkt beskrivning här...",
  

},
productStatistics:{
title:"Produkt statistik",
titleMobile:"Produkt statistik",
leftSelectLegend:"Produkt kategorier",
leftSelectFirstOption:"Välj kategori",
fromLegend:"Från:",
toLegend:"Till:",
rightStatisticLegend:"Statisik för produkt",
chartLegend:"Välj chart",
titleAdjustMenu:"Justera menyfärg",
titleAdjustMenu1:"Justera bakgrund",
},
workwithProduction:{
  title:"Arbeta med Produktion",
titleMobile:"Produktion",
leftFirstSelectLegend:"Skapa ny produktion",
leftFirstSelectFirstOption:"Välj kategori",
leftSecondSelectFirstOption:"Välj produkt",
leftThirdSelectLegend:"Pågående produktion",
leftThirdSelectFirstOption:"Välj produktion",
leftFourthSelectLegend:"Tidigare produktion",
leftFourthSelectFirstOption:"Välj historik",
middleHeaderId:"Pid",
middleHeaderProdid:"Prodid",
middleHeaderName:"Namn",
middleHeaderAmount:"Antal",
middleHeaderPrice:"Pris",
middleHeaderTotal:"Totalt",
middleHeaderStatus:"Status",
titleButtonOpenSaveMenu:"Spara meny",
titleIncreaseAmount:"Klicka för att öka mängd",
titleDeacreaseAmount:"Klicka för att minska mängd",
saveProductionBoxHeaderLegend:"Spara produktion",
saveProductionInputPlaceHolder:"Namn på produktion",
createProductionButtonText:"Skapa ny produktion",
saveProductionButtonText:"Spara",
clearProductionButtonText:"Klarskriv produktion",
rightHeaderLegend:"Sammanställning pågående produktion",
rightHeaderPid:"Pid",
rightHeaderCategoryId:"Kid",
rightHeaderCategory:"Kategori",
rightHeaderSum:"Summa",
rightHeaderAmount:"Antal",
titleRightHeaderPid:"Produktions id",
titleRightHeaderCategoryId:"Kategori id",
titleAdjustMenu:"Justera menyfärg",
titleAdjustMenu1:"Justera bakgrund",
middleHeaderMobileProdid:"Id",
clearProductInproductionButtonText:"Klarskriv",
clearProductInProductionButtonText1:"Klar",
titleCreateNewProduct:"Skapa ny produkt",
},
totalprodNew:{
title:"Totalt antal produkter i produktion",
titleMobile:"Totalt i produktion",
leftHeaderLegend:"Totalt att producera",
headerMenuProdid:"Prodid",
headerMenuProductid:"Produktid",
mobileMenuProductid:"id",
headerMenuProductName:"Namn på produkt",
headerMenuProductQuantity:"Antal",
headerMenuProductPrice:"Pris",
headerMenuProductTotal:"Totalt",
rightMenuHeaderLegend:"Totalt netto",
listHeaderLegend:"Sammanställning av pågående produktion",
rightListHeaderPID:"Pid",
rightListHeaderKID:"Kid",
rightListHeaderCategory:"Kategori",
rightListHeaderAmount:"Summa",
rightListHeaderQuantity:"Antal",
titleHeaderName:"Sortera på namn",
titleHeaderAntal:"Sortera på pris",
titleAdjustMenu:"Justera menyfärg",
titleAdjustMenu1:"Justera bakgrund",
titleProductionId:"Produktions id",
titleCategoryId:"Kategori id",
},
prodarbSum:{
title:"Receptsummering / produktion",
titleMobile:"Summering/Produktion",
leftSelectHeaderLegend:"Receptsummering",
leftSelectFirstOption:"Välj produktion",
middleHeaderLegend:"Recept specifikation",
middleHeaderId:"Id",
middleHeaderIngredient:"Ingrediens",
middleHeaderAmount:"Mängd",
middleHeaderPercentage:"%",
middleheaderWorkWeight:"Arbvikt",
middleHeaderArborder:"Order",
middleDescriptionLegend:"Recept beskrivning",
rightHeaderLegend:"Produkt meny",
rightHeaderProductId:"Id",
rightHeaderProductName:"Namn på produkt",
rightHeaderProductQuantity:"Antal",
titleMidHeaderId:"Ingrediens id",
titleMidHeaderName:"Namn på ingrediens",
titleMidHeaderIngredientAmount:"Ingrediens mängd",
titleMidHeaderPercentageOf:"Procentandel av recept",
titleMiddleHeaderCalculatedIngredientAmount:"kalkylerad receptmängd",
titleMiddleHeaderWorkOrder:"Arborder",
titleAdjustMenu:"Justera menyfärg",
titleAdjustMenu1:"Justera bakgrund",
footerTotalText:"Total",
recipeDescriptionLegend:"Beskrivning"

},
ingredtotNew:{
title:"Ingrediens summering",
titleMobile:"Ingrediens summering",
listHeaderLegend:"Summering av ingredienser för produkter i produktion",
listHeaderId:"Id",
listHeaderName:"Ingrediens",
listHeaderWeight:"Vikt",
listtHeaderAmount:"Summa",
titleHeaderName:"Klicka för att sortera på namn",
titleHeaderWeight:"Klicka för att sortera på vikt",
titleHeaderAmount:"Klicka för att sortera på summa",
rightHeaderLegendTotalAmount:"Total summa",
rightHeaderLegendTotalWeight:"Total vikt",
titleAdjustMenu:"Justera menyfärg",
titleAdjustMenu1:"Justera bakgrund",

},
pricelist:{
title:"Prislistan",
titleMobile:"Prislistan",
mainSelectLegend:"Produkt kategorier",
mainSelectFirstOption:"Välj kategori",
mainHeaderPid:"Pid",
mainHeaderItemNumber:"Artnr",
mainHeaderProductName:"Produkt",
mainHeaderPrice:"Pris",
mainHeaderMargin:"Marginal",
mainHeaderInclusiveVAT:"Ink/moms",
mainHeaderSave:"Spara",
mainHeaderSaved:"Sparad",
titleIncreaseBy10:"Tryck för att öka pris med 10",
titleIncreaseBy1:"Tryck för att öka pris med 1",
titleIncreaseBy01:"Tryck för att öka pris med 0.1",
titleDecreaseBy10:"Tryck för att minska pris med 10",
titleDecreaseBy1:"Tryck för att minska pris med 1",
titleDecreaseBy01:"Tryck för att minska pris med 0.1",
titleName:"Tryck för att sortera på namn",
titlePrice:"Tryck för att sortera på pris",
titleMargin:"Tryck för att sortera på marginal",
rightHeaderLegend:"Genomsnittlig marginal i produktgruppen",
titleAdjustMenu:"Justera meny färg",
titleAdjustMenu1:"Justera bakgrund",
titleMobileMargin:"Marg",
titleMargin1:"Margin",
titleMobileVat:"Ink/m",
titleVat:"Ink/moms",
noNameGiven:"Namn saknas",
},
mysettings:{
  title:"Mina sid inställningar",
  mobiletitle:"Sid inställningar",
  leftHeaderLegend:"Välj sida",
  midBackgroundFilter:"Välj bakgrunds filter för sidan",
  midMenuFilter:"Välj meny filter för sidan",
  midMenuColor:"Välj meny färg för sidan",
  saveButtonText:"Spara",
  rightHeaderlegend:"Välj bild för sidan",
},
headermenubar:{
selectMenu:"Välj meny",
headermenuHome:"Hem",
headermenuLogout:"Logga ut",
headermenuIngrediensList:"Ingrediens lista",
headermenuRecipeList:"Recept lista",
headermenuProductList:"Produkt lista",
headermenuIngredients:"Ingredienser",
headermenuIngredientCategories:"Ingrediens kategorier",
headermenuRecipeCategories:"Recept kategorier",
headermenuProductCategories:"Produkt kategorier",
headermenuMyRecipes:"Mina recept",
headermenuCreateReceipemanually:"Skapa nytt recept manuellt",
headermenuCreateRecipeWithAI:"Skapa nytt recept med AI",
headermenuCreateProduct:"Skapa produkt",
headermenuProductStatistics:"Produkt statistik",
headermenuWorkWithProduction:"Arbeta med produktion",
headermenuTotalNumbers:"Totalt antal produkter i produktion",
headermenuRecipeSummary:"Recept summering",
headermenuIngredientSummary:"Ingrediens summering",
headermenuPriceList:"Prislistan",
headermenuPageSettings:"Sid inställningar",
lists:"Listor",
InfoAboutUs:"Registrera dig",
headermenuproducts:"Produkter",
headermenuproduction:"Produktion",
headermenurecipe:"Recept",
headermenuUserDataPolicy:"Data policy",
headermenuRecipeIdeas:"Recept idéer",
headermenuMyAccount:"Mitt konto",
headermenuRecipeScroll:"Mina recept scroll",
},
loginBox:{
welcomeMessageText:"Välkommen",
emailadressText:"Email adress",
setresetEmailText:"Email för reset",
continuebuttonText:"fortsätt",
noaccountText:"inget konto?",
createaccountText:"Skapa konto",
availableLanguagesText:"Vi finns nu tillgängliga på följande språk",
loginText:"Logga in",
passwordText:"Lösenord",
forgotPasswordText:"Glömt lösenord?",
sendMeResetLinkText:"Skicka återställningslänk",
sendResetButtonText:"Återställ email",
resetinputPlaceholder:"Email adress",
aboutUsText:"Om oss",
contactText:"Kontakt",
privacyPolicyText:"Integritetspolicy",
loginText2:"Logga in",
signUpText2:"Registrera konto",
orText2:"eller", 
returnText:"Tillbaka",
mobileWelcomeMessageText:"Välkommen!",
mobileWelcomeMessageText2:"Kul att du är här!",
},
confirmationMessages:{
  ingredientCreated:"Ingrediens skapad",
  ingredientNameUpdated:"Ingrediens namn uppdaterat",
  ingredientUpdated:"Ingrediens uppdaterad",
  ingredientReplaced:"Ingrediens ersatt",
  priceUpdated:"Pris uppdaterat",
  ingredientDeleted:"Ingrediens borttagen",
  nutrientsUpdated:"Näringsvärden uppdaterade",
  recipeUpdated:"Recept uppdaterat",

  categoryDeleted:"Kategori borttagen",
  categoryDeleteQuestion:"Vill du ta bort kategori:",
  categoryNameWrite:"Skriv in kategorinamn",
  ingredientsMovedTochosenCategory:"Ingredienser flyttade till vald kategori",
  productsMovedTochosenCategory:"Produkter flyttade till vald kategori",
  
  categoryCreated:"Kategori skapad",

  recepyCreated:"Recept är sparat",
  recepyDeleted:"Recept är borttaget",
  yesNoDeleteRecepy:"Vill du ta bort receptet?",
  yesNoDeleteRecepyWithImages:"Vill du ta bort receptet och bilder?",
  btnYes:"Ja",
  btnNo:"Nej",
  imageDeletQuestion:"Vill du ta bort bilden?",
  productDeleted:"Produkt är borttagen",
  productIsSaved:"Produkt är sparad",
  yesNoDeleteProduct:"Vill du ta bort produkten?",
  nameInfo:"Namn kan endast innehålla 45 tecken",

  productionSaved:"Produktionen är sparad",
  productionCreated:"Produktionen är skapad",
  productionCleared:"Produktionen är klarskriven",
  descriptionIsUpdated:"Beskrivning är uppdaterad",
  pageSaved:"Sidan sparad",
  emailSent:"Email skickad",
  userAddressDataUpdated:"Användar data uppdaterad!",
  imageSearchTextToLong:"Max 100 tecken som söktext",
  selectedImageDeleted:"Bilden är borttagen",
  aiimageIsDone:"Bilden är klar",
  aiimageCantBeDone:"Ert konto saldo är för lågt för att generera bilden",
  imageUploadedMessage:"Bilden är uppladdad",
  yesnodeleteIngredient:"Vill du ta bort ingrediens?",
  fetchingDataInformation:"Hämtar data,bygger recept..",
  prescriptionCreatedMessage:"Abonnemang skapat",
  linkNotValid:"Länken är ej giltig eller har gått ut",
  passwordNoMatch:"Lösenorden matchar inte",
  passwordAtleast8:"Lösenordet måste vara minst 8 tecken",
  passwordRestored:"Lösenordet har återställts",
  passwordRestoreFailure:"Lösenordet kunde inte återställas",
  resetLinkSentToEmail:"Återställningslänk skickad med email",
  activateServiceMessage:"Tjänsten är aktiverad",
  deactivateServiceMessage:"Tjänsten är avaktiverad",
  aiImageCantBeUploaded:"Max gräns för uppladdning av bilder uppnådd",
  recipeCanNotBeSaved:"Antal recept har uppnått max gräns",
  recipeIdeaAccountVoid:"Maxgräns för recpet ideer uppnådd",
  aiSubscriptionVoid:"Maxgräns för bild skapande med AI uppnådd",
  categoriesVoid:"Maxgräns för antal kategorier uppnådd",
  ingredientVoid:"Maxgräns för antal ingredienser uppnådd",
  ingredientInfoVoid:"Maxgräns för antal ingrediens information uppnådd",
  productsVoid:"Maxgräns för antal produkter uppnådd",
  productionVoid:"Maxgräns för antal produktioner uppnådd",
  imageuploadVoid:"Maxgräns för antal bilder uppnådd",
  convertVoid:"Maxgräns för antal konverteringar uppnådd",
  supportMessageSendText1:"Meddelande skickat",
  dowloadpdfOkMessage:"Pdf faktura har laddats ner",
  aiRecipeSearchVoid:"Maxgräns för antal recept sökningar uppnådd,aktivera tjänst under Mitt konto",
  passwordsMustBelongText:"Lösenorden måste vara innehålla minst 6 tecken",
  confirmPasswordtext:"match lösenord måste inehålla minst 6 tecken",
  passwordNoMatchText:"Lösenorden matchar inte",
  firstNameRequiredText:"Förnamn krävs",
  lastNameRequiredText:"Efternamn krävs",
  adressRequiredText:"Adress krävs",
  postalCodeRequiredText:"Postnummer krävs",
  cityRequiredText:"Stad krävs",
  regionRequiredText:"Region krävs",
  countryRequiredText:"Land krävs",
  phoneRequiredText:"Telefon krävs",
  emailRequiredText:"Email krävs",
  languageRequiredText:"Språk krävs",
  fullnameRequiredText:"Fullständigt namn krävs",
  currencyRequiredText:"Valuta krävs",
  serviceRequiredText:"Tjänst krävs",
  emailNotValidText:"Email är inte giltig",
  accountCreatedSuccessText:"Konto skapat, gå till betalning steg 2",
  accountCreatedNoSuccessText:"Konto kunde inte skapas",
  accountAlreadyExistText:"Konto finns redan",
  accountCreationFailedText:"Konto kunde inte skapas",
  setupDetailsDeleteFailedText:"Detaljer vid skapandet av konto kunde ej tas bort",

  paypalPaymentConfirmText:"Paypal betalning genomförd, vi har skickat email med bekräftelse",

  loginConfirmToManyUsersText:"Du använder för många enheter,försök igen senare",
  forceLoginText:"Vi har skickat email med kod för inloggning",
  noaccessContactSupportText:"Ingen åtkomst, kontakta support",
  wrongIdOrAccountText:"Ni har angett fel id eller konto",
  notAuthorizedText:"Ni är ej autentiserad att använda denna applikation, kontakta support",
  klarnaPaymentSuccessText:"Kontot är klar för användning",
  klarnaPaymentfalureText:"Klarna betalning ej genomförd",
  finalPaymentMessage:"Tack för er betalning!",
  finalPaymentMessageLink:"Logga in här",
  useNoAccessText:"Ni har ingen åtkomst till denna tjänst",
  signUptemporaryClosedText:"Registrering är tillfälligt stängd",
  clientSideUpdateSupportTicketText:"Support ticket uppdaterad",
  noHelpInformationText:"Ingen hjälp information tillgänglig",
  FetchingHelpInstructionsText:"Hämtar hjälp instruktioner",
  notValidFacebookTokenText:"Ni finns redan eller kan inte logga in med facebook, kontakta support",
  accountCantBeCreatedText:"Konto kan inte skapas med denna användare! Kontakta support.",
  noinvoicesFoundText:"Inga fakturor hittades",
  signupButtontext:"Registrera här",
  priceCantBeSetText:"Pris måste vara emellan 1-5",
},
recipeideas:{
  recipesearchFieldset:"Sök recept idéer",
  recipesearchPlaceholder:"tex, pasta med köttfärssås",
  recipesearchButton:"Sök",
  recipesearchConfirmation:"Vi har hittat",
  recipesearchConfirmationNum:"st",
  recipesearchText:"recept",
  receipesearchHeaderId:"Id",
  receipesearchHeaderName:"Namn",
  recipeTitle:"Recept idéer",
  titleAdjustMenu:"Justera menyfärg",
titleAdjustMenu1:"Justera bakgrund",
},
MyAccount:{
title:"Mitt konto",
userFirstName:"Namn",
userFamilyName:"Efternamn",
userCompany:"Företag",
userAdress:"Adress",
userPostalCode:"Postnr",
userCity:"Stad",
userRegion:"region",
userCountry:"Land",
userPhone:"Telefon",
userEmail:"Epost",
userSaveButtonText1:"Spara",

userallServices:"Alla tjänster",
userDebitServices:"Debitering av tjänster",
userService:"Tjänst",
userPrice:"Pris",
userActivate:"Aktivera",
userStat:"Status",
numPageRequestsPerPage:"Antal sid visningar per sida i perioden",
userPagenr:"Sid nr",
userPageComponent:"Sida/komponent",
userPageNum:"Antal",
userMyData:"Min data",
userMyDataDescriptionText:"Detta är information om den data ni har i vårt system, vid kontots skapande erhåller ni ett bas paket med data, denna information visar den total mängd data ni har på ert konto hos oss.",

userDataId:"Id",
userDataName:"Namn",
userDataAntal:"Antal",
userDataIngredientCategories:"Ingrediens kategorier",
userDataRecipeCategories:"Recept kategorier",
userDataProductCategories:"Produkt kategorier",
userDataIngredient:"Ingredienser",
userDataRecipes:"Recept",
userDataProducts:"Produkt",
userDataProductions:"Produktioner",
userDataRecipeImages:"Recept bilder",
userDataProductImages:"Produkt bilder",
userDataInstructionText:"Om ni vill ha er egen data skickad till er på email klicka på knappen nedan. Gäller ej bilder.",


useDataMyDataButtonText:"Min data",
paymentPayPal:"Paypal",
paymentSwish:"Swish",
paymentKlarna:"Klarna",
paymentCreditCard:"Kort",
paymentInvoice:"Faktura",
accountOverview:"Översikt",
userAccount:"Konto",
accountTransactionsFor:"Konto händelser för",
accountService:"Service",
accountInvoice:"Faktura",
accountDebit:"Pris",
accountKredit:"Kredit",
accountDate:"Datum",
accountSystemComment:"Kommentar",
accountTotalDebit:"Total Debit",
accountTotalKredit:"Total Kredit",
accountTotalSaldo:"Saldo",
invoice:"Faktura",
invoiceInfo:"Faktura information",
estimatedSaldo:"Beräknat saldo inkl moms(25%)",
estimatedSaldoText:"Detta är det belopp du betalar baserat på din nuvarande prenumeration.",
nextPaymenttext:"Nästa betalnings tillfälle",
prePaymentText:"Förskottsbetalning",
totalAmountUsed:"Totalt använt",

    // invoice history
    invoiceHistoryText:"Faktura historik",
    invoiceHistoryInvoice:"Faktura",
    invoiceHistorySum:"Summa",
    invoiceHistoryDate:"Datum",
    invoiceHistoryStatus:"Status",

    //invoicerows
    chooseInvoiceText:"Välj faktura",
    invoiceRowsText:"Faktura rader för faktura",
    allInvoiceRowsText:"Samliga faktura rader",
    invoiceRowsId:"Faktura id",
    invoiceRowsService:"Tjänst",
    invoiceRowsMax:"Max",
    invoiceRowsRequest:"Antal",
    invoiceRowsDebitQty:"Debit",
    invoiceRowsPrice:"Pris",
    invoiceRowsTotal:"Summa",
    invoiceRowsType:"Typ",
    invoiceRowsTotalSum:"Totalt",

    //password
    passwordValidText:"Giltigt lösenord",
    newPasswordText:"Nytt lösenord",
    confirmPasswordText:"Bekräfta lösenord",
    showPasswordText:"Visa lösenord",
    savePasswordButtonText:"Spara",
    iforgotMyPasswordText:"Jag har glömt mitt lösenord och vill återställa det",

    //support
    supportMText:"Bakesoft support",
    supportMessageText:"Om du har några frågor eller behöver hjälp, vänligen kontakta oss via formuläret nedan.",
    supportMessageSelectText:"Välj ämne",
    supportMessageSendText:"Skicka",
    messagesSentToSupportText:"Meddelande skickat till support",


    // settings
settingsHeaderText:"Inställningar",
companyAndPersonalText:"Företags- och personuppgifter",
addressInstructionText:"This address is used for invoices and other information and shall be a real address to youre home or company.",
addressInstructionText:"Denna adress används för fakturor och annan information och ska vara en riktig adress till ditt hem eller företag.",
taxIdentificationVATText:"Momsidentifikationsnummer (VAT)",
taxIdInformationText:"Registered companies outside of EU are able to register their tax identification number to remove VAT from the invoice.",
taxIdInformationText:"Registrerade företag utanför EU kan registrera sitt momsidentifikationsnummer för att ta bort moms från fakturan.",
registerTaxIdButtonText:"Registrera momsidentifikationsnummer",
maxValueWarningText:"Aktivera och ställ in varningsgräns för månatliga fakturor. Ett e-postmeddelande skickas när gränsen nås.",
actiVateMaxValueText:"Aktivera varningsgräns",
maxValueButtonSaveText:"Spara",

// buttontext
addressInfoButtontext:"Adressinfo",
servicesButtontext:"Tjänster Shop",
dataUsageButtontext:"Data användning",
mydataButtontext:"Min data",
paymentButtontext:"Betalning",
myAccountButtonText:"Mitt konto",
myInvoiceButtonText:"Fakturor & kvitton",
passwordButtontext:"Lösenord",
supportButtontext:"Support",
settingsButtontext:"Inställningar",
servicesButtonUsagetext:"Tjänst debitering",

//invoices
websiteCompanyName:"Bakesoft.se",
invoiceText:"Faktura",
broughtToYouText:"Brought to you by Hellström Trading i Göteborg AB",
customerIdText:"Kundnr:",
serviceText:"Tjänst",
nameText:"Namn",
numText:"Antal",
maxText:"Max",
debitText:"Pris",
kreditText:"Kredit",
totaltText:"Totalt",
sumNetText:"Summa netto:",
sumVatText:"Moms:",
attBetalaText:"ATT BETALA",
bankgiroText:"Bankgiro:",
swishText:"Swish:",
termsText:"Villkor:",
bankText:"Bank:",
bicText:"BIC:",
ibanText:"IBAN:",

serviceHeaderText:"Tjänster",
servicePrisHeaderText:"Pris",
serviceActivateHeaderText:"Aktivera",
serviceStatusHeaderText:"Status",

// tjansterdebitering
debserviceidText:"ID",
debserviceBenaemningText:"Namn",
debserviceQtyText:"Antal",
debserviceMaxText:"Max",
debserviceUsableText:"Kvar",
debserviceStatusText:"Status",

// support history
supportHistoryServiceIdText:"Id",
supportHistoryDateText:"Datum",
supportHistoryStatusText:"Status",
supportHistoryCategoryText:"Ämne",
supportRequestText:"Support ärenden",

//password change
passwordHeaderText:"Byte av lösenord",
resetPasswordHeaderText:"Återeställ lösenord",

overviewText:"Översikt",
overviewInformationText:"Information",
overviewInvoiceText:"Faktura",

//invoice
invoiceSelectFirstOption:"Välj faktura",
printInvoceButtonText:"Skriv ut faktura",

myinformationText:"Min information",
supportButtonAnswerText:"Skicka",
supportTitleText:"Ämne",
supportHistoryBenaemnText:"Ämne",
paymentStandard:"Betalning",
paymentIntentErrorText:"Kan inte förbereda betalning,kontakta support",
thankYouPurchaseText:"Tack för ditt köp!",
serviceActivatedText:"Tjänsten är aktiverad.",
paymentFailedMessageText:"Betalningen kunde inte genomföras!",
paymentFailedMessageText2:"Försök igen eller kontakta support.",
isPayedText:"Betald",
isNotPayedText:"Betala",
choseImageButtontext:".Välj bild",
choseImageUploadText:"Ladda upp bild",

liveAccountSetupText:"Inställning av Live konto",
liveCreatorImageText:"Live skapare bild uppladning",
liveCreatorPublicImageText:"Live skapare publik bild(5:7)",
liveCreatorProfileText:"Live skapare profil text",
liveMax500text:"max:500",
saveCreatorText:"Spara text",
liveAccountText:"Live konto",



},
logout:{
  logoutMessage:"Du är utloggad!",
  loginMessage:"Logga in för att fortsätta"
},
aialert:{
  aialertTitle:"Information!",
  aialertMessage:"Vi kunde inte sammanställa recept ifrån data.",
  aialertMessage2:"Försök upp till 3 ggr, därefter ändra text.",
  aialertButtonText:"Stäng",
},
resetPass:{
headerText:"Återställ lösenord",
resetPassInputPlaceHolder:"Nytt lösenord",
resetPassInputConfirmPlaceHolder:"Bekräfta nytt lösenord",
resetPassButtonText:"Återställ lösenord",
},
scrollRecipe:{
  mobileTitle:"Mina recept bilder",
  title:"Mina recept bilder",
  titleJusteraBakgrund:"Justera bakgrund",
  scrollIngredientButtontext:"Ingredienser",
  scrollDescriptionButtontext:"Beskrivning",
  scrollBenaemnText:"Benämning",
  scrollMangdText:"Mängd",
  scrollSummaText:"Summa",
  scrollKcalText:"Kcal",
  scrollArborderText:"Arborder",
  scrollDescriptionHeaderText:"Beskrivning",
  serviceActivatedText:"Tjänsten är aktiverad",
  thankYouPurchaseText:"Tack för ditt köp!",
},
info:{
  startButtonText:"Hem",
  signupButtonText:"Skapa konto",
  signupButtonPrivateText:"Skapa konto",
  signupButtonCompanyText:"Skapa konto - företag",
  applicationButtonText:"Bas tjänster",
  applicationPlusServicesText:"Plus tjänster",
  aiServicesButtonText:"AI tjänster",
  aboutUsButtonText:"Om oss",
  faqButtonText:"Frågor&Svar",
  basicButtonText:"Bas",
  totalButtonText:"Total",
  basicCorporate:"Bas företag",
  totalCorporate:"Total företag",
  basicStoryButton:"Bas, vi berättar för er",
  basicfunctionalityButtonText:"Funktioner",
  pleasefillupFormText:"Fyll i formuläret nedan för att skapa ett konto",
  aftersignupemailwillbesent:"När vi skapat ert konto skickas ett email med faktura.",
  accountsetupin24hrs:"Vid betalning i appen är ert konto klart inom 5 minuter",
 accountsetupSlow:"Vi annan betalningsmetod är ert konto klart inom 24 timmar",
  signUpHeaderText:"Skapa konto",
 firstNameText:"Förnamn",
 lastNameText:"Efternamn",
 addressText:"Adress",
 postalCodeText:"Postnummer",
 cityText:"Stad",
 regionText:"Region",
 countryText:"Land",
 emailText:"Email",
 phoneText:"Telefon",
 passwordText:"Lösenord",
 confirmPasswordText:"Bekräfta lösenord",
 selectLanguageText:"Välj app språk",
 selectSwedLangText:"Svenska",
  selectEngLangText:"Engelska",
  selectJapLangText:"Japanska",
  selectCurrencyText:"Välj valuta",
  selectSekCurrencyText:"SEK",
  selectUsdCurrencyText:"USD",
  selectJapCurrencyText:"JPY",
  selectServiceText:"Välj tjänst",
  selectBasicServiceText:"Bas",
  selectTotalServiceText:"Total",
  selectBasicCorporateServiceText:"Bas företag",
  selectTotalCorporateServiceText:"Total företag",
  allfieldsMandatoryText:"Alla fält är obligatoriska",
  signUpButtonText:"Skapa konto",

  selectPlanIntentTypeText:"Välj period",
  selectMonthlyPlanText:"Månad",
  selectTrippleMonthPlanText:"Trippel",
  selectYearlyPlanText:"Helår",
  discountText:"Sänkt pris",

  applicationBaseServicesHeader:"Applikationens bas tjänster",
  applicationBaseVersions:"Applikationen har 4 olika bas tjänster",
  applicationBaseVersions1:"Bas",
  applicationBaseVersions2:"Total",
  applicationBaseVersions3:"Bas företag",
  applicationBaseVersions4:"Total företag",
  applicationCanusePartly:"Samtliga delar utom produkter och produktion kan användas",
  applicationCanBeUsed:"Samtliga delar kan användas",
  plusServicesHeaderText:"Följande plus tjänster finns att tillgå för att",
  plusServicesHeaderText1:"storleksmässigt kunna växa i applikationen.",
  plusServiceName:"Namn",
  plusServicePrice:"Pris",
  plusServiceBenaemning:"Benämning",
  pricesGivenPerMonthText:"Priser angivna gäller per månad",
  faqHeaderText:"Frågor och svar",
  totalWeTelltext:"Total, vi berättar för er",
  totalFunctionalitytext:"Funktioner",
  totalCorporateWeTellText:"Total företag, vi berättar",
  totalCorporateFunctionalityText:"Funktioner",
  basicCorporateWeTell:"Bas företag, vi berättar",
  basicCorporateFunctionality:"Funktioner",

  // paypal payment
  paypalMainHeaderText:"Bakesoft Betalnings tjänst (Paypal)",
  klarnaMainHeaderText:"Bakesoft Betalnings tjänst (Klarna)",
  pptotalAmountToPayText:"Totalt att betala",
  ppChosenServiceText:"Vald tjänst",
  ppChosenCurrencyText:"Vald valuta",
  registerAccountTabText:"Registrera konto , Steg 1",
  paymentTabText:"Betalning, Steg 2",
  ppBakesoftTermsText:"Villkor",
  ppBakesoftTerms:"Avsluta abonnemang när som helst",
  ppSelectInvoiceText:"Välj faktura",
  ppSelectCurrencyText:"Välj valuta",
  contactInfoText:"Kontakt information",
  privacyPolicyText:"Integritetspolicy",
  klickOnTextMoreInfo:"Klicka på tjänst för mer info!",
  integrityPolicyText:"Integritetspolicy",
  contactInformationText:"Kontakt information",
  buyingTermsText:"Köpvillkor",
  signupCompanyButtonText:"Skapa konto - företag",


 totalHeaderText:"Total företag",
 totalBasIncludedText:"Allt i Grund Företag ingår",
 totalUppTo800RecipeText:"800 recept",
 totalProductsMax500Text:"500 produkter",
 totalProductionText:"500 produktioner",
 totalAutomaticRecipeSummaryText:"Automatisk recept summering",
 totalAutomaticIngredientSummaryText:"Automatisk ingrediens summering",
 totalAutomaticProductionStatisticText:"Automatisk produktion statistik",
 totalCreatePricelistText:"Skapa prislista med marginaler",
 total200ProductCategoriesText:"200 produkt kategorier",

basCorpHeaderText:"Bas företag",
basCorporateSave500RecipeText:"Spara 500 recept",
basCorpUse500IngredientsText:"Använd 500 ingredienser",
basCorpCreate200IngredientCategoriesText:"Skapa 200 ingrediens kategorier",
basCorpCreateRecipeWithAiText:"Skapa recept med AI",
basCorpCreateRecipeManuallyWithIngredientsText:"Skapa recept manuellt med ingredienser",
basCorpSearchRecipeIdeaText:"Sök recept idéer",
basCorpCreateImageWithAiText:"Skapa bild med AI",
basCorpUploadOwnImageText:"Ladda upp egna bilder",
basCorpSave1000ImagesText:"Spara 1000 bilder",
basCorpCreate200RecipeCategoriesText:"Skapa 200 recept kategorier",
basCorpAutomaticNutrientCalculationText:"Automatisk näringsberäkning",
basCorpAutomaticCostCalculationText:"Automatisk kostnadsberäkning",
basCorpAutomaticMatchIngredientsText:"Automatisk matchning av ingredienser",
basCorpAutomaticGetNutrientsText:"Automatisk hämtning av näringsvärden",
basCorpInformationsAboutIngredientsText:"Information om ingredienser",
basCorpConvertVolumeToGramText:"Konvertera volym till gram",
basCorpManageIngredientRecipeLinksText:"Hantera ingrediens-recept kopplingar",
basCorpMoveManageLinksAutomaticallyText:"Flytta kopplingar automatiskt",
basCorpManageIngredientRecipeLinksText:"Hantera ingrediens-recept kopplingar",
basCorpIngredientPriceText:"Hantera ingrediens priser",
basCorpAutomaticPriceDiagramText:"Automatisk prisdiagram",
basCorpHumoristicCommentsText:"Humoristiska kommentarer från AI",



basPrivateSave300RecipeText:"Spara 300 recept",
basPrivateUse500IngredientsText:"Använd 500 ingredienser",
basPrivateCreate100IngredientCategoriesText:"Skapa 100 ingrediens kategorier",
basPrivateSearchCreateRecipeAiText:"Sök och skapa recept med AI",
basPrivateCreateRecipeManuallyWithIngredientsText:"Skapa recept manuellt",
basPrivateSearchRecipeIdeaText:"Sök recept idéer",
basPrivateCreateImageWithAiText:"Skapa bild med AI",
basPrivateUploadOwnImageText:"Ladda upp egna bilder",
basPrivateSave400ImagesText:"Spara 400 bilder",
basPrivateCreate100RecipeCategoriesText:"Skapa 100 recept kategorier",
basPrivateAutomaticNutrientCalculationText:"Automatisk näringsberäkning",
basPrivateAutomaticCostCalculationText:"Automatisk kostnadsberäkning",
basPrivateAutomaticMatchIngredientsText:"Automatisk matchning av ingredienser",
basPrivateAutomaticGetNutrientsText:"Automatisk hämtning av näringsvärden",
basPrivateInformationsAboutIngredientsText:"Information om ingredienser",
basPrivateConvertVolumeToGramText:"Konvertera volym till gram",
basPrivateManageIngredientRecipeLinksText:"Hantera ingrediens-recept kopplingar",
basPrivateMoveManageLinksAutomaticallyText:"Flytta kopplingar automatiskt",
basPrivateIngredientPriceText:"Hantera ingrediens priser",
basPrivateAutomaticPriceDiagramText:"Automatisk prisdiagram",
basPrivateHumoristicCommentsText:"Humoristiska kommentarer från AI",

totalPrivateBaseIncludedText:"Allt i Grund plan ingår",
totalUppTo400RecipeText:"400 recept",
totalProductsMax300Text:"300 produkter",
  totalProductionText2:"250 produktioner",
totalAutomaticRecipeSummaryText2:"Automatisk recept summering",
totalAutomaticIngredientSummaryText2:"Automatisk ingrediens summering",
totalAutomaticProductionStatisticText2:"Automatisk produktion statistik",
createProductionMax250Text:"Skapa produktioner max 250",
createPricelistWithMarginText:"Skapa prislista med marginaler",
createUpTo100ProductCategoriesText:"Skapa upp till 100 produkt kategorier",

signUpSubscriptionHeaderText:"Välj din plan",
basicPlanText:"Grund plan",
basServiceIdText:"Tjänst ID:127",
basFeaturesText:"Viktiga funktioner ,privata användare",
totalPlanText:"Premium plan",
totalServiceIdText:"Tjänst ID:128",
totalFeaturesText:"Utökade funktioner, privat användare",

basicCorpPlanText:"Grund Företag plan",
basCorpServiceIdText:"Tjänst ID:133",
basCorpFeaturesText:"Viktiga funktioner, företag",
totalCorpPlanText:"Premium Företag plan",
totalCorpServiceIdText:"Tjänst ID:134",
totalCorpFeaturesText:"Utökade funktioner, företag",

basPrivateHeaderText:"Grund",
totalPrivateHeaderText:"Premium",
basCorporateheaderText:"Grund Företag",
totalCorporateheaderText:"Premium Företag",

choseCountryText:"Välj land",
prepayLanguageHeader:"Språk",
prepayCountryHeader:"Land",
prepayCurrencyHeader:"Valuta",
prepayPeriodHeader:"Period",

paymentDetailsHeaderText:"Betalningsdetaljer",
paymentDetailsCurrencyText:"Valuta",
paymentPlanText:"Betalningsplan",
discountText:"Rabatt",
paymentTotalToPayText:"Totalt att betala",

orderOverviewText:"Orderöversikt",
plandetailText:"Plandetaljer",

fullNameLabelText:"Fullständigt namn",
emailLabelText:"Email",
passwordLabelText:"Lösenord",
confirmPasswordLabelText:"Bekräfta lösenord",
continueLabelText:"Fortsätt",
welcomeLabelText:"Välkommen",
signUpContinueLabelText:"Registrera dig eller fortsätt med",
orLabelText:"eller",

// success payment stripe
// callback messages
successPaymentHeaderText:"Betalning genomförd",
successPaymentText:"Tack för din betalning, din betalning har genomförts.",
successPaymentIdText:"Betalnings ID",
successPaymentAmountText:"Betalt belopp",
successPaymentServiceText:"Tjänst",


paymentFailedHeaderText:"Betalning misslyckades",
paymentFailedText:"Betalningen kunde inte genomföras,försök igen eller kontakta support.",


accountSetupServiceText:"Service",
accountSetupLanguageText:"Språk",
accountSetupCurrencyText:"Valuta",
accountSetupPeriodText:"Period",
accountSetupCountryText:"Land",
accountSetupTotalAmountText:"Att betala",
accountSetupHeaderText:"Skapa konto information",
accountSetupCancelText:"Säg upp när som helst",
accountSetupTermsText:"Villkor",  
confirmPayButtonText:"Betala",
gotoLoginMessageText:"Gå till login",

paymentSucceededText:"Betalning genomförd",
paymentProcessingText:"Betalning pågår",
paymentFailedTextInfo:"Betalning misslyckades,försök igen",
paymentSomethingWentWrongText:"Något gick fel, försök igen",
paymentSucceededEmailText:"Vi har skickat ett email med bekräftelse",
paymentFailedMessageText:"Betalningen kunde inte genomföras",
paymentFailedMessageText2:"försök igen eller kontakta support",

continueWithFacebookText:"Fortsätt med Facebook",
continueWithGoogleText:"Fortsätt med Google",
continueWithLineText:"Fortsätt med Line",
basPrivateIncludedText:"Allt i Entry plan ingår",
planFeaturesText:"Plan funktioner",
passwordText:"Lösenord",
passwordConfirmText:"Bekräfta lösenord",
termsText:"Jag godkänner användarvillkor och sekretesspolicyn.",

},
welcomeImage:{
  importantNoticeText:"Viktig information",
  invoiceDueText:"Följande faktura för prenumeration är förfallen",
  invoiceText:"Faktura",
  serviceIdText:"Tjänst ID",
  dueDateText:"Förfallodatum",  
  amountText:"Belopp",
  settlePaymentHereText:"Betala här under fliken mitt konto",
  gotoMyAccountText:"Gå till mitt konto",
  ifnopaymentText:"Om ingen betalning sker kommer konto att stängas.",
  contactSupportText:"Kontakta support i support panelen eller via email:",
  supportLinkText:"Support",
  serviceDueText:"Följande tjänst är förfallen!",
  deleteText:"Ta bort",
  payText:"Betala",
  subscriptionInRedText:"Prenumeration i rött måste betalas!",
},
liveArea:{
  liveAreaText:"Live",
  unpublishButtonText:"Avpublicera",
  liveAreaText:"Live",
  unpublishButtonText:"Avpublicera",
  publishThisRecipeText:"Publicera detta recept på Live",
  recipeIsPublishedText:"RECEPTET ÄR PUBLICERAT",
  creatorStatusText:"Skaparens status",
  recipeIdText:"Receptid",
  liveImageText:"Bild kontroll",
  liveInstructionText:"(Kopiera recept, skapa nytt för att dela)",
  recipeToPublishText:"Recept att publicera",
  liveTimeText:"Tid",
  liveDifficultyText:"Svårighetsgrad",
  liveSalesPriceText:"Försäljningspris",
  userAccessText:"Användare som har tillgång",
},





// \u0020




      // Add more pages as needed
    },
    ja: {
      common: {
        // greeting: "Hola",
        // farewell: "Adiós"
      },
      ingredienslista:{
        title: "食材リスト-マイ食材",
        titleMobile: "食材リスト-マイ食材",
        kategoriLegend: "カテゴリを選ぶ",
        selectFirstOption: "カテゴリを選ぶ",
        headerId: "ID",
        headerNamn: "食材名",
        headerAktiv: "表示",
        headerDela: "公開",
        headerSpara: "保存",
        ListSaveButton: "保存",
        SavedOnButton:"保存済",
        titleAdjustMenu:"メニューの色を調整",
        titleAdjustMenu1:"背景を調整",
      },
      receptlista: {
        title:"レシピのリスト",
        titleMobile: "レシピのリスト",
        kategoriLegend: "カテゴリを選ぶ",
        selectFirstOption: "カテゴリを選ぶ",
        headerId: "ID",
        headerNamn: "食材名",
        headerAktiv: "表示",
        headerDela: "公開",
        headerSpara: "保存",
        ListSaveButton: "保存",
        SavedOnButton:"保存済",
        titleAdjustMenu:"メニューの色を調整",
        titleAdjustMenu1:"背景を調整",
    
      },
      produktlista: {
        title:"製品のリスト-あなたの製品",
        titleMobile:"製品のリスト",
        kategoriLegend: "カテゴリを選ぶ",
        selectFirstOption: "カテゴリを選ぶ",
        headerId: "ID",
        headerNamn: "食材名",
        headerAktiv: "表示",
        headerDela: "公開",
        headerSpara: "保存",
        ListSaveButton: "保存",
        SavedOnButton:"保存済",
        titleAdjustMenu:"メニューの色を調整",
        titleAdjustMenu1:"背景を調整",
    
      },
      ingredienser:{
        title:"食材リスト -マイ食材",
        titleMobile:"食材リスト",
        ingredbutton:"食材リスト",
        ingredcreatebutton:"食材を設定",
        receptkoppbutton:"関連するレシピ",
        produktkoppbutton:"関連する製品",
        inkopbutton:"価格",
        prisdiabutton:"価格チャート",
        nutrientbutton:"成分",
        konvertbutton:"単位変換",
        ingredinfobutton:"食材情報",
        chooseingredcategoryFieldset:"食材カテゴリを選ぶ",
        ingredcategoryFirstOption1:"カテゴリを選ぶ",
        changeingredNameFieldset:"食材名を変更",
        changeingredNameSaveButton:"保存",
        nutrientHeaderHas:"を使うのは",
        nutrientHeaderReceptKopp:"の関連するレシピ",
        nutrientHeaderAnd:"及び",
        nutrientHeaderProdkopp:"の関連する製品",
        nutrientHeaderSmall:"この食材には関連するレシピが存在するため、削除できません",
        nutrientDeleteInfoButton:"食材を削除する",
        nutrientlistTopSubject:"100g当たりの成分",
        nutrientlistTopAmount:"量",
        nutrientListTopWeight:"単位",
        createingredientNameLegend:"食材名",
        createingredientSaveButton:"食材を保存",
        produktkoppHeaderLegendText:"関連する製品がありません",
        produktkoppInputHeaderTextLegend:"置き換え",
        produktkoppInputHeaderTextLegend2:"を次の食材と",
        produktkoppMessageTextLegend:"置き換えるレシピ",
        produktkoppSaveButtonText:"置き換えて保存",
        inkopsprisChangePriceLegend:"価格を更新",
        inkopsprisLatestPriceLegend:"最新のkg当たり価格:",
        inkopsprisListHeaderLegend:"購入データ",
        prisdiagramChoseChartLegend:"チャート選択",
        nutrientHeaderLegend:"栄養素",
        nutrientFetchDataButtonText:"成分を表示",
        nutrientSaveDataButtonText:"保存",
        nutrientListHeaderTextSubject:"100g当たりの成分",
        nutrientListHeaderTextAmount:"量",
        nutrientListHeaderTextWeight:"単位",
        konvertHeaderTextLegend:"単位変換",
        konvertHeaderTextLegend2:"グラムへ",
        konvertSaveButtonText:"単位変換",
        ingredInfoHeaderTextlegend:"食材情報",
        ingredInfoGetInfoButtonText:"情報をダウンロード",
        ingredInfoGetNewInfoText:"新たに情報をダウンロード",
        ingredcanberemovedText:"食材を削除できます",
        recepyConnectionHeader:"関連するレシピ",
        recepySelectHeaderLegend:"置き換え",
        recepySelectHeaderLegend2:"というレシピを",
        recepySelectedStatementLegend:"以下と置き換える",
        recepyConnectionSaveButtonText:"置き換えて保存",
        produktkoppHeaderTextLegend2:"関連する製品",
        inkopPriceHeaderBenaemn:"購入した日",
        inkopPriceHeaderPrice:"価格",
        inkopChangePriceSaveButton:"保存",
        prisdiagramHeaderLegend:"この食材の価格変動",
        konvertDisclaimerText:"ATTENTION! 単位の変換は、インターネットや提供者から得た情報を参考にしており、あくまで目安です。わたしたちは、これらのデータを使用可能であると判断してはいますが、もっと正確な数値が必要な場合には、より多くの情報を収集してください。",
        konvertGramText:"グラム",
        measureTableSpoon:"大さじ",
        measureTeaSpoon:"小さじ",
        measureDeciliter:"デシリットル",
        measureMilliliter:"ミリリットル",
        measurePinch:"少々",
        ingredSelect:"食材を選ぶ",
        measureSelect:"単位を選ぶ",
        
    },
    ingredcategories:{
        title:"食材カテゴリ",
        titleMobile:"食材カテゴリ",
        categoryHeaderKategori:"カテゴリ",
        categoryHeaderAntal:"数量",
        categoryMoveIngredLegend:"食材を移動",
        categorySelectFirstOption:"カテゴリを選ぶ",
        categoryButtonMoveSubmit:"移動",
        categoryCreateCategorySubmit:"カテゴリを作成",
        furiganaLabel:"カテゴリを作成",
        furiganaHolder:"ふりがな",
        kanjiHolder:"カテゴリ名"
    },
    recepycategories:{
        title:"レシピのカテゴリー",
        titleMobile:"レシピのカテゴリー",
        categoryHeaderKategori:"カテゴリー",
        categoryHeaderAntal:"量",
        categoryMoveIngredLegend:"食材を移動",
        categorySelectFirstOption:"カテゴリーを選択",
        categoryButtonMoveSubmit:"移動",
        categoryCreateCategorySubmit:"カテゴリーを作る",
    },
    productcategories:{
        title:"製品のカテゴリー",
        titleMobile:"製品のカテゴリー",
        categoryHeaderKategori:"カテゴリー",
        categoryHeaderAntal:"量",
        categoryMoveIngredLegend:"食材を移動",
        categorySelectFirstOption:"カテゴリーを選択",
        categoryButtonMoveSubmit:"移動",
        categoryCreateCategorySubmit:"カテゴリーを作る"
        
    },
    myrecipes:{
title:"マイレシピ",
titleMobile:"マイレシピ",
leftSelectLegendMinaRecept:"レシピのカテゴリー",
leftSelectFirstOption:"カテゴリ選択",
aiHeader:"AIのコメント",
listHeaderId:"ID",
listHeaderIngrediens:"食材",
listHeaderMangd:"量",
listHeaderKostnad:"価格",
listHeaderKal:"熱量",
listHeaderOrder:"表示順",
ingredCommentBoxInfo:"食材へのコメント",
ingredInfoBoxPlus:"プラス",
ingredInfoBoxMinus:"マイナス",
getNutrientInfoButton:"栄養価を見る",
antalTeckenInfoText:" 文字数",
addIngredientSelectLegend:"食材を選ぶ",
addIngredientSelectFirstOption:"食材を選ぶ",
numPortionSelectLegend:"人数分",
numPortionsSelectlegendMobile:"人数分",
portionSizeInputLegend:"一人当たりグラム",
portionSizeInputLegendMobile:"大きさ/量",
saveRecepyButtonText:"レシピ保存",
saveRecepyButtonTextMobile:"保存",
sizeRecepyDoublerLegend:"レシピの分量",
nutrientEnergyLegend:"栄養価",
nutrientKolhydraterLegend:"炭水化物",
nutrientProteinLegend:"蛋白質",
nutrientFettLegend:"脂質",
rightHeaderBeskrivning:"詳細",
rightBottomAntalTecken:"文字数",
titleJusteraBakgrund:"背景を調整",
titleTaBortDettaRecept:"このレシピを削除",
titleDatabasensIngrediensId:"食材IDをデータに保存",
titleKlickaforattSortera:"クリックして選択",
titleMangdIgramPerIngrediens:"一単位当たりのグラム数",
titleKostnadPerIngrediens:"食材毎の価格",
titleKalorier:"熱量",
titleKlickaForMerInformation:"クリックして更に情報を取得",
titleTaBortRecept:"レシピを削除",
titleAntalKalorierPerGramKalorierPerPortion:"一人分のグラム数及び熱量",
titleAndraStorlekPaRecept:"レシピの分量を変更",
titleSummaEnergiIRecept:"レシピ全体の栄養価",
titleTotalMangdKolhydraterIRecept:"レシピ全体の炭水化物",
titleTotalMangdProteinIRecept:"レシピ全体の蛋白質",
titleTotalMangdFettIRecept:"レシピ全体の脂質",
titleHamtaNaringsvarde:"栄養価を取得",
titleMinskaMangd:"量を減らす",
titleOkaMangd:"量を増やす",
titleHamtaNaringsvarde:"栄養価を取得",
textAreaKomment:"食材にコメントする",
nutrientListHeaderSubject:"100グラム当たりの栄養価",
nutrientListHeaderAmount:"量",
nutrientListHeaderValue:"数値",
hideNutrientValues:"栄養価を非表示",
titleDeleteFromRecipe:"レシピから削除",
footerTotalt:"合計",
titleTotalSummary:"グラム当たり/一人分当たりの熱量",
portionInputText:"グラムで表示",
titleDoublerLegend:"レシピの分量を変更",
searchLegend:"レシピを検索",
searchPlaceholdertext:"レシピ名",
searchButton:"検索",
imageIconText:"画像",
recipeIconText:"レシピ",
uploadIconText:"アップロード",
downloadIconText:"AI画像",
descriptionIconText:"説明",
deleteImageTitle:"画像を削除",
choseImageButtontext:"画像を選ぶ",
choseImageUploadText:"画像をアップロード",
recepyMustBeChosenBeforeUploadMessage:"レシピを選択してから画像をアップロードしてください!",
createImageTextPlaceholder:"画像の説明",
createImageButtonText:"画像を追加",
generateAIimageDisclaimer:"AIによる画像生成は、説明に含まれていません。このサービスには常に追加料金がかかります。料金はアカウントの価格リストに記載されています。",
publishSalesPriceText:"価格を表示",
publishDifficultyText:"難易度",
publishDifficultyOption1:"簡単",
publishDifficultyOption2:"普通",
publishDifficultyOption3:"難しい",
publishButtonText:"公開",
inputPriceText:"価格",
inputMinuteText:"分",
publishTimeText:"時間",




    },
    mancreaterecipe:{
      title:"自分でレシピを作成する",
      titleMobile:"自分でレシピ作成",
      leftFirstSelectLegend:"食材を開始",
      leftSecondSelectLegend:"レシピを開始",
      leftSelectFirstOption:"カテゴリーを選ぶ",
      recepyTitlePlaceHolder:"レシピ名",
      menuHeaderId:"ID",
      menuHeaderIngrediens:"食材",
      menuHeaderMangd:"量",
      menuHeaderKostnad:"価格",
      menuHeaderKalorier:"熱量",
      menuHeaderOrder:"並替",
      titleMenuHeaderId:"食材のデータ番号",
      titleMenuHeaderIngrediens:"食材、クリックして選択",
      titleMenuHeaderMangd:"食材毎のグラム数",
      titleMenuHeaderKostnad:"食材毎の価格",
      titleMenuHeaderKalorier:"熱量、クリックして選択",
      titleMenuHeaderOrder:"クリックして選択",
      titleDeleteIngredient:"クリックしてレシピから食材を削除",
      ingredientBoxCommentTitle:"食材へコメント",
      ingredientBoxPlusButtonText:"+",
      titleIngredientBoxPlusButton:"1から10倍までの増量 (ダブルクリック)",
      ingredientBoxMinusButtonText:"-",
      titleIngredientBoxMinusButton:"１から10倍までの減量 (ダブルクリック)",
      ingredientBoxGetNutrientButtonText:"栄養価を表示",
      ingredientBoxCloseListButtonText:"栄養価を非表示",
      ingredientBoxNumberOfLetters:"文字数",
      footerMenuTotalt:"合計",
      footerCalorieSummary:"グラム当たりの熱量",
      saveRecepySelectCategoryFirstOption:"カテゴリ選択",
      saveRecepyButtonText:"レシピを保存",
      titleSaveRecepyButton:"マイレシピにレシピを保存",
      nutrientEnergyLegend:"熱量",
      nutrientKolhydraterLegend:"炭水化物",
      nutrientProteinLegend:"蛋白質",
      nutrientFettLegend:"脂質",
      titleSummaEnergiIRecept:"レシピ全体の熱量",
      titleTotalMangdKolhydraterIRecept:"レシピ全体の炭水化物",
      titleTotalMangdProteinIRecept:"レシピ全体の蛋白質",
      titleTotalMangdFettIRecept:"レシピ全体の脂質",
      titleAdjustBackGround:"背景を調整",
      recepyDescriptionHeader:"説明",
      recepyDescriptionAntalTecken:"文字数",
      nutrientListHeaderSubject:"100g当たりの栄養価",
      nutrientListHeaderAmount:"量",
      nutrientListHeaderValue:"数値",
      choseRecepyFirstOption:"レシピを選ぶ",
      checkboxShowPriceLabel:"価格を表示",
      recipeIconText:"レシピ",
      imageIconText:"画像",
      createImageButtonText:"画像を追加",
      createImageTextPlaceholder:"画像の説明",
      deleteImageTitle:"画像を削除",
      generateAIimageDisclaimer:"AIによる画像生成は、説明に含まれていません。このサービスには常に追加料金がかかります。料金はアカウントの価格リストに記載されています。",
      uploadIconText:"アップロード",
      downloadIconText:"AI画像",
      choseImageButtontext:"画像を選ぶ",
      choseImageUploadText:"画像をアップロード",
      recepyMustBeChosenBeforeUploadMessage:"レシピを選択してから画像をアップロードしてください!",


    },
    createAirecepy:{
title:"AI でレシピを作成する",
titleMobile:"AI でレシピを作成",
searchInputLegend:"AI でレシピ検索",
sendButtonText:"AIにレシピを尋ねる",
sendButtonTextClicked:"お待ちください",
recpyArrivedButtonHeaderText:"GPTで以下のレシピが見つかりました",
listHeaderId:"ID",
listHeaderIngrediens:"食材",
listHeaderMangd:"量",
listHeaderSum:"価格",
listHeaderKcals:"熱量",
listHeaderBakesoftMatch:"Bakesoftと照合",
listHeaderBakesoftMatchMobile:"Bsと照合",
titleAddIngredient:"食材をデータベースに追加",
titleDeleteFromRecipe:"レシピから削除",
footerTotalt:"合計",
saveNewIngredientHeader:"新しい食材を保存",
saveIngredientBoxName:"食材名",
saveIngredientBoxCategory:"カテゴリー",
saveIngredientBoxpPrice:"価格",
saveIngredientBoxSaveButtonText:"追加",
saveIngredientPricePlaceHolder:"kg当たりの価格",
titleAdjustBackground:"背景を調整",
selectAddIngredientFirstOption:"食材を追加",
saveRecepyBoxRecepyNameTitle:"レシピの名前",
saveRecepyBoxRecepyCategoryFirstOption:"カテゴリ選択",
saveRecepyBoxSaveRecepyButtonText:"保存",
openSaveRecepyBoxButtonText:"レシピを保存",
descriptionHeader:"説明",
descriptionCharacterLength:"文字数",
ingredientBoxCommentTitle:"食材へのコメント",
ingredientBoxPlusButtonText:"+",
titleIngredientBoxPlusButton:"1から10倍までの増量 (ダブルクリック)",
ingredientBoxMinusButtonText:"-",
titleIngredientBoxMinusButton:"1から10倍までの減量 (ダブルクリック)",
selectIngredientFirstOption:"食材を選択",
ailastSearchMessage:"最新のAI検索",
deleteingredientfromAiRecepy:"食材を削除",
saveRecepyTitle:"レシピを保存",

    },
    createProduct:{
      titleKlickToSave:"クリックして保存",
      saveProduktBoxMenuHeader:"製品を保存",
      namePlaceHolderText:"名前",

      title:"製品を作成",
titleMobile:"製品を作成",
leftSelectLegend:"製品のカテゴリを選択",
leftSelectFirstOption:"カテゴリを選択",
titleCreateNewProduct:"新たに製品を作成",
middleFirstSelectLegend:"食材を選択",
middleSecondSelectLegend:"レシピを選択",
middleThirdSelectLegend:"包装を選択",
middleFirstSelectFirstOption:"食材",
middleSecondSelectFirstOption:"レシピ",
middleThirdSelectFirstOption:"包装",
headerId:"ID",
headerName:"名前",
headerAmount:"量",
headerTotal:"合計",
headerCalories:"熱量",
titleSaveMenuOpenButton:"メニューを保存",
rightHeader:"製品の計算",
totalCostLegend:"価格合計",
prisHeaderLegend:"売値",
marginalHeaderLegend:"利益",
nutrientHeaderLegend:"栄養価",
caloriesHeaderLegend:"熱量",
proteinHeaderLegend:"蛋白質",
fatHeaderLegend:"脂質",
carbonHeaderLegend:"炭水化物",
titleAdjustMenu:"メニューの色を調整",
titleAdjustMenu1:"メニューの背景を調整",
titleDeleteFromProduct:"製品から削除",
titleTrash:"この製品を削除",
titleIncreaseAmount:"押すと増量",
titleDecreaseAmount:"押すと減量",
saveProduktButtonText:"製品を保存",
middleFirstSelectLegendMobile:"食材",
middleSecondSelectLegendMobile:"レシピ",
middleThirdSelectLegendMobile:"包装",
recipeIconText:"レシピ",
imageIconText:"画像",
uploadIconText:"アップロード",
downloadIconText:"AI画像",
deleteImageTitle:"画像を削除",
generateAIimageDisclaimer:"AIによる画像生成は、定期購入の内容に含まれていません。このサービスには常に追加料金がかかります。料金はアカウントの価格リストに記載されています。",
downloadIconText:"AI画像",
choseImageButtontext:".画像を選ぶ",
choseImageUploadText:"画像をアップロード",
recepyMustBeChosenBeforeUploadMessage:"レシピを選択してから画像をアップロードしてください!",

createImageButtonText:"画像を追加",
createImageTextPlaceholder:"画像の説明",
deleteImageTitle:"画像を削除",
produkttitle:"製品を作成",
noChosenProdukt:"選択された製品はありません",
descriptionIconText:"説明",
saveDescriptionButtonText:"保存",
placeholderDescriptionText:"製品説明はこちら...",
  
  
    },
    productStatistics:{
title:"製品の統計",
titleMobile:"製品の統計",
leftSelectLegend:"製品のカテゴリー",
leftSelectFirstOption:"カテゴリを選択",
fromLegend:"次から",
toLegend:"次まで",
rightStatisticLegend:"製品の統計",
chartLegend:"チャートを選択",
titleAdjustMenu:"メニューの色を調整",
titleAdjustMenu1:"メニューの背景を調整",
    },
    workwithProduction:{
      title:"商品の製造過程",
      titleMobile:"製造過程",
      leftFirstSelectLegend:"新たに製造過程を作成",
      leftFirstSelectFirstOption:"カテゴリ選択",
      leftSecondSelectFirstOption:"製品を選択",
      leftThirdSelectLegend:"現在の製造過程",
      leftThirdSelectFirstOption:"製造過程を選択",
      leftFourthSelectLegend:"古い製造過程",
      leftFourthSelectFirstOption:"履歴を選択",
      middleHeaderId:"PID",
      middleHeaderProdid:"PID",
      middleHeaderName:"名前",
      middleHeaderAmount:"量",
      middleHeaderPrice:"価格",
      middleHeaderTotal:"合計",
      middleHeaderStatus:"状況",
      titleButtonOpenSaveMenu:"メニュー保存",
      titleIncreaseAmount:"クリックして増量",
      titleDeacreaseAmount:"クリックして減量",
      saveProductionBoxHeaderLegend:"製造過程を保存",
      saveProductionInputPlaceHolder:"製造過程の名前",
      createProductionButtonText:"製造過程を作成",
      saveProductionButtonText:"保存",
      clearProductionButtonText:"仕上げる",
      rightHeaderLegend:"現在の製造過程を編集",
      rightHeaderPid:"PID",
      rightHeaderCategoryId:"CID",
      rightHeaderCategory:"カテゴリー",
      rightHeaderSum:"量",
      rightHeaderAmount:"数量",
      titleRightHeaderPid:"製造過程ID",
      titleRightHeaderCategoryId:"カテゴリID",
      titleAdjustMenu:"メニューの色を調整",
      titleAdjustMenu1:"メニューの背景を調整",
      middleHeaderMobileProdid:"ID",
      clearProductInproductionButtonText:"完成する",
      clearProductInProductionButtonText1:"完了",
      titleCreateNewProduct:"新たに製品を作成",//
    },
    totalprodNew:{
      title:"製造過程の製品数",
titleMobile:"製造過程の製品数",
leftHeaderLegend:"製造過程のすべて",
headerMenuProdid:"PID",
headerMenuProductid:"製品ID",
headerMenuProductName:"製品名",
headerMenuProductQuantity:"数量",
headerMenuProductPrice:"価格",
headerMenuProductTotal:"合計",
rightMenuHeaderLegend:"合計金額",
listHeaderLegend:"現行の製造過程を編集",
rightListHeaderPID:"PID",
rightListHeaderKID:"KID",
rightListHeaderCategory:"カテゴリー",
rightListHeaderAmount:"量",
rightListHeaderQuantity:"数量",
titleHeaderName:"名前で抽出",
titleHeaderAntal:"価格で抽出",
titleAdjustMenu:"メニューの色を調整",
titleAdjustMenu1:"メニューの背景を調整",
mobileMenuProductid:"ID",
titleProductionId:"製造過程ID",
titleCategoryId:"カテゴリID",
    },
    prodarbSum:{
title:"レシピの概要 / 製造過程",
titleMobile:"概要/製造過程",
leftSelectHeaderLegend:"レシピ概要",
leftSelectFirstOption:"製造過程を選ぶ",
middleHeaderLegend:"レシピの詳細",
middleHeaderId:"ID",
middleHeaderIngredient:"食材",
middleHeaderAmount:"量",
middleHeaderPercentage:"%",
middleheaderWorkWeight:"算出量",
middleHeaderArborder:"並べ替え",
middleDescriptionLegend:"レシピの説明",
rightHeaderLegend:"製品のメニュー",
rightHeaderProductId:"ID",
rightHeaderProductName:"製品名",
rightHeaderProductQuantity:"数量",
titleMidHeaderId:"食材ID",
titleMidHeaderName:"食材名",
titleMidHeaderIngredientAmount:"元のレシピ上にある食材の量",
titleMidHeaderPercentageOf:"レシピの配合",
titleMiddleHeaderCalculatedIngredientAmount:"レシピの総量を計算",
titleMiddleHeaderWorkOrder:"並べ替え",
titleAdjustMenu:"メニューの色を調整",
titleAdjustMenu1:"背景を調整",
footerTotalText:"合計",
recipeDescriptionLegend:"説明"

    },
    ingredtotNew:{
title:"食材の概要",
titleMobile:"食材の概要",
listHeaderLegend:"製造過程にある製品の食材概要",
listHeaderId:"ID",
listHeaderName:"食材名",
listHeaderWeight:"重さ",
listtHeaderAmount:"量",
titleHeaderName:"クリックして名前で抽出",
titleHeaderWeight:"クリックして重さで抽出",
titleHeaderAmount:"クリックして量で抽出",
rightHeaderLegendTotalAmount:"総量",
rightHeaderLegendTotalWeight:"総重量",
titleAdjustMenu:"メニューの色を調整",
titleAdjustMenu1:"背景を調整",

  
},
pricelist:{
  title:"価格リスト",
titleMobile:"価格リスト",
mainSelectLegend:"製品のカテゴリ",
mainSelectFirstOption:"カテゴリを選ぶ",
mainHeaderPid:"PID",
mainHeaderItemNumber:"品番",
mainHeaderProductName:"製品",
mainHeaderPrice:"価格",
mainHeaderMargin:"マージン",
mainHeaderInclusiveVAT:"税込",
mainHeaderSave:"保存",
mainHeaderSaved:"保存済",
titleIncreaseBy10:"クリックして10増額",
titleIncreaseBy1:"クリックして1増額",
titleIncreaseBy01:"クリックして0.1増額",
titleDecreaseBy10:"クリックして10減額",
titleDecreaseBy1:"クリックして1減額",
titleDecreaseBy01:"クリックして0.1減額",
titleName:"クリックして名前で抽出",
titlePrice:"クリックして価格で抽出",
titleMargin:"クリックしてマージンで抽出",
rightHeaderLegend:"この製品群の平均マージン",
titleAdjustMenu:"メニューの色を調整",
titleAdjustMenu1:"背景を調整",

titleMobileMargin:"ﾏｰｼﾞﾝ",
titleMargin1:"マージン",
titleMobileVat:"税込",
titleVat:"税込",
noNameGiven:"名前がありません",
},
mysettings:{
title:"マイページの設定",
mobiletitle:"ページの設定",
leftHeaderLegend:"ページを選ぶ",
midBackgroundFilter:"ページの背景フィルタを選ぶ",
midMenuFilter:"ページのメニューフィルタを選ぶ",
midMenuColor:"ページのメニューカラーを選ぶ",
saveButtonText:"保存",
rightHeaderlegend:"ページの画像を選ぶ",
},
headermenubar:{
selectMenu:"メニューを選択",
headermenuHome:"ホーム",
headermenuLogout:"ログアウト",
headermenuIngrediensList:"食材リスト",
headermenuRecipeList:"レシピリスト",
headermenuProductList:"製品リスト",
headermenuIngredients:"食材リスト",
headermenuIngredientCategories:"食材のカテゴリー",
headermenuRecipeCategories:"レシピのカテゴリー",
headermenuProductCategories:"製品カテゴリー",
headermenuMyRecipes:"マイレシピ",
headermenuCreateReceipemanually:"新しいレシピを手動で作成",
headermenuCreateRecipeWithAI:"AIで新しいレシピを作成",
headermenuCreateProduct:"製品を作成",
headermenuProductStatistics:"製品統計",
headermenuWorkWithProduction:"生産に取り組む",
headermenuTotalNumbers:"生産中の製品の総数",
headermenuRecipeSummary:"レシピの概要",
headermenuIngredientSummary:"食材の概要",
headermenuPriceList:"価格表",
headermenuPageSettings:"ページ設定",
lists:"各リスト",
InfoAboutUs:"私たちについて",
headermenuproducts:"製品",
headermenuproduction:"生産",
headermenurecipe:"レシピ",
headermenuUserDataPolicy:"ユーザーデータポリシー",
headermenuRecipeIdeas:"レシピのアイデア",
headermenuMyAccount:"マイアカウント",
headermenuRecipeScroll:"レシピスクロール",
},
loginBox:{
welcomeMessageText:"ようこそ",
emailadressText:"Email address",
setresetEmailText:"リセット用メール",
continuebuttonText:"次へ",
noaccountText:"未登録ですか？",
createaccountText:"アカウント作成",
availableLanguagesText:"以下の言語で対応しています",
loginText:"ログイン",
passwordText:"パスワード",
forgotPasswordText:"パスワードをお忘れですか？",
sendMeResetLinkText:"リセットリンクを送る",
sendResetButtonText:"送信",
resetinputPlaceholder:"電子メールアドレス",
aboutUsText:"私たち",
contactText:"問い合わせ",
privacyPolicyText:"プライバシー",
loginText2:"ログイン",
signUpText2:"サインアップ",
orText2:"または",
returnText:"戻る",
mobileWelcomeMessageText:"ようこそ",
mobileWelcomeMessageText2:"ログイン",

},
confirmationMessages:{
  ingredientCreated:"食材を作成しました",
ingredientNameUpdated:"食材名を更新しました",
ingredientUpdated:"食材を更新しました",
ingredientReplaced:"食材を置き換えました",
priceUpdated:"価格を更新しました",
ingredientDeleted:"食材を削除しました",
nutrientsUpdated:"栄養素を更新しました",
recipeUpdated:"レシピを更新しました",

categoryDeleted:"カテゴリを削除しました",
categoryDeleteQuestion:"カテゴリを削除しますか:",
categoryNameWrite:"カテゴリ名",
ingredientsMovedTochosenCategory:"食材を指定のカテゴリに移動しました",
productsMovedTochosenCategory:"製品を指定のカテゴリに移動しました",
categoryCreated:"カテゴリを作成しました",

recepyCreated:"レシピを作成しました",
recepyDeleted:"レシピを削除しました",
yesNoDeleteRecepy:"このレシピを削除しますか?",
btnYes:"はい",
btnNo:"いいえ",

productDeleted:"製品を削除しました",
productIsSaved:"製品を保存しました",
yesNoDeleteProduct:"製品を削除しますか?",
nameInfo:"45文字以内で名前を作成",

productionSaved:"製造過程を保存しました",
productionCreated:"製造過程を作成しました",
productionCleared:"製造過程をクリアしました",
aiimageIsDone:"画像が完成しました",
aiimageCantBeDone:"アカウント残高が低すぎて画像を生成できません。",
descriptionIsUpdated:"説明を更新しました",
pageSaved:"ページを保存しました",
emailSent:"メールを送信しました",
userAddressDataUpdated:"ユーザーアドレスデータを更新しました",
selectedImageDeleted:"選択した画像を削除しました",
imageDeletQuestion:"この画像を削除しますか",
yesnodeleteIngredient:"この食材を削除しますか?",
fetchingDataInformation:"データを取得して、レシピを構築しています",
prescriptionCreatedMessage:"処方箋が作成されました",
yesNoDeleteRecepyWithImages:"レシピと写真を削除しますか？",
linkNotValid:"リンクが無効です",
passwordNoMatch:"パスワードが一致しません",
passwordAtleast8:"パスワードは少なくとも8文字である必要があります",
passwordRestored:"パスワードが復元されました",
passwordRestoreFailure:"パスワードの復元に失敗しました",
resetLinkSentToEmail:"Reset link sent to email",
activateServiceMessage:"サービスがアクティブ化されました",
deactivateServiceMessage:"サービスが非アクティブ化されました",
aiImageCantBeUploaded:"画像の最大限度に達しました",
recipeCanNotBeSaved:"レシピの数が最大限度に達しました。",
recipeIdeaAccountVoid:"レシピアイデアの最大限度に達しました。",
aiSubscriptionVoid:"AIがぞうさくせいのさいだいげんどにたっしました。",
categoriesVoid:"カテゴリーのさいだいげんどにたっしました。",
ingredientVoid:"材料の最大限度に達しました",
ingredientInfoVoid:"材料情報の最大限度に達しました",
productsVoid:"製品の最大限度に達しました",
productionVoid:"生産数の最大限度に達しました",
imageuploadVoid:"画像アップロードの最大限度に達しました",
convertVoid:"変換の最大限度に達しました",
supportMessageSendText1:"サポートメッセージが送信されました",
dowloadpdfOkMessage:"PDF請求書がダウンロードされました",
aiRecipeSearchVoid:"AIレシピ検索の最大限度に達しました",

passwordsMustBelongText:"パスワードは少なくとも6文字である必要があります",
confirmPasswordtext:"パスワードを確認してください",
passwordNoMatchText:"パスワードが一致しません",
firstNameRequiredText:"名前が必要です",
lastNameRequiredText:"姓が必要です",
adressRequiredText:"住所が必要です",
postalCodeRequiredText:"郵便番号が必要です",
cityRequiredText:"市区町村が必要です",
regionRequiredText:"都道府県が必要です",
countryRequiredText:"国が必要です",
phoneRequiredText:"電話番号が必要です",
emailRequiredText:"メールが必要です",
languageRequiredText:"言語が必要です",
fullnameRequiredText:"名前が必要です",
currencyRequiredText:"通貨が必要です",
serviceRequiredText:"サービスが必要です",
emailNotValidText:"メールが有効ではありません",

accountCreatedSuccessText:"アカウント作成完了。支払いステップ2へ",
setupDetailsDeleteFailedText:"設定詳細の削除に失敗しました",
accountCreatedNoSuccessText:"アカウント作成に失敗しました",
accountAlreadyExistText:"アカウントがすでに存在します",
accountCreationFailedText:"アカウントの作成に失敗しました",

paypalPaymentConfirmText:"Paypal支払い完了、確認メールを送信しました。",
loginConfirmToManyUsersText:"デバイスが多すぎます。後で試してください",
forceLoginText:"ログイン用の復元コードを含むメールを送信しました。",
noaccessContactSupportText:"アクセス不可、サポートへ連絡。",
wrongIdOrAccountText:"IDまたはアカウントが間違っています",
notAuthorizedText:"このアプリを使う権限がありません。サポートに連絡してください。",
klarnaPaymentSuccessText:"アカウントの準備が整いました",
klarnaPaymentfailureText:"Klarnaの支払いが完了していません。",
finalPaymentMessage:"お支払いありがとうございます",
finalPaymentMessageLink:"こちらからログインしてください",
useNoAccessText:"アクセスがありません",
signUptemporaryClosedText:"サインアップは一時的に閉じられています",
clientSideUpdateSupportTicketText:"サポートチケットが更新されました",
noHelpInformationText:"ヘルプ情報がありません",
FetchingHelpInstructionsText:"ヘルプ情報を取得しています",
accountCantBeCreatedText:"このメールアドレスではアカウントを作成できません。サポートに連絡してください！",
noinvoicesFoundText:"請求書が見つかりません", 
signupButtontext:"サインアップ",
priceCantBeSetText:"価格は1から5の間でなければなりません",

},
recipeideas:{
},
recipeideas:{
recipesearchFieldset:"レシピアイデアを検索",
recipesearchPlaceholder:"e.g. ミートソースパスタ",
recipesearchButton:"検索",
recipesearchConfirmation:"結果",
recipesearchConfirmationNum:"",
recipesearchText:"レシピ",
receipesearchHeaderId:"ID",
receipesearchHeaderName:"名前",
recipeTitle:"レシピのアイデア",
titleAdjustMenu:"メニューの色を調整",
titleAdjustMenu1:"メニューの背景を調整",
imageSearchTextToLong:"画像の説明が長すぎます",
aiimageIsDone:"AIが画像を作成しました",
imageUploadedMessage:"画像をアップロードしました",
dowloadpdfOkMessage:"PDF請求書がダウンロードされました",


},
MyAccount:{
  title:"マイアカウント",
  userFirstName:"名",
  userFamilyName:"姓",
  userCompany:"会社名",
  userAdress:"住所",
  userPostalCode:"郵便番号",
  userCity:"市区町村",
  userRegion:"都道府県",
  userCountry:"国",
  userPhone:"電話番号",
  userEmail:"メール",
  userSaveButtonText1:"保存",

  userallServices:"全てのサービス",
  userDebitServices:"サービスの課金",
  userService:"サービス",
  userPrice:"価格",
  userActivate:"アクティブ",
  userStat:"じょうたい",
  numPageRequestsPerPage:"今月のページ別リクエスト数",
  userPagenr:"ページ番号",
  userPageComponent:"ページ",
  userPageNum:"閲覧数",
  userMyData:"マイデータ",
  userMyDataDescriptionText:"このページには、システムにあるデータに関する情報が表示されます。アカウント作成時にデータが含まれた基本パッケージが提供されます。",

  userDataId:"ID",
  userDataName:"名前",
  userDataAntal:"数量",
  userDataIngredientCategories:"食材カテゴリ",
  userDataRecipeCategories:"レシピカテゴリ",
  userDataProductCategories:"製品カテゴリ",
  userDataIngredient:"食材",
  userDataRecipes:"レシピ",
  userDataProducts:"製品",
  userDataProductions:"製造過程",
  userDataRecipeImages:"レシピ画像",
  userDataProductImages:"製品画像",
  userDataInstructionText:"あなた自身のデータをメールで送りたい場合は、以下のボタンを押してください。画像は含まれません。",

  useDataMyDataButtonText:"マイデータを送る",

  estimatedSaldo:"請求額",
  estimatedSaldoText:"これは現在の処方の合計費用です",
  
  nextPaymenttext:"次回の支払い",
  prePaymentText:"前払い金",
  totalAmountUsed:"使用量の合計",

    // invoice history
    invoiceHistoryText:"請求書履歴",
    invoiceHistoryInvoice:"請求書番号",
    invoiceHistorySum:"合計金額",
    invoiceHistoryDate:"日付",
    invoiceHistoryStatus:"ステータス",

    
    //invoicerows
    chooseInvoiceText:"請求書を選ぶ",
    invoiceRowsText:"請求書",
    allInvoiceRowsText:"すべての請求書",
    invoiceRowsId:"ID",
    invoiceRowsService:"サービス",
    invoiceRowsMax:"最大",
    invoiceRowsRequest:"リクエスト",
    invoiceRowsDebitQty:"デビット数量",
    invoiceRowsPrice:"価格",
    invoiceRowsTotal:"合計",
    invoiceRowsType:"タイプ",
    invoiceRowsTotalSum:"合計",

        //password
        passwordValidText:"パスワードが有効です",
        newPasswordText:"新しいパスワード",
        confirmPasswordText:"パスワードを確認",
        showPasswordText:"パスワードを表示",
        savePasswordButtonText:"保存",
        iforgotMyPasswordText:"パスワードを忘れた場合は、リセットしたいです",

    
  //support
  supportMText:"Bakesoft サポート",
  supportMessageText:"サポートメッセージ",
  supportMessageSelectText:"サポートメッセージを選ぶ",
  supportMessageSendText:"送信",
  messagesSentToSupportText:"サポートに送信されたメッセージ",
  

// settings
settingsHeaderText:"設定",
companyAndPersonalText:"会社と個人",
addressInstructionText:"この住所は、請求書などの情報に使用され、自宅または会社の実際の住所である必要があります。",
taxIdentificationVATText:"VAT登録番号",
taxIdInformationText:"EU外の登録会社は、請求書からVATを削除するために、税金識別番号を登録することができます。",
registerTaxIdButtonText:"登録",
maxValueWarningText:"月次請求書の最大値警告をアクティブ化し、設定します。最大値に達したときにメールを送信します。",
actiVateMaxValueText:"月次請求書の最大値警告をアクティブ化し、設定します。最大値に達したときにメールを送信します。",
maxValueButtonSaveText:"保存",

// buttontext
addressInfoButtontext:"住所情報",
servicesButtontext:"サービスショップ",
dataUsageButtontext:"データ使用量",
mydataButtontext:"マイデータ",
paymentButtontext:"支払い",
myAccountButtonText:"マイアカウント",
myInvoiceButtonText:"請求書と領収書",
passwordButtontext:"パスワード",
supportButtontext:"サポート",
settingsButtontext:"設定",
servicesButtonUsagetext:"利用中のサービス",


      //invoices
websiteCompanyName:"Bakesoft.se",
invoiceText:"請求書",
broughtToYouText:"Brought to you by Hellström Trading i Göteborg AB",
customerIdText:"顧客番号:",
serviceText:"サービス",
nameText:"名前",
numText:"番号",
maxText:"最大",
debitText:"料金",
kreditText:"クレジット",
totaltText:"合計",
sumNetText:"合計金額",
sumVatText:"税",
attBetalaText:"支払い:",
bankgiroText:"Bankgiro:",
swishText:"Swish:",
termsText:"支払い条件:",
bankText:"銀行:",
accountTransactionsFor:"様とのお取引",
accountService:"サービス",
accountInvoice:"請求書",
accountDebit:"料金",
accountKredit:"クレジット",
accountDate:"日付",
accountSystemComment:"システムコメント",
accountTotalDebit:"合計デビット",
accountTotalKredit:"合計クレジット",
accountTotalSaldo:"合計",
invoice:"請求書",
invoiceInfo:"請求書情報",
paymentCreditCard:"クレジットカード",
paymentInvoice:"振込",
accountOverview:"アカウント概要",
userAccount:"ユーザーアカウント",
bicText:"BIC:",
ibanText:"IBAN:",
paymentPayPal:"PayPal",
paymentSwish:"Swish",
paymentKlarna:"Klarna",

//
serviceHeaderText: "サービス",
  servicePrisHeaderText: "価格",
  serviceActivateHeaderText: "利用する",
  serviceStatusHeaderText: "ステータス",

// tjansterdebitering
debserviceidText:"ID",
debserviceBenaemningText:"名前",
debserviceQtyText:"数量",
debserviceMaxText:"最大",
debserviceUsableText:"使用可能",
debserviceStatusText:"ステータス",

// support history
supportHistoryServiceIdText:"ID",
supportHistoryDateText:"日付",
supportHistoryStatusText:"ステータス",
supportHistoryCategoryText:"件名 ",
supportRequestText:"サポートリクエスト",

//saved words overview
overviewText:"概要",
overviewInformationText:"詳細",
overviewInvoiceText:"請求書",
//invoice detail for netsum
totalnetSumText:"合計金額",
// invoice detail for vatsum
totalvatSumText:"税",
// change of password for the password page
passwordHeaderText:"パスワードの変更",
resetPasswordHeaderText:"パスワードのリセット",

// support changes
myRequestPageheaderText:"一覧",
sendsupportMessagePageHeaderText:"メッセージを送る",

//invoice
invoiceSelectFirstOption:"請求書を選ぶ",
printInvoceButtonText:"印刷",
myinformationText:"私の情報",
supportButtonAnswerText:"送信",
supportTitleText:"サポート",
supportHistoryBenaemnText:"名前",
paymentStandard:"標準",
paymentIntentErrorText:"支払いが失敗しました",
serviceActivatedText:"サービスがアクティブ化されました",
thankYouPurchaseText:"ご購入いただきありがとうございます",
paymentFailedMessageText:"支払いが失敗しました",
paymentFailedMessageText2:"もう一度お試しください",
isPayedText:"支払い済み",
isNotPayedText:"未払い",
choseImageButtontext:".画像を選ぶ",
choseImageUploadText:"画像をアップロード",

liveAccountSetupText:"アカウントの設定",
liveCreatorImageText:"ライブクリエイター画像アップロード",
liveCreatorPublicImageText:"ライブクリエイター公開画像 (5:7)",
liveCreatorProfileText:"ライブクリエーターテキスト:",
liveMax500text:"最大:500",
saveCreatorText:"テキスト保存",
liveAccountText:"ライブ垢",

},
  logout:{
    logoutMessage:"ログアウトしました！",
    loginMessage:"続けるにはログインしてください",
  },
  aialert:{
    aialertTitle:"情報",
    aialertMessage:"私たちは十分なデータを集めることができませんでした。",
    aialertMessage2:"最大3回試して、その後はテキストを変更してください",
    aialertButtonText:"終了",
  },
  resetPass:{
    headerText:"パスワードをリセット",
    resetPassInputPlaceHolder:"新しいパスワード",
    resetPassInputConfirmPlaceHolder:"新しいパスワードを確認",
    resetPassButtonText:"パスワードを再設定する",
    },
    scrollRecipe:{
      mobileTitle:"レシピスクロール",
      title:"レシピスクロール",
      titleJusteraBakgrund:"メニューの背景を調整",
      scrollIngredientButtontext:"食材リスト",
      scrollDescriptionButtontext:"説明",
      scrollBenaemnText:"名前",
      scrollMangdText:"量", 
      scrollSummaText:"合計",
      scrollKcalText:"カロリー",
      scrollArborderText:"並べ替え",
      scrollDescriptionHeaderText:"説明",
    },
    info:{
      startButtonText:"スタート",
      signupButtonText:"アカウントを新規作成",
      signupButtonPrivateText: "サインアップ",
      signupButtonCompanyText:"会社",
      applicationButtonText:"アプリケーション",
      applicationPlusServicesText:"アプリケーションプラスサービス",
      aiServicesButtonText:"AIサービス",
      aboutUsButtonText:"Bakesoftについて",
      faqButtonText:"FAQ",
      basicButtonText:"基本",
      totalButtonText:"合計",
      basicCorporate:"基本企業",
      totalCorporate:"合計企業",
      basicStoryButton:"ストーリー",
      basicfunctionalityButtonText:"機能",
      pleasefillupFormText:"フォームに記入してください",
      aftersignupemailwillbesent:"サインアップ後、確認メールが送信されます",
      accountsetupin24hrs:"アプリ内での支払いにより、5分以内にアカウントが開設されます",
      accountsetupSlow:"他の支払い方法では、24時間以内にアカウントが開設されます。",
      signUpHeaderText:"アカウントを作成",
      firstNameText:"名",
      lastNameText:"姓",
      addressText:"住所",
      postalCodeText:"郵便番号",
      cityText:"市区町村",
      regionText:"都道府県",
      countryText:"国",
      emailText:"メール",
      phoneText:"電話番号",
      passwordText:"パスワード",
      confirmPasswordText:"パスワードを確認",
      selectLanguageText:"言語を選択",
      selectSwedLangText:"スウェーデン語",
       selectEngLangText:"英語",
       selectJapLangText:"日本語",
       selectCurrencyText:"通貨を選択",
       selectSekCurrencyText:"SEK",
       selectUsdCurrencyText:"USD",
       selectJapCurrencyText:"JPY",
       selectServiceText:"サービスを選択",
       selectBasicServiceText:"Basic",
       selectTotalServiceText:"Total",
       selectBasicCorporateServiceText:"Basic corporate",
       selectTotalCorporateServiceText:"Total corporate",
       allfieldsMandatoryText:"すべてのフィールドは必須です",
       signUpButtonText:"アカウントを新規作成",

       applicationBaseServicesHeader:"アプリケーションの基本サービス",
       applicationBaseVersions:"アプリケーションには4つの異なる基本バージョンがあります",
       applicationBaseVersions1:"Basic",
       applicationBaseVersions2:"Total",
       applicationBaseVersions3:"Basic corporate",
       applicationBaseVersions4:"Total corporate",
       applicationCanusePartly:"すべての部品は、製品および生産に使用できます。",
       applicationCanBeUsed:"すべての部品は使用できます。",
       plusServiceName:"サービス名",
       plusServicePrice:"価格",
       plusServiceBenaemning:"名前",
       pricesGivenPerMonthText:"すべての価格は月額です",
       plusServicesHeaderText:"以下のサービスは、アプリケーション内で\n",
       plusServicesHeaderText1:"会社を成長させるために利用できます",
       faqHeaderText:"よくある質問",
       totalWeTelltext:"Totalとは何ですか?",
       totalFunctionalitytext:"included",
       totalCorporateWeTellText:"Total corporate",
       totalCorporateFunctionalityText:"included",
       basicCorporateWeTell:"Basic corporate",
       basicCorporateFunctionality:"included",

       //paypal payment
       paypalMainHeaderText:"ベイクソフト支払いサービス（PayPal）",
       klarnaMainHeaderText:"ベイクソフト支払いサービス（Klarna）",
       pptotalAmountToPayText:"支払総額",
       ppChosenServiceText:"選択したサービス",
       ppChosenCurrencyText:"選択した通貨",
       //menu text
       registerAccountTabText:"アカウント登録、ステップ1",
       paymentTabText:"支払い、ステップ2",
       ppBakesoftTermsText:"利用規約",
       ppBakesoftTerms:"いつでも退会できます",
       ppSelectInvoiceText:"請求書を選択",
       ppSelectCurrencyText:"通貨を選択",
       contactInfoText:"連絡先情報",
       privacyPolicyText:"プライバシーポリシー",
       klickOnTextMoreInfo:"詳細をクリック",
       integrityPolicyText:"個人情報保護方針",
       contactInformationText:"連絡先情報",
       buyingTermsText:"購入条件",
       signupCompanyButtonText:"会社アカウントを作成",


      totalHeaderText: "処方箋の合計",
      totalBasIncludedText:"企業基本プランに含まれています",
      totalUppTo800RecipeText: "800のレシピ",
      totalProductsMax500Text: "500の製品",
      totalProductionText: "500の生産",
      totalAutomaticRecipeSummaryText: "自動レシピの要約",
      totalAutomaticIngredientSummaryText: "自動材料の要約",
      totalAutomaticProductionStatisticText: "自動生産統計",
      totalCreatePricelistText: "利益率を含む価格表を作成",
      total200ProductCategoriesText: "200の製品カテゴリ",

      basCorpHeaderText: "基本企業",
      basCorporateSave500RecipeText: "500のレシピを保存",
      basCorpUse500IngredientsText: "500の材料を使用",
      basCorpCreate200IngredientCategoriesText: "200の材料カテゴリを作成",
      basCorpCreateRecipeWithAiText: "AIでレシピを作成",
      basCorpCreateRecipeManuallyWithIngredientsText: "材料で手動でレシピを作成",
      basCorpSearchRecipeIdeaText: "レシピのアイデアを検索",
      basCorpCreateImageWithAiText: "AIで画像を作成",
      basCorpUploadOwnImageText: "自分の画像をアップロード",
      basCorpSave1000ImagesText: "1000の画像を保存",
      basCorpCreate200RecipeCategoriesText: "200のレシピカテゴリを作成",
      basCorpAutomaticNutrientCalculationText: "自動栄養計算",
      basCorpAutomaticCostCalculationText: "自動コスト計算",
      basCorpAutomaticMatchIngredientsText: "材料の自動マッチング",
      basCorpAutomaticGetNutrientsText: "栄養価を自動取得",
      basCorpInformationsAboutIngredientsText: "材料に関する情報",
      basCorpConvertVolumeToGramText: "体積をグラムに変換",
      basCorpManageIngredientRecipeLinksText: "材料-レシピリンクを管理",
      basCorpMoveManageLinksAutomaticallyText: "リンクを自動で移動・管理",
      basCorpIngredientPriceText: "材料の価格を管理",
      basCorpAutomaticPriceDiagramText: "自動価格図",
      basCorpHumoristicCommentsText: "AIによるユーモアなコメント",


      basPrivateSave300RecipeText: "300のレシピを保存",
basPrivateUse500IngredientsText: "500の材料を使用",
basPrivateCreate100IngredientCategoriesText: "100の材料カテゴリを作成",
basPrivateSearchCreateRecipeAiText: "AIでレシピを検索・作成",
basPrivateCreateRecipeManuallyWithIngredientsText: "材料で手動でレシピを作成",
basPrivateSearchRecipeIdeaText: "レシピのアイデアを検索",
basPrivateCreateImageWithAiText: "AIで画像を作成",
basPrivateUploadOwnImageText: "自分の画像をアップロード",
basPrivateSave400ImagesText: "400の画像を保存",
basPrivateCreate100RecipeCategoriesText: "100のレシピカテゴリを作成",
basPrivateAutomaticNutrientCalculationText: "自動栄養計算",
basPrivateAutomaticCostCalculationText: "自動コスト計算",
basPrivateAutomaticMatchIngredientsText: "材料の自動マッチング",
basPrivateAutomaticGetNutrientsText: "栄養価を自動取得",
basPrivateInformationsAboutIngredientsText: "材料に関する情報",
basPrivateConvertVolumeToGramText: "体積をグラムに変換",
basPrivateManageIngredientRecipeLinksText: "材料-レシピリンクを管理",
basPrivateMoveManageLinksAutomaticallyText: "リンクを自動で移動・管理",
basPrivateIngredientPriceText: "材料の価格を管理",
basPrivateAutomaticPriceDiagramText: "自動価格図",
basPrivateHumoristicCommentsText: "AIによるユーモアなコメント",

totalPrivateBaseIncludedText: "ベーシックプランにすべて含まれています",
totalUppTo400RecipeText: "400のレシピ",
totalProductsMax300Text: "300の製品",
totalProductionText2:"250の生産",
totalAutomaticRecipeSummaryText2: "自動レシピ要約",
totalAutomaticIngredientSummaryText2: "自動材料要約",
totalAutomaticProductionStatisticText2: "自動生産統計",
createProductionMax250Text: "最大250の生産を作成",
createPricelistWithMarginText: "利益率付きの価格表を作成",
createUpTo100ProductCategoriesText: "最大100の製品カテゴリを作成",

signUpSubscriptionHeaderText: "プランを選択",
basicPlanText: "エントリープラン ",
basServiceIdText: "サービスID:127",
basFeaturesText: "基本機能、個人ユーザー向け",
totalPlanText: "プレミアムプラン",
totalServiceIdText: "サービスID:128",
totalFeaturesText: "拡張機能、個人ユーザー向け",

basicCorpPlanText: "エントリー法人プラン",
basCorpServiceIdText: "サービスID:133",
basCorpFeaturesText: "基本機能、企業向け",
totalCorpPlanText: "プレミアム法人プラン",
totalCorpServiceIdText: "サービスID:134",
totalCorpFeaturesText: "拡張機能、企業向け",

basPrivateHeaderText: "エントリー",
totalPrivateHeaderText: "プレミアム",
basCorporateheaderText: "エントリー法人",
totalCorporateheaderText: "プレミアム法人",

selectPlanIntentTypeText: "期間を選択",
selectMonthlyPlanText: "月間",
selectTrippleMonthPlanText: "3ヶ月",
selectYearlyPlanText: "年間",
discountText:"オフ",

selectPlanIntentTypeText: "期間を選択",
selectMonthlyPlanText: "月間",
selectTrippleMonthPlanText: "3ヶ月",
selectYearlyPlanText: "年間",
discountText: "割引",
choseCountryText:"国を選択",

choseCountryText:"国を選択",
prepayLanguageHeader:"言語",
prepayCountryHeader:"通貨",
prepayCurrencyHeader:"通貨",
prepayPeriodHeader:"期間",

paymentDetailsHeaderText:"支払い詳細",
paymentDetailsCurrencyText:"通貨",
paymentPlanText:"プラン",
discountText:"割引",
paymentTotalToPayText:"支払総額",

orderOverviewText:"注文概要",
plandetailText:"プランの詳細",

fullNameLabelText:"名前",
emailLabelText:"メール",
passwordLabelText:"パスワード",
confirmPasswordLabelText:"パスワードを確認",
continueLabelText:"続ける",      
welcomeLabelText:"ようこそ",
signUpContinueLabelText:"サインアップまたは続行",
orLabelText:"または",

successPaymentHeaderText: "支払いが完了しました",
successPaymentText: "お支払いありがとうございます。お支払いが完了しました。",
successPaymentIdText: "支払いID",
successPaymentAmountText: "支払い金額",
successPaymentServiceText: "サービス",

paymentFailedHeaderText: "支払いに失敗しました",
paymentFailedText: "支払いが完了できませんでした。もう一度お試しいただくか、サポートに連絡してください。",

accountSetupServiceText: "サービス",
accountSetupLanguageText: "言語",
accountSetupCurrencyText: "通貨",
accountSetupPeriodText: "期間",
accountSetupCountryText: "国",
accountSetupTotalAmountText: "支払額 (しはらいがく",
accountSetupHeaderText: "アカウント設定情報",

accountSetupCancelText:"キャンセル",
accountSetupTermsText:"利用規約",
confirmPayButtonText:"支払いを確認", 
gotoLoginMessageText:"ログインページに移動します",
paymentSucceededText:"支払いが成功しました",
paymentProcessingText:"支払い処理中",
paymentFailedTextInfo:"支払いに失敗しました",
paymentSomethingWentWrongText:"支払いに問題が発生しました",
paymentSucceededEmailText:"支払いが成功しました。確認メールが送信されました。",

continueWithFacebookText:"Facebookで続行",
continueWithGoogleText:"Googleで続行",
continueWithLineText:"Lineで続行",
notValidFacebookTokenText:"Facebookトークンが有効ではありません",
basPrivateIncludedText:"エントリープランにすべて含まれています",
planFeaturesText: "プランの特徴",
passwordText:"パスワード",
passwordConfirmText:"パスワードを確認",
termsText:"利用規約およびプライバシーポリシーに同意します。",


      },
      welcomeImage:{
        importantNoticeText:"重要なお知らせ",
        invoiceDueText:"請求書の支払い期限",
        invoiceText:"請求書",
        serviceIdText:"サービスID",
        dueDateText:"支払い期限",  
        gotoMyAccountText:"マイアカウントに移動",
        ifnopaymentText:"支払いがない場合は、アカウントが停止されます",
        contactSupportText:"サポートパネルまたはメールでサポートに連絡してください。",
        supportLinkText:"サポートに連絡",
        amountText:"金額",
        settlePaymentHereText:"ここで支払いを行う",
        serviceDueText:"サービスの支払い期限",
        deleteText:"削除",
        payText:"支払う",
        subscriptionInRedText: "赤い表示のサブスクリプションは支払う必要があります!!"

        
      },
      liveArea:{
        liveAreaText:"ライブエリア",
        unpublishButtonText:"公開停止",
        publishThisRecipeText:"このレシピを公開",
        recipeIsPublishedText:"レシピが公開されました",
        creatorStatusText:"作成者ステータス",
        recipeIdText:"レシピID",
        liveImageText:"画像",
        liveInstructionText:"手順",
        recipeToPublishText:"公開するレシピ",
        liveTimeText:"時間",
        liveDifficultyText:"難易度",
        liveSalesPriceText:"販売価格",
        userAccessText:"ユーザーアクセス",
      },

      // Add more pages as needed
    },
    en: {   
        common: {
          greeting: "Hello",
          farewell: "Goodbye"
        },
        ingredienslista:{
          title: "Ingredient list",
          titleMobile: "Ingredient list",
          kategoriLegend: "Choose category",
          selectFirstOption: "Choose category",
          headerId: "Id",
          headerNamn: "Name",
          headerAktiv: "Activ",
          headerDela: "Shar",
          headerSpara: "Save",
          ListSaveButton: "Save",
          SavedOnButton:"Saved",
          titleAdjustMenu:"Adjust menucolor",
          titleAdjustMenu1:"Adjust background",

  
        },
        receptlista: {
        title: "Recipe list - my recipes",
        titleMobile: "Recipe list",
        kategoriLegend: "Choose category",
        selectFirstOption: "Choose category",
        headerId: "Id",
        headerNamn: "Name",
        headerAktiv: "Activ",
        headerDela: "Shar",
        headerSpara: "Save",
        ListSaveButton: "Save",
        SavedOnButton:"Saved",
        titleAdjustMenu:"Adjust menucolor",
        titleAdjustMenu1:"Adjust background",
        },
        produktlista: {
        title: "Productlist - my products",
        titleMobile: "Productlist",
        kategoriLegend: "Choose category",
        selectFirstOption: "Choose category",
        headerId: "Id",
        headerNamn: "Name",
        headerAktiv: "Activ",
        headerDela: "Shar",
        headerSpara: "Save",
        ListSaveButton: "Save",
        SavedOnButton:"Saved",
        titleAdjustMenu:"Adjust menucolor",
        titleAdjustMenu1:"Adjust background",
        },
        ingredienser:{
        title:"Ingredient list - my ingredients",
        titleMobile: "Ingredient",
        ingredbutton:"Ingredient list",
        ingredcreatebutton:"Create Ingredient",
        receptkoppbutton:"Recipe connections",
        produktkoppbutton:"Product connections",
        inkopbutton:"Purchase price",
        prisdiabutton:"Price chart",
        nutrientbutton:"Nutrients",
        konvertbutton:"Conversion",
        ingredinfobutton:"Ingredient information",
        chooseingredcategoryFieldset:"Select ingredient category",
        ingredcategoryFirstOption1:"Select category",
        changeingredNameFieldset:"Change ingredient name",
        changeingredNameSaveButton:"Save",
        nutrientHeaderHas:"have",
        nutrientHeaderReceptKopp:"recipe links",
        nutrientHeaderAnd:"and",
        nutrientHeaderProdkopp:"product links",
        nutrientHeaderSmall:"Ingredient has links, cannot be removed",
        nutrientDeleteInfoButton:"Remove ingredient",
        nutrientlistTopSubject:"Nutrients per 100gr",
        nutrientlistTopAmount:"Amount",
        nutrientListTopWeight:"Type weight",
        createingredientNameLegend:"Ingredient name",
        createingredientSaveButton:"Save Ingredient",
        produktkoppHeaderLegendText:"No products linked to",
        produktkoppInputHeaderTextLegend:"Replace",
        produktkoppInputHeaderTextLegend2:"in selected products with",
        produktkoppMessageTextLegend:"selected products to change",
        produktkoppSaveButtonText:"Replace & save",
        inkopsprisChangePriceLegend:"Update price on",
        inkopsprisLatestPriceLegend:"Latest price per kg:",
        inkopsprisListHeaderLegend:"Purchase data for",
        prisdiagramChoseChartLegend:"Select chart",
        nutrientHeaderLegend:"Nutrients",
        nutrientFetchDataButtonText:"Get nutrients",
        nutrientSaveDataButtonText:"Save",
        nutrientListHeaderTextSubject:"Nutrients per 100gr",
        nutrientListHeaderTextAmount:"Amount",
        nutrientListHeaderTextWeight:"Weight",
        konvertHeaderTextLegend:"Convert",
        konvertHeaderTextLegend2:"to grams",
        konvertSaveButtonText:"Convert",
        ingredInfoHeaderTextlegend:"Information about",
        ingredInfoGetInfoButtonText:"Fetch information",
        ingredInfoGetNewInfoText:"Fetch new information",
        ingredcanberemovedText:"Ingredient can be removed!",
        recepyConnectionHeader:"Recepies connected to",
        recepySelectHeaderLegend:"Replace",
        recepySelectHeaderLegend2:"in selected recipes with",
        recepySelectedStatementLegend:"selected recipes to change",
        recepyConnectionSaveButtonText:"Replace & save",
        produktkoppHeaderTextLegend2:"Products connected to",
        inkopPriceHeaderBenaemn:"Purchase date",
        inkopPriceHeaderPrice:"Price",
        inkopChangePriceSaveButton:"Save",
        prisdiagramHeaderLegend:"Price development for",
        convertDisclaimerText:"ATTENTION! Conversion is only an approximate value that we retrieve after searching on the internet, or at the supplier. We judge that the data which is usually useful, but in case of extra thorough need, we ask you to check more sources.",
        konvertGramText:"gram",
        measureTableSpoon:"Tablespoon",
        measureTeaSpoon:"Teaspoon",
        measureDeciliter:"Deciliter",
        measureMilliliter:"Milliliter",
        measurePinch:"Pinch",
        ingredSelect:"Select ingredient",
        measureSelect:"Select measure",
        },
        ingredcategories:{
        title:"Ingredient categories",
        titleMobile:"Ingredient categories",
        categoryHeaderKategori:"Category",
        categoryHeaderAntal:"Quantity",
        categoryMoveIngredLegend:"Move ingredient",
        categorySelectFirstOption:"Choose category",
        categoryButtonMoveSubmit:"Move",
        categoryCreateCategorySubmit:"Create category",
        furiganaLabel:"Create category",
        },
        recepycategories:{
            title:"Recipe categories",
            titleMobile:"Recipe categories",
            categoryHeaderKategori:"Category",
            categoryHeaderAntal:"Quantity",
            categoryMoveIngredLegend:"Move",
            categorySelectFirstOption:"Choose category",
            categoryButtonMoveSubmit:"Move",
            categoryCreateCategorySubmit:"Create category",

        },
        productcategories:{
            title:"Product categories",
            titleMobile:"Product categories",
            categoryHeaderKategori:"Category",
            categoryHeaderAntal:"Quantity",
            categoryMoveIngredLegend:"Move",
            categorySelectFirstOption:"Choose category",
            categoryButtonMoveSubmit:"Move",
            categoryCreateCategorySubmit:"Create category"
        },
        myrecipes:{
          title:"My recipes",
          titleMobile:"My recipes",
          leftSelectLegendMinaRecept:"Recipe categories",
          leftSelectFirstOption:"Choose category",
          aiHeader:"Comment from AI",
          listHeaderId:"Id",
          listHeaderIngrediens:"Ingredient",
          listHeaderMangd:"Amount",
          listHeaderKostnad:"Cost",
          listHeaderKal:"Cal",
          listHeaderOrder:"Order",
          ingredCommentBoxInfo:"Comment to ingredient",
          ingredInfoBoxPlus:"Plus",
          ingredInfoBoxMinus:"Minus",
          getNutrientInfoButton:"Fetch nutritional value",
          hideNutrientValues:"Hide nutritional values",
          antalTeckenInfoText:"Number of characters",
          addIngredientSelectLegend:"Ingredient",
          addIngredientSelectFirstOption:"Choose ingredient",
          numPortionSelectLegend:"Portions",
          numPortionsSelectlegendMobile:"Portions",
          portionSizeInputLegend:"Size/portion in grams",
          portionSizeInputLegendMobile:"Size/Portion",
          saveRecepyButtonText:"Save recipe",
          saveRecepyButtonTextMobile:"Save",
          sizeRecepyDoublerLegend:" size",
          nutrientEnergyLegend:"Energy",
          nutrientKolhydraterLegend:"Carbohydrates",
          nutrientProteinLegend:"Protein",
          nutrientFettLegend:"Fat",
          rightHeaderBeskrivning:"Description",
          rightBottomAntalTecken:"Letter count",
          titleJusteraBakgrund:"Adjust background",
          titleTaBortDettaRecept:"Take away this recipe",
          titleDatabasensIngrediensId:"Database ingredient id",
          titleKlickaforattSortera:"Klick to sort",
          titleMangdIgramPerIngrediens:"Quantity in grams per portion",
          titleKostnadPerIngrediens:"Kost per ingredient",
          titleKalorierPerIngrediens:"Calories",
          titleKlickaForMerInformation:"Klick for more information",
          titleTaBortRecept:"Delete recipe",
          titleAntalKalorierPerGramKalorierPerPortion:"Number of calories per gram/calories per serving",
          titleAndraStorlekPaRecept:"Change size of receipt",
          titleSummaEnergiIRecept:"Total energy in recipe",
          titleTotalMangdKolhydraterIRecept:"Total amount of carbohydrates in recipe",
          titleTotalMangdProteinIRecept:"Total amount of protein in recipe",
          titleTotalMangdFettIRecept:"Total amount of fat in recipe",
          titleHamtaNaringsvarde:"Get nutritional values",
          titleMinskaMangd:"Reduce amount",
          titleOkaMangd:"Increase amount",
          titleHamtaNaringsvarde:"Get nutritional values",
          titleDeleteFromRecipe:"Delete from recipe",
          footerTotalt:"Total",
          textAreaKomment:"Comment to ingredient",
          nutrientListHeaderSubject:"Nutrients per 100g",
          nutrientListHeaderAmount:"Amount",
          nutrientListHeaderValue:"Value",
          titleTotalSummary:"Number of calories per gram/calories per serving",
          portionInputText:"Size in grams",
          titleDoublerLegend:"Resize recipes",
          searchLegend:"Search recipes",
          searchPlaceholdertext:"Recipe name",
          searchButton:"Search",
          imageIconText:"Images",
          recipeIconText:"Recipe",
          uploadIconText:"Upload image",
          downloadIconText:"AI image",
          descriptionIconText:"Description",
          eleteImageTitle:"double click to delete image",
          choseImageButtontext:".Choose image",
          choseImageUploadText:"Upload image",
          recepyMustBeChosenBeforeUploadMessage:"A recipe must be chosen before you can upload an image!",
          createImageTextPlaceholder:"Enter text to generate image",
          createImageButtonText:"Create AI image",
          generateAIimageDisclaimer:"Creation of images by AI is not included in your subscription.There is always extra charge for this service.Prices can be found at your account - pricelist.",
          publishSalesPriceText:"Sales price",
          publishDifficultyText:" Difficulty",
          publishDifficultyOption1:"Easy",
          publishDifficultyOption2:"Medium",
          publishDifficultyOption3:"Hard",
          publishButtonText:"Publish",
          inputPriceText:"Price in credits",
          inputMinuteText:"Time in minutes",
          publishTimeText:"Time",


          
        },
        mancreaterecipe:{
          title:"Create recipes - manually",
          titleMobile:"Create recipes manually",
          leftFirstSelectLegend:"Start with ingredient",
          leftSecondSelectLegend:"Starta with a recipe",
          leftSelectFirstOption:"Choose ingredient",
          recepyTitlePlaceHolder:"Name of recipe",
          menuHeaderId:"Id",
          menuHeaderIngrediens:"Ingredient",
          menuHeaderMangd:"Amount",
          menuHeaderKostnad:"Cost",
          menuHeaderKalorier:"Cal",
          menuHeaderOrder:"Order",
          titleMenuHeaderId:"Database ingredient id",
          titleMenuHeaderIngrediens:"Ingredient, klick to sort",
          titleMenuHeaderMangd:"Amount in grams per ingredient",
          titleMenuHeaderKostnad:"Cost per ingredient",
          titleMenuHeaderKalorier:"Calories, click to sort",
          titleMenuHeaderOrder:"Klick to sort",
          titleDeleteIngredient:"Click to remove ingredient from recipe",
          ingredientBoxCommentTitle:"Comment on ingredient",
          ingredientBoxPlusButtonText:"Plus",
          titleIngredientBoxPlusButton:"Increase quantity by 1 or 10 (double click)",
          ingredientBoxMinusButtonText:"Minus",
          titleIngredientBoxMinusButton:"Decrease quantity by 1 or 10 (double click)",
          ingredientBoxGetNutrientButtonText:"Fetch nutritional values",
          ingredientBoxCloseListButtonText:"Hide nutritional values",
          ingredientBoxNumberOfLetters:"Letter count:",
          footerMenuTotalt:"Total",
          footerCalorieSummary:"Number of calories per gram",
          saveRecepySelectCategoryFirstOption:"Save in category",
          saveRecepyButtonText:"Save recipe",
          titleSaveRecepyButton:"Save recipes in My Recipes",
          nutrientEnergyLegend:"Energy",
          nutrientKolhydraterLegend:"Carbohydrates",
          nutrientProteinLegend:"Protein",
          nutrientFettLegend:"Fat",
          titleSummaEnergiIRecept:"Total energy in recipes",
          titleTotalMangdKolhydraterIRecept:"Total amount of carbohydrates in recipe",
          titleTotalMangdProteinIRecept:"Total amount of protein in recipe",
          titleTotalMangdFettIRecept:"Total amount of fat in recipe",
          titleAdjustBackGround:"Adjust background",
          recepyDescriptionHeader:"Description",
          recepyDescriptionAntalTecken:"Number of characters",
          nutrientListHeaderSubject:"Nutrients per 100g",
          nutrientListHeaderAmount:"Amount",
          nutrientListHeaderValue:"Value",
          choseRecepyFirstOption:"Choose recipe",
          checkboxShowPriceLabel:"Show price",
          recipeIconText:"Recipe",
          imageIconText:"Images",
          createImageButtonText:"Create AI image",
          createImageTextPlaceholder:"Image description",
          deleteImageTitle:"double click to delete image",
          generateAIimageDisclaimer:"Generation of images by AI is not included in your description.There is always extra charge for this service.Prices can be found at your account - pricelist.",
          uploadIconText:"Upload image",
          downloadIconText:"AI image",
          choseImageButtontext:".Choose image",
          choseImageUploadText:"Upload image",
          recepyMustBeChosenBeforeUploadMessage:"A recipe must be chosen before you can upload an image!",



        },
        createAirecepy:{
title:"Create recipes with AI",
titleMobile:"Create recipes with AI",
searchInputLegend:"Search AI for a recipe",
sendButtonText:"Ask AI for recepy",
sendButtonTextClicked:"Waiting...",
recpyArrivedButtonHeaderText:"AI has found the following recipe",
listHeaderId:"Id",
listHeaderIngrediens:"Ingredient",
listHeaderMangd:"Amount",
listHeaderSum:"Sum",
listHeaderKcals:"Cal",
listHeaderBakesoftMatch:"Bakesoft match",
listHeaderBakesoftMatchMobile:"Bs match",
titleAddIngredient:"Add ingredient to database",
titleDeleteFromRecipe:"Remove from recipe",
footerTotalt:"Total",
saveNewIngredientHeader:"Save new Ingredient",
saveIngredientBoxName:"Name",
saveIngredientBoxCategory:"Category",
saveIngredientBoxpPrice:"Price",
saveIngredientBoxSaveButtonText:"Add",
saveIngredientPricePlaceHolder:"Price per kg",
titleAdjustBackground:"Adjust background",
selectAddIngredientFirstOption:"Add ingredient",
saveRecepyBoxRecepyNameTitle:"Name of recipe",
saveRecepyBoxRecepyCategoryFirstOption:"Choose category",
saveRecepyBoxSaveRecepyButtonText:"Save",
openSaveRecepyBoxButtonText:"Save recipe",
descriptionHeader:"Description",
descriptionCharacterLength:"Letter count",
ingredientBoxCommentTitle:"Comment on ingredient",
ingredientBoxPlusButtonText:"Plus",
titleIngredientBoxPlusButton:"Increase quantity by 1 or 10 (double click)",
ingredientBoxMinusButtonText:"Minus",
titleIngredientBoxMinusButton:"Decrease quantity by 1 or 10 (double click)",
selectIngredientFirstOption:"Choose ingredient",
ailastSearchMessage:"Last AI search",
deleteingredientfromAiRecepy:"Delete ingredient from recipe",
saveRecepyTitle:"Save recipe",
},
  createProduct:{
title:"Create product",
titleMobile:"Create product",
leftSelectLegend:"Choose product category",
leftSelectFirstOption:"Choose category",
titleCreateNewProduct:"Create new product",
middleFirstSelectLegend:"Choose ingredient",
middleSecondSelectLegend:"Choose receipt",
middleThirdSelectLegend:"Choose packaging",
middleFirstSelectFirstOption:"Ingredient",
middleSecondSelectFirstOption:"Receipt",
middleThirdSelectFirstOption:"Packaging",
headerId:"Id",
headerName:"Name",
headerAmount:"Amount",
headerTotal:"Total",
headerCalories:"Calories",
titleSaveMenuOpenButton:"Save menu",
rightHeader:"Product calculation",
totalCostLegend:"Totalcost",
prisHeaderLegend:"Price",
marginalHeaderLegend:"Margin",
nutrientHeaderLegend:"Nutritional values",
caloriesHeaderLegend:"Calories",
proteinHeaderLegend:"Protein",
fatHeaderLegend:"Fat",
carbonHeaderLegend:"Carbohydrates",
titleAdjustMenu:"Adjust menu color",
titleAdjustMenu1:"Adjust menu background",
titleDeleteFromProduct:"Remove from product",
titleTrash:"Delete this product",
titleIncreaseAmount:"Klick to increase amount",
titleDecreaseAmount:"Klick to decrease amount",
saveProduktButtonText:"Save",
saveProduktBoxMenuHeader:"Save product",
titleKlickToSave:"Click here to save product",
namePlaceHolderText:"Name",
middleFirstSelectLegendMobile:"Ingredient",
middleSecondSelectLegendMobile:"Receipt",
middleThirdSelectLegendMobile:"Packaging",
recipeIconText:"Recipe",
imageIconText:"Images",
uploadIconText:"Upload image",
downloadIconText:"AI image",
deleteImageTitle:"double click to delete image",
generateAIimageDisclaimer:"Generation of images by AI is not included in your description.There is always extra charge for this service.Prices can be found at your account - pricelist.",
downloadIconText:"AI image",
choseImageButtontext:".Choose image",
choseImageUploadText:"Upload image",
recepyMustBeChosenBeforeUploadMessage:"A product must be chosen before you can upload an image!",
createImageButtonText:"Create AI image",
createImageTextPlaceholder:"Image description",
deleteImageTitle:"double click to delete image",
produkttitle:"Product",
noChosenProdukt:"No chosen product",
descriptionIconText:"Description",
saveDescriptionButtonText:"Save",
placeholderDescriptionText:"Product description here..",
  
        },
  productStatistics:{
title:"Product statistics",
titleMobile:"Product statistics",
leftSelectLegend:"Product categories",
leftSelectFirstOption:"Choose category",
fromLegend:"From:",
toLegend:"To:",
rightStatisticLegend:"Statistics for product",
chartLegend:"Select chart",
titleAdjustMenu:"Adjust menu color",
titleAdjustMenu1:"Adjust menu background",

  },
  workwithProduction:{
title:"Work with production",
titleMobile:"Production",
leftFirstSelectLegend:"Create new production",
leftFirstSelectFirstOption:"Select category",
leftSecondSelectFirstOption:"Select product",
leftThirdSelectLegend:"Ongoing production",
leftThirdSelectFirstOption:"Select production",
leftFourthSelectLegend:"Earlier production",
leftFourthSelectFirstOption:"Select history",
middleHeaderId:"Pid",
middleHeaderProdid:"Prodid",
middleHeaderMobileProdid:"Id",
middleHeaderName:"Name",
middleHeaderAmount:"Pcs",
middleHeaderPrice:"Price",
middleHeaderTotal:"Total",
middleHeaderStatus:"Status",
titleButtonOpenSaveMenu:"Save menu",
titleIncreaseAmount:"Klick to increase quantity",
titleDeacreaseAmount:"Klick to reduce quantity",
saveProductionBoxHeaderLegend:"Save production",
saveProductionInputPlaceHolder:"Name of production",
createProductionButtonText:"Create production",
saveProductionButtonText:"Save",
clearProductionButtonText:"Finalize production",
rightHeaderLegend:"Compilation of ongoing production",
rightHeaderPid:"Pid",
rightHeaderCategoryId:"Kid",
rightHeaderCategory:"Category",
rightHeaderSum:"Amount",
rightHeaderAmount:"Quantity",
titleRightHeaderPid:"Production id",
titleRightHeaderCategoryId:"Category id",
titleAdjustMenu:"Adjust menu color",
titleAdjustMenu1:"Adjust menu background",
clearProductInproductionButtonText:"Finalize",
clearProductInProductionButtonText1:"Done",
titleCreateNewProduct:"Skapa ny produkt",
  },
  totalprodNew:{
title:"Total number of products in production",
titleMobile:"Total in production",
leftHeaderLegend:"Total to produce",
headerMenuProdid:"Prodid",
headerMenuProductid:"Productid",
mobileMenuProductid:"id",
headerMenuProductName:"Name of product",
headerMenuProductQuantity:"Quantity",
headerMenuProductPrice:"Price",
headerMenuProductTotal:"Total",
rightMenuHeaderLegend:"Total sum",
listHeaderLegend:"Compilation of ongoing production",
rightListHeaderPID:"Pid",
rightListHeaderKID:"Kid",
rightListHeaderCategory:"Category",
rightListHeaderAmount:"Amount",
rightListHeaderQuantity:"Quantity",
titleHeaderName:"Sort by name",
titleHeaderAntal:"Sort by price",
titleAdjustMenu:"Adjust menu color",
titleAdjustMenu1:"Adjust menu background",
titleProductionId:"Production id",
titleCategoryId:"Category id",
  },
  prodarbSum:{
title:"Recipe summary / production",
titleMobile:"Summation/production",
leftSelectHeaderLegend:"Recipe summary",
leftSelectFirstOption:"Select production",
middleHeaderLegend:"Recipe specification:",
middleHeaderId:"Id",
middleHeaderIngredient:"Ingredient",
middleHeaderAmount:"Amount",
middleHeaderPercentage:"%",
middleheaderWorkWeight:"WorkW",
middleHeaderArborder:"Order",
middleDescriptionLegend:"Recipe description",
rightHeaderLegend:"Product menu",
rightHeaderProductId:"Id",
rightHeaderProductName:"Name on product",
rightHeaderProductQuantity:"Quantity",
titleMidHeaderId:"Ingredient id",
titleMidHeaderName:"Name of ingredient",
titleMidHeaderIngredientAmount:"Ingredient quantity",
titleMidHeaderPercentageOf:"Percentage of recipe",
titleMiddleHeaderCalculatedIngredientAmount:"Calculated quantity",
titleMiddleHeaderWorkOrder:"Order",
titleAdjustMenu:"Adjust menu color",
titleAdjustMenu1:"Adjust background",
footerTotalText:"Total",
recipeDescriptionLegend:"Description"
  },
  ingredtotNew:{
title:"Ingredient summary",
titleMobile:"Ingredient summary",
listHeaderLegend:"Summation of ingredients for products in production",
listHeaderId:"Id",
listHeaderName:"Ingredient name",
listHeaderWeight:"Weight",
listtHeaderAmount:"Amount",
titleHeaderName:"Klick to sort by name",
titleHeaderWeight:"Klick to sort by weight",
titleHeaderAmount:"Klick to sort by amount",
rightHeaderLegendTotalAmount:"Total amount",
rightHeaderLegendTotalWeight:"Total weight",
titleAdjustMenu:"Adjust menu color",
titleAdjustMenu1:"Adjust background",
  
  },
  pricelist:{
title:"Pricelist",
titleMobile:"Pricelist",
mainSelectLegend:"Product categories",
mainSelectFirstOption:"Select category",
mainHeaderPid:"Pid",
mainHeaderItemNumber:"ItemNr",
mainHeaderProductName:"Product name",
mainHeaderPrice:"Price",
mainHeaderMargin:"Margin",
mainHeaderInclusiveVAT:"VAT included",
mainHeaderSave:"Save",
mainHeaderSaved:"Saved",
titleIncreaseBy10:"Click to increase price by10",
titleIncreaseBy1:"Click to increase price by 1",
titleIncreaseBy01:"Click to increase price by 0.1",
titleDecreaseBy10:"Click to reduce price by 10",
titleDecreaseBy1:"Click to reduce price by 1",
titleDecreaseBy01:"Click to reduce price by 0.1",
titleName:"Click to sort by name",
titlePrice:"Click to sort by price",
titleMargin:"Click to sort by margin",
rightHeaderLegend:"Average margin in the product group",
titleAdjustMenu:"Adjust menu color",
titleAdjustMenu1:"Adjust background",
titleMobileMargin:"Marg",
titleMargin1:"Margin",
titleMobileVat:"+VAT",
titleVat:"+VAT",
noNameGiven:"No name given",
  },
  mysettings:{
title:"My page settings",
mobiletitle:"Page settings",
leftHeaderLegend:"Select page",
midBackgroundFilter:"Select background filter",
midMenuFilter:"Select menu filter",
midMenuColor:"Select menu color for the page",
saveButtonText:"Save",
rightHeaderlegend:"Select image for the page",
  },
  headermenubar:{
selectMenu:"Select menu",
headermenuHome:"Home",
headermenuLogout:"Log out",
headermenuIngrediensList:"Ingredient List",
headermenuRecipeList:"Recipe List",
headermenuProductList:"Product List",
headermenuIngredients:"Ingredients",
headermenuIngredientCategories:"Ingredient Categories",
headermenuRecipeCategories:"Recipe Categories",
headermenuProductCategories:"Product Categories",
headermenuMyRecipes:"My Recipes",
headermenuCreateReceipemanually:"Create New Recipe Manually",
headermenuCreateRecipeWithAI:"Create New Recipe with AI",
headermenuCreateProduct:"Create Product",
headermenuProductStatistics:"Product Statistics",
headermenuWorkWithProduction:"Work with Production",
headermenuTotalNumbers:"Total Number of Products in Production",
headermenuRecipeSummary:"Recipe Summary",
headermenuIngredientSummary:"Ingredient Summary",
headermenuPriceList:"Price List",
headermenuPageSettings:"Page Settings",
lists:"Lists",
InfoAboutUs:"Subscribe",
headermenuproducts:"Products",
headermenuproduction:"Production",
headermenurecipe:"Recipe",
headermenuUserDataPolicy:"Data policy",
headermenuRecipeIdeas:"Recipe ideas",
headermenuMyAccount:"My Account",
headermenuRecipeScroll:"Recipe scroll",

  },
  loginBox:{
welcomeMessageText:"Welcome",
emailadressText:"Email address",
setresetEmailText:"Email to reset",
continuebuttonText:"continue",
noaccountText:"no account?",
createaccountText:"Create account",
availableLanguagesText:"We are now available in the following languages",
loginText:"Log in",
passwordText:"Password",
forgotPasswordText:"Forgot password?",
sendMeResetLinkText:"Send me a reset link",
sendResetButtonText:"Reset my email",
resetinputPlaceholder:"Email address",
aboutUsText:"About us",
contactText:"Contact",
privacyPolicyText:"Privacy policy",
loginText2:"Log in",
signUpText2:"Sign up",
orText2:"or",
returnText:"Return",
mobileWelcomeMessageText:"Welcome!",
mobileWelcomeMessageText2:" Great you are here!",
  },
  confirmationMessages:{
    ingredientCreated:"Ingredient created",
    ingredientNameUpdated:"Ingredient name updated",
    ingredientUpdated:"Ingredient updated",
    ingredientReplaced:"Ingredient replaced",
    priceUpdated:"Price updated",
    ingredientDeleted:"Ingredient deleted",
    nutrientsUpdated:"Nutrients updated",
    recipeUpdated:"Recipe updated",

    categoryDeleted:"Category deleted",
    categoryDeleteQuestion:"Do you want to remove the category:",
    categoryNameWrite:"Write category name",
    ingredientsMovedTochosenCategory:"Ingredients moved to chosen category",
    productsMovedTochosenCategory:"Produkter flyttade till vald kategori",
  
    categoryCreated:"Category created",

    recepyCreated:"Recepy created",
    recepyDeleted:"Recepy deleted",
    yesNoDeleteRecepy:"Do you want to delete the recipe?",
    btnYes:"Yes",
    btnNo:"No",

    productDeleted:"Product deleted",
    productIsSaved:"Product is saved",
    yesNoDeleteProduct:"Do you want to delete the product?",
    nameInfo:"Name can only include 45 characters",

    productionSaved:"Production is saved",
    productionCreated:"Production is created",
    productionCleared:"Production is cleared",

    descriptionIsUpdated:"Description is updated",
    pageSaved:"Page is saved",
    emailSent:"Email sent",
    userAddressDataUpdated:"User address data updated",
    imageSearchTextToLong:"Maximum 100 characters creative text",
    selectedImageDeleted:"Image deleted",
    aiimageIsDone:"Image is created",
    aiimageCantBeDone:"Account balance is too low to generate image",
    imageDeletQuestion:"Do you want to delete this image?",
    imageUploadedMessage:"Image uploaded",
    yesnodeleteIngredient:"Do you want to delete this ingredient?",
    fetchingDataInformation:"Fetching data, building recipe..",
    prescriptionCreatedMessage:"Prescription created",
    yesNoDeleteRecepyWithImages:"Do you want to delete the recipe and images?",
    linkNotValid:"Link is not valid or timed out",
  passwordNoMatch:"Passwords do not match",
  passwordAtleast8:"Password must be at least 8 characters long",
  passwordRestored:"Password restored",
  passwordRestoreFailure:"Password restore failed",
  resetLinkSentToEmail:"Reset link sent to email",
  activateServiceMessage:"Service is activated",
  deactivateServiceMessage:"Service is deactivated",
  recipeIdeaAccountVoid:"Limit for recipe ideas reached",
  aiImageCantBeUploaded:"Maximum images for upload reached",
  recipeCanNotBeSaved:"Max limit for recipe Ideas reached",
  aiSubscriptionVoid:"Max limits for AI image creation reached",
  categoriesVoid:"Max limit for categories reached",
  ingredientVoid:"Max limit for ingredients reached",
  ingredientInfoVoid:"Max limit for ingredient information reached",
  productsVoid:"Max limit for products reached",
  productionVoid:"Max limit for production reached",
  imageuploadVoid:"Max limit for image upload reached",
  convertVoid:"Max limit for conversion reached",
  supportMessageSendText1:"Meddelande skickat",
  dowloadpdfOkMessage:"Pdf invoice downloaded",
  aiRecipeSearchVoid:"Max limit for AI recipe search reached",
  
  passwordsMustBelongText:"Passwords must be at least 6 characters long",
  confirmPasswordtext:"matching password must be atleast 6 characters long",
  passwordNoMatchText:"Passwords do not match",
  firstNameRequiredText:"First name required",
  lastNameRequiredText:"Last name required",
  adressRequiredText:"Adress required",
  postalCodeRequiredText:"Postal code required",
  cityRequiredText:"City required",
  regionRequiredText:"Region required",
  countryRequiredText:"Country required",
  phoneRequiredText:"Phone required",
  emailRequiredText:"Email required",
  languageRequiredText:"Language required",
  fullnameRequiredText:"Full name required",
  currencyRequiredText:"Currency required",
  serviceRequiredText:"Service required",
  emailNotValidText:"Email not valid",

  accountCreatedSuccessText:"Account created successfully, go to payment Step 2",
  setupDetailsDeleteFailedText:"Setup details delete failed, contact support",
  accountCreatedNoSuccessText:"Account could not be created",
  accountAlreadyExistText:"Account already exist, contact support",
  accountCreationFailedText:"Account creation failed, contact support",
   
  paypalPaymentConfirmText:"Payment is done, confirmation sent to your email",
  loginConfirmToManyUsersText:"You are using to many devices, try again later",
  forceLoginText:"We have sent email with a restore code for login",
  noaccessContactSupportText:"No access, contact support",
  wrongIdOrAccountText:"Wrong id or account",
  notAuthorizedText:"You are not authorized to use this app, please contact support",
  klarnaPaymentSuccessText:"Account is ready for use",
  klarnaPaymentfailureText:"Klarna payment not done",
  finalPaymentMessage:"Thank you for your payment",
  finalPaymentMessageLink:"Log in here",
  useNoAccessText:"You have no access to this service",
  signUptemporaryClosedText:"Sign up is temporary closed, contact support",
  clientSideUpdateSupportTicketText:"Support ticket updated",
  noHelpInformationText:"No help information available",
  FetchingHelpInstructionsText:"Fetching help instructions",
  accountCantBeCreatedText:"Account can not be created with this email address, contact support!",
  noinvoicesFoundText:"No invoices found",
  signupButtontext:"Sign up",
  priceCantBeSetText:"Price must be between 1 and 5",
},
  recipeideas:{
    recipesearchFieldset:"Search for recipe ideas",
    recipesearchPlaceholder:"e.g. pasta with meatsauce",
    recipesearchButton:"Search",
    recipesearchConfirmation:"Found",
    recipesearchConfirmationNum:"",
    recipesearchText:"Recipes",
    receipesearchHeaderId:"Id",
    receipesearchHeaderName:"Name",
    recipeTitle:"Recipe ideas",
    titleAdjustMenu:"Adjust menu color",
    titleAdjustMenu1:"Adjust background",
   
  },
  MyAccount:{
    title:"My Account",

    userFirstName:"Name",
    userFamilyName:"Family name",
    userCompany:"Company",
    userAdress:"Address",
    userPostalCode:"Postal code",
    userCity:"City",
    userRegion:"Region",
    userCountry:"Country",
    userPhone:"Phone",
    userEmail:"Email",
    userSaveButtonText1:"Save",


    userallServices:"All services",
    userDebitServices:"Billing of services",
    userService:"Service",
    userPrice:"Price",
    userActivate:"Activate",
    userStat:"Status",
    numPageRequestsPerPage:"Number of page views in the period",
    userPagenr:"Page nr",
    userPageComponent:"Page",
    userPageNum:"Qty",
    userMyData:"My data",
    userMyDataDescriptionText:"This is information about the data you have in our system, at the creation of the account you received a base package with data, this is included.",

    userDataId:"Id",
    userDataName:"Name",
    userDataAntal:"Qty",
    userDataIngredientCategories:"Ingredient categories",
    userDataRecipeCategories:"Recipe categories",
    userDataProductCategories:"Product categories",
    userDataIngredient:"Ingredients",
    userDataRecipes:"Recipes",
    userDataProducts:"Products",
    userDataProductions:"Production",
    userDataRecipeImages:"Recipe images",
    userDataProductImages:"Product images",
    userDataInstructionText:"If you want your own data sent to you on email, push the button below. Does not include images.",

    useDataMyDataButtonText:"My data",
    paymentPayPal:"Paypal",
    paymentSwish:"Swish",
    paymentKlarna:"Klarna",
    paymentCreditCard:"Credit card",
    paymentInvoice:"Invoice",
    accountOverview:"Overview",
    userAccount:"Account",
    accountTransactionsFor:"Account transactions for",
    accountService:"Service",
    accountInvoice:"Invoice",
    accountDebit:"Price",
    accountKredit:"Credit",
    accountDate:"Date",
    accountSystemComment:"Comment",

    accountTotalDebit:"Total Debit",
    accountTotalKredit:"Total Credit",
    accountTotalSaldo:"Balance",

    invoice:"Invoice",
    invoiceInfo:"Invoice information",
    estimatedSaldo:"Estimated balance",
    estimatedSaldoText:"This is the total cost for the current prescription",
    nextPaymenttext:"Next payment date",
    prePaymentText:"Advance payment",
    totalAmountUsed:"Total used",

    // invoice history
    invoiceHistoryText:"Invoice history",
    invoiceHistoryInvoice:"Invoice",
    invoiceHistorySum:"Sum",
    invoiceHistoryDate:"Date",
    invoiceHistoryStatus:"Status",

    
    //invoicerows
    chooseInvoiceText:"Choose invoice",
    invoiceRowsText:"Invoice rows for invoice",
    allInvoiceRowsText:"All invoice rows",
    invoiceRowsId:"ID",
    invoiceRowsService:"Service",
    invoiceRowsMax:"Max",
    invoiceRowsRequest:"Qty",
    invoiceRowsDebitQty:"Debit",
    invoiceRowsPrice:"Price",
    invoiceRowsTotal:"Total",
    invoiceRowsType:"Type",
    invoiceRowsTotalSum:"Total sum",

        //password
        passwordValidText:"Valid password",
        newPasswordText:"New password",
        confirmPasswordText:"Confirm password",
        showPasswordText:"Show password",
        savePasswordButtonText:"Save",
        iforgotMyPasswordText:"I forgot my password and want to reset.",
    
  //support
  supportMText:"Bakesoft support",
  supportMessageText:"If you have any questions or need help, please contact us.",
  supportMessageSelectText:"Select subject",
  supportMessageSendText:"Send",
  messagesSentToSupportText:"Messages sent to support",
  supportRequestText:"Support request",

  //settings
settingsHeaderText:"Settings",
companyAndPersonalText:"Company and personal information",
addressInstructionText:"This address is used for invoices and other information and shall be a real address to youre home or company.",
taxIdentificationVATText:"Tax identification number",
taxIdInformationText:"Registered companies outside of EU are able to register their tax identification number to remove VAT from the invoice.",
registerTaxIdButtonText:"Register tax identification number",
maxValueWarningText:"Max value warning",
actiVateMaxValueText:"Activate and set the max value warning for the monthly invoice,we send a email when max value is reached.",
maxValueButtonSaveText:"Save",

// buttontext
addressInfoButtontext:"Address",
servicesButtontext:"Services Shop",
dataUsageButtontext:"Data usage",
mydataButtontext:"My data",
paymentButtontext:"Payment",
myAccountButtonText:"My account",
myInvoiceButtonText:"Invoices and receipts",
passwordButtontext:"Password",
supportButtontext:"Support",
settingsButtontext:"Settings",
servicesButtonUsagetext:"Service usage",

    //invoices
websiteCompanyName:"Bakesoft.se",
invoiceText:"Invoice",
broughtToYouText:"Brought to you by Hellström Trading i Göteborg AB",
customerIdText:"customerId:",
serviceText:"Service",
nameText:"Name",
numText:"Qty",
maxText:"Max",
debitText:"Price",
kreditText:"Credit",
totaltText:"Total",
sumNetText:"Net sum:",
sumVatText:"VAT:",
attBetalaText:"TO PAY:",
bankgiroText:"Bankgiro:",
swishText:"Swish:",
termsText:"Terms:",
bankText:"Bank:",
bicText:"BIC:",
ibanText:"IBAN:",


//
serviceHeaderText:"Services",
servicePrisHeaderText:"Price",
serviceActivateHeaderText:"Activate",
serviceStatusHeaderText:"Status",

// tjamsterdebitering
debserviceidText:"ID",
debserviceBenaemningText:"Name",
debserviceQtyText:"Qty",
debserviceMaxText:"Max",
debserviceUsableText:"Usable",
debserviceStatusText:"Status",

// support history
supportHistoryServiceIdText:"ID",
supportHistoryDateText:"Date",
supportHistoryStatusText:"Status",
supportHistoryCategoryText:"Subject",

//password
passwordHeaderText:"Password Change",
resetPasswordHeaderText:"Reset password",

overviewText:"Overview",
overviewInformationText:"Information",
overviewInvoiceText:"Invoice",

//invoice
invoiceSelectFirstOption:"Select invoice",
printInvoceButtonText:"Print invoice",
myinformationText:"My information",
supportButtonAnswerText:"Send",
supportTitleText:"Title",
supportHistoryBenaemnText:"Subject",
paymentStandard:"Payment",
paymentIntentErrorText:"Payment preparations cannot be done, contact support",
serviceActivatedText:"Service activated",
thankYouPurchaseText:"Thank you for your purchase!",
paymentFailedMessageText:"Payment failed!",
paymentFailedMessageText2:"Try again or contact support!",
isPayedText:"Payed",
isNotPayedText:"Not payed",
choseImageButtontext:".Choose image",
choseImageUploadText:"Upload image",

liveAccountSetupText:"Live account setup",
liveCreatorImageText:"Live creator image upload",
liveCreatorPublicImageText:"Live creator public image (5:7)",
liveCreatorProfileText:"Live creator profile text:",
liveMax500text:"max:500",
saveCreatorText:"Save creator text",
liveAccountText:"Live account",


    },
    logout:{
      logoutMessage:"You are now logged out!",
      loginMessage:"Please login to continue."
    },
    aialert:{
      aialertTitle:"Information!",
      aialertMessage:"We could not collect enough data.",
      aialertMessage2:"Try up to 3 times, then change the text.",
      aialertButtonText:"Close",
    },
    resetPass:{
      headerText:"Reset password",
      resetPassInputPlaceHolder:"New password",
      resetPassInputConfirmPlaceHolder:"Confirm new password",
      resetPassButtonText:"Restore password",
      },
      scrollRecipe:{
        mobileTitle:"Recipe image scroll",
        title:"Recipe image scroll",
        titleJusteraBakgrund:"Justera bakgrund",
        scrollIngredientButtontext:"Ingredients",
        scrollDescriptionButtontext:"Description",
        scrollBenaemnText:"Name",
        scrollMangdText:"Amount",
        scrollSummaText:"Total",
        scrollKcalText:"Calories",
        scrollArborderText:"Order",
        scrollDescriptionHeaderText:"Description",
      },
      info:{
        startButtonText:"Home",
        signupButtonText:"Sign up",
        signupButtonPrivateText:"Sign up",
        signupButtonCompanyText:"Sign up company",
        applicationButtonText:"Base Services",
        applicationPlusServicesText:"Plus Services",
        aiServicesButtonText:"AI Services",
        aboutUsButtonText:"About us",
        faqButtonText:"FAQ",
        basicButtonText:"Basic",
        totalButtonText:"Total",
        basicCorporate:"Basic corporate",
        totalCorporate:"Total corporate",
        basicStoryButton:"Basic, the story",
        basicfunctionalityButtonText:"Features",
        pleasefillupFormText:"Please fill in the form below to sign up.",
        aftersignupemailwillbesent:"After sign-up you will be sent a email with a invoice.",
        accountsetupin24hrs:"Payment in the app will open the account in 5 minutes.",
        accountsetupSlow:"Other payment methods opens youre account within 24hrs.",
        signUpHeaderText:"Sign up",
        firstNameText:"First name",
        lastNameText:"Last name",
        addressText:"Address",
        postalCodeText:"Postal code",
        cityText:"City",
        regionText:"Region",
        countryText:"Country",
        emailText:"Email",
        phoneText:"Phone",
        passwordText:"Password",
        confirmPasswordText:"Confirm password",
        selectLanguageText:"Select app language",
        selectSwedLangText:"Swedish",
         selectEngLangText:"English",
         selectJapLangText:"Japanese",
         selectCurrencyText:"Select currency",
         selectSekCurrencyText:"SEK",
         selectUsdCurrencyText:"USD",
         selectJapCurrencyText:"JPY",
         selectServiceText:"Select service",
         selectBasicServiceText:"Basic",
         selectTotalServiceText:"Total",
         selectBasicCorporateServiceText:"Basic corporate",
         selectTotalCorporateServiceText:"Total corporate",
         allfieldsMandatoryText:"All fields are mandatory",
         signUpButtonText:"Sign up",

         applicationBaseServicesHeader:"Application base services",
         applicationBaseVersions:"The application has 4 different base versions",
         applicationBaseVersions1:"Basic",
         applicationBaseVersions2:"Total",
         applicationBaseVersions3:"Basic corporate",
         applicationBaseVersions4:"Total corporate",
         applicationCanusePartly:"All parts can used exempt products and production",
         applicationCanBeUsed:"All parts can be used",
         plusServicesHeaderText:"The following services can be",
         plusServicesHeaderText1:"used to grow your company in the application",
         plusServiceName:"Name",
         plusServicePrice:"Price",
         plusServiceBenaemning:"Name",
         pricesGivenPerMonthText:"Prices given per month",
         faqHeaderText:"FAQ",
         totalWeTelltext:"Total, we tell you",
         totalFunctionalitytext:"Features",
         totalCorporateWeTellText:"Total corporate, we tell you",
         totalCorporateFunctionalityText:"Features",
         basicCorporateWeTell:"Basic corporate, we tell",
         basicCorporateFunctionality:"Features",
         // paypal payment
         paypalMainHeaderText:"Bakesoft payment Service (PayPal)",
         klarnaMainHeaderText:"Bakesoft payment Service (Klarna)",
         pptotalAmountToPayText:"Total amount to pay",
         ppChosenServiceText:"Chosen service",
         ppChosenCurrencyText:"Chosen currency",
         registerAccountTabText:"Register account, Step 1",
         paymentTabText:"Payment, Step 2",
         ppBakesoftTermsText:"Terms",
         ppBakesoftTerms:"Unsubscribe at any time",
         ppSelectInvoiceText:"Select invoice",
         ppSelectCurrencyText:"Select currency",
         contactInfoText:"Contact information",
         privacyPolicyText:"Privacy policy",
         klickOnTextMoreInfo:"Click on service for more information!",
         integrityPolicyText:"Integrity policy",
         contactInformationText:"Contact information",
         buyingTermsText:"Buying terms",
           signupCompanyButtonText:"Sign up company",



           totalHeaderText:"Total prescription",
           totalBasIncludedText:"All in Entry Corporate Plan included",
           totalUppTo800RecipeText:"800 recipes",
           totalProductsMax500Text:"500 products",
           totalProductionText:"500 productions",
           totalAutomaticRecipeSummaryText:"Automatic recipe summary",
           totalAutomaticIngredientSummaryText:"Automatic ingredient summary",
           totalAutomaticProductionStatisticText:"Automatic production statistics",
           totalCreatePricelistText:"Create pricelist with margins",
           total200ProductCategoriesText:"200 product categories",


           basCorpHeaderText: "Basic Corporate",
          basCorporateSave500RecipeText: "Save 500 recipes",
          basCorpUse500IngredientsText: "Use 500 ingredients",
          basCorpCreate200IngredientCategoriesText: "Create 200 ingredient categories",
          basCorpCreateRecipeWithAiText: "Create recipes with AI",
          basCorpCreateRecipeManuallyWithIngredientsText: "Create recipes manually with ingredients",
          basCorpSearchRecipeIdeaText: "Search for recipe ideas",
          basCorpCreateImageWithAiText: "Create image with AI",
          basCorpUploadOwnImageText: "Upload your own images",
          basCorpSave1000ImagesText: "Save 1000 images",
          basCorpCreate200RecipeCategoriesText: "Create 200 recipe categories",
          basCorpAutomaticNutrientCalculationText: "Automatic nutrient calculation",
          basCorpAutomaticCostCalculationText: "Automatic cost calculation",
          basCorpAutomaticMatchIngredientsText: "Automatic ingredient matching",
          basCorpAutomaticGetNutrientsText: "Automatic retrieval of nutrients",
          basCorpInformationsAboutIngredientsText: "Information about ingredients",
          basCorpConvertVolumeToGramText: "Convert volume to grams",
          basCorpManageIngredientRecipeLinksText: "Manage ingredient-recipe links",
          basCorpMoveManageLinksAutomaticallyText: "Move links automatically",
          basCorpIngredientPriceText: "Manage ingredient prices",
          basCorpAutomaticPriceDiagramText: "Automatic price diagram",
          basCorpHumoristicCommentsText: "Humorous comments from AI",

       

      basPrivateSave300RecipeText: "Save 300 recipes",
      basPrivateUse500IngredientsText: "Use 500 ingredients",
      basPrivateCreate100IngredientCategoriesText: "Create 100 ingredient categories",
      basPrivateSearchCreateRecipeAiText: "Search and create recipes with AI",
      basPrivateCreateRecipeManuallyWithIngredientsText: "Create recipes manually with ingredients",
      basPrivateSearchRecipeIdeaText: "Search for recipe ideas",
      basPrivateCreateImageWithAiText: "Create image with AI",
      basPrivateUploadOwnImageText: "Upload your own images",
      basPrivateSave400ImagesText: "Save 400 images",
      basPrivateCreate100RecipeCategoriesText: "Create 100 recipe categories",
      basPrivateAutomaticNutrientCalculationText: "Automatic nutrient calculation",
      basPrivateAutomaticCostCalculationText: "Automatic cost calculation",
      basPrivateAutomaticMatchIngredientsText: "Automatic ingredient matching",
      basPrivateAutomaticGetNutrientsText: "Automatic retrieval of nutrients",
      basPrivateInformationsAboutIngredientsText: "Information about ingredients",
      basPrivateConvertVolumeToGramText: "Convert volume to grams",
      basPrivateManageIngredientRecipeLinksText: "Manage ingredient-recipe links",
      basPrivateMoveManageLinksAutomaticallyText: "Move links automatically",
      basPrivateIngredientPriceText: "Manage ingredient prices",
      basPrivateAutomaticPriceDiagramText: "Automatic price diagram",
      basPrivateHumoristicCommentsText: "Humorous comments from AI",
      
      totalPrivateBaseIncludedText: "Everything in Entry plan is included",
      totalUppTo400RecipeText: "400 recipes",
      totalProductsMax300Text: "300 products",
      totalProductionText2:"250 productions",
      totalAutomaticRecipeSummaryText2: "Automatic recipe summary",
      totalAutomaticIngredientSummaryText2: "Automatic ingredient summary",
      totalAutomaticProductionStatisticText2: "Automatic production statistics",
      createProductionMax250Text: "Create up to 250 productions",
      createPricelistWithMarginText: "Create pricelist with margins",
      createUpTo100ProductCategoriesText: "Create up to 100 product categories",

      signUpSubscriptionHeaderText: "Select your plan",
      basicPlanText: "Entry plan",
      basServiceIdText: "Service ID:127",
      basFeaturesText: "Essential features, private users",
      totalPlanText: "Premium plan",
      totalServiceIdText: "Service ID:128",
      totalFeaturesText: "Extended features, private users",

      basicCorpPlanText: "Entry Corporate Plan",
      basCorpServiceIdText: "Service ID:133",
      basCorpFeaturesText: "Essential features, corporate",
      totalCorpPlanText: "Premium Corporate plan",
      totalCorpServiceIdText: "Service ID:134",
      totalCorpFeaturesText: "Extended features, corporate",

      basPrivateHeaderText:"Entry",
      totalPrivateHeaderText:"Premium",
      basCorporateheaderText:"Entry Corporate",
      totalCorporateheaderText:"Premium Corporate",

      selectPlanIntentTypeText: "Choose period",
      selectMonthlyPlanText: "Monthly",
      selectTrippleMonthPlanText: "Triple",
      selectYearlyPlanText: "Full year",
      discountText:"Off",

      choseCountryText:"Choose country",
      
      choseCountryText:"Choose country",
      prepayLanguageHeader:"Language",
      prepayCountryHeader:"Country",
      prepayCurrencyHeader:"Currency",
      prepayPeriodHeader:"Period",

      paymentDetailsHeaderText:"Payment details",
      paymentDetailsCurrencyText:"Currency",
      paymentPlanText:"Plan",
      discountText:"Discount",
      paymentTotalToPayText:"Total to pay",

      orderOverviewText:"Order overview",
      plandetailText:"Plan details",

      fullNameLabelText:"Full name",
      emailLabelText:"Email",
      passwordLabelText:"Password",
      confirmPasswordLabelText:"Confirm password",
      continueLabelText:"Continue",
      welcomeLabelText:"Welcome",
      signUpContinueLabelText:"Register or continue with",
      orLabelText:"or",

      successPaymentHeaderText: "Payment Completed",
      successPaymentText: "Thank you for your payment, your payment has been completed.",
      successPaymentIdText: "Payment ID",
      successPaymentAmountText: "Amount Paid",
      successPaymentServiceText: "Service",

      paymentFailedHeaderText: "Payment Failed",
      paymentFailedText: "The payment could not be completed, please try again or contact support.",

      accountSetupServiceText: "Service",
      accountSetupLanguageText: "Language",
      accountSetupCurrencyText: "Currency",
      accountSetupPeriodText: "Period",
      accountSetupCountryText: "Country",
      accountSetupTotalAmountText: "Amount to Pay",
      accountSetupHeaderText: "Account Setup Information",
      accountSetupCancelText:"Cancel at anytime",
      accountSetupTermsText:"Terms",  
      confirmPayButtonText:"Pay",
      gotoLoginMessageText:"Go to login",

      paymentSucceededText:"Payment succeeded",
      paymentProcessingText:"Payment processing",
      paymentFailedTextInfo:"Something went wrong, try again",
      paymentSomethingWentWrongText:"Something is wrong, try again",
      paymentSucceededEmailText:"Confirmation sent to your email",

      continueWithFacebookText:"Continue with Facebook",
      continueWithGoogleText:"Continue with Google",
      continueWithLineText:"Continue with Line",
      notValidFacebookTokenText:"Your address exists or you cant use facebook to sign up,contact support",
      basPrivateIncludedText:"All in Entry Plan included",
      planFeaturesText:"Plan features",
      passwordText:"Password",
      passwordConfirmText:"Confirm password",
      termsText:"Jag godkänner användarvillkor och sekretesspolicyn.",

              },
  welcomeImage:{
    importantNoticeText:"Important notice!",
    invoiceDueText:"The following invoice is due",
    invoiceText:"Invoice",
    serviceIdText:"Service ID",
    dueDateText:"Due date",  
    gotoMyAccountText:"Go to my account",
    amountText:"Amount",
    settlePaymentHereText:"Settle payment here",
    ifnopaymentText:"If no payment is made, the service will be deactivated",
    contactSupportText:"Contact support in the support panel or by email.",
    supportLinkText:"Support",
    serviceDueText:"Services due to be paid",
    deleteText:"Delete",
    payText:"Pay",
    subscriptionInRedText:"Subscription in red must be payed!!",
  },
  liveArea:{
    liveAreaText:"Live",
    unpublishButtonText:"Unpublish",
    publishThisRecipeText:"Publish this recipe on Live",
    recipeIsPublishedText:"RECIPE IS PUBLISHED",
    creatorStatusText:"Creator status",
    recipeIdText:"Recipeid",
    liveImageText:"ImageCheck",
    liveInstructionText:"(Copy recipe ,create new to share)",
    recipeToPublishText:"Recipe to publish",
    liveTimeText:"Time",
    liveDifficultyText:"Difficulty",
    liveSalesPriceText:"Sales price",
    userAccessText:"User who has access",
  },





        // Add more pages as needed
      },

    }

  export default languages;